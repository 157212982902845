import React, {useState} from 'react';
import tissage from '../../Data/bundle';

const SearchWigs01 = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const [showResults, setShowResults] = useState(false);

    const handleSearchInputChange = (e) => {
        setSearchQuery(e.target.value);
        if (e.target.value.trim() === '') {
            setShowResults(false);
        }
    };

    const handleSearchSubmit = (e) => {
        e.preventDefault();
        setShowResults(true);
    };

    // Define the multiplier value
    const multiplier = 100; // Change this value to your desired multiplier

    const filteredWigs = tissage.flatMap(wig =>
        wig.style.filter(style =>
            (wig.type && typeof wig.type === 'string' && wig.type.toLowerCase().includes(searchQuery.toLowerCase())) ||
            (style.grade && typeof style.grade === 'string' && style.grade.toLowerCase().includes(searchQuery.toLowerCase()))
        ).map(style => ({
            ...style,
            wigType: wig.type,
            picture: wig.picture
        }))
    );

    return (
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="max-w-3xl text-center mx-auto">
                <h2 className="mb-12 text-gray-800 text-4xl font-bold leading-none text-center sm:text-3xl">
                    Trouvez vos Bundles.
                </h2>
            </div>
            <form onSubmit={handleSearchSubmit} className="mt-8 sm:flex py-20">
                <label htmlFor="default-search" className="sr-only">Search</label>
                <div className="relative w-full sm:flex-1">
                    <input
                        id="default-search"
                        className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:border-red-300 focus:ring focus:ring-red-200 focus:ring-opacity-50 sm:text-sm"
                        placeholder="Recherchez par type, densité ou qualité..."
                        type="search"
                        value={searchQuery}
                        onChange={handleSearchInputChange}
                        required
                    />
                </div>
                <button
                    type="submit"
                    className="mt-3 w-full sm:mt-0 sm:ml-3 bg-red-600 border border-transparent rounded-md py-2 px-4 flex items-center justify-center text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:w-auto sm:flex-shrink-0 sm:inline-flex sm:items-center sm:justify-center"
                >
                    Recherche
                </button>
            </form>
            {showResults && searchQuery.trim() !== '' && (
                <div className="mt-5 max-h-96 overflow-y-auto">
                    {filteredWigs.length > 0 ? (
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                            <tr>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Image</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Type</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Grade</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Lace</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Details</th>
                            </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                            {filteredWigs.map((wig, wigIndex) => (
                                <>
                                    <tr key={wigIndex} className="text-secondary-800 font-bold">
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <img src={wig.picture} alt={wig.wigType}
                                                 className="h-16 object-cover"/>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">{wig.wigType}</td>
                                        <td className="px-6 py-4 whitespace-nowrap">{wig.grade}</td>
                                        <td className="px-6 py-4 whitespace-nowrap">{wig.lace}</td>
                                        <td className="px-6 py-4 whitespace-nowrap">See details below</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="5">
                                            <table className="min-w-full">
                                                <tbody className="grid grid-rows-3 grid-flow-col gap-2">
                                                {wig.lengths.map((length, lengthIndex) => {
                                                    // Calculate the final price by multiplying the original price with the multiplier
                                                    const finalPrice = length.price * multiplier;

                                                    return (
                                                        <tr key={`${wigIndex}-${lengthIndex}`}>
                                                            <td className="px-6 py-2 whitespace-nowrap">
                                                                <span
                                                                    className="font-semibold">Taille</span>: {length.length}"
                                                                = {finalPrice} FCFA
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </>
                            ))}
                            </tbody>
                        </table>
                    ) : (
                        <div className="text-gray-500">No results found.</div>
                    )}
                </div>
            )}
        </div>
    );
};

export default SearchWigs01;
