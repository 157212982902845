import React from 'react';

import {
    Clients,
    Contact, Dashboard,
    Home,
    NotFound, Order,
    OurCustomer,
    Question,
    SignIn
} from "../pages";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import {AuthContextProvider} from "../context/AuthContext";
import ProtectedRoute from "../context/ProtectedRoute";

const AllRoutes = () => {
    return (
        <AuthContextProvider>
            <Router>
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/signin" element={<SignIn/>}/>
                    <Route path="*" element={<NotFound/>}/>
                    <Route path="/clients" element={<Clients/>}/>
                    <Route path="/contact" element={<Contact/>}/>
                    <Route path="/question" element={<Question/>}/>
                    <Route path="/dashboard" element={<Dashboard/>}/>
                    <Route path="/orders" element={<Order/>}/>

                    {/*Protected Routes*/}
                    <Route path="/fourus" element={
                        <ProtectedRoute>
                            <OurCustomer/>
                        </ProtectedRoute>
                    }/>

                </Routes>
            </Router>
        </AuthContextProvider>
    );
};

export default AllRoutes;