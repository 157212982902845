import React from 'react';
import {MyOrder, TopOder} from "../components";
import Header3 from "../layout/header/Header3";

const Order = () => {
    return (
        <div>
            <Header3/>
            <TopOder/>
            <MyOrder/>
        </div>

    );
};

export default Order;