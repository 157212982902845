import React, {useRef, useState} from 'react';
import emailjs from 'emailjs-com';
import {useNavigate} from 'react-router-dom';

const Order = () => {
    const today = new Date();
    const dateString = today.toISOString().split('T')[0].replace(/-/g, '');
    const randomNumber = Math.floor(1000 + Math.random() * 9000);
    const billNumber = `INV-${dateString}-${randomNumber}`;

    const [formData, setFormData] = useState({
        billNumber,
        name: '',
        address_street: '',
        billing_address: '',
        urgency_selection: '',
        urgent_details: '',
        phone_number: '',
        secondary_email: '',
        currency: '',
        delivery_mode: '',
        required_services: '',
        budget_amount: '',
        payment_date: '',
        payment_method: '',
        customer_profile: '',
        description: ''
    });

    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const formRef = useRef(null);
    const navigate = useNavigate();

    const handleChange = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value});
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!formData.name || !formData.secondary_email || !billNumber) {
            setError('Please fill all required fields.');
            return;
        }

        try {
            await emailjs.sendForm('service_1h5q8el', 'template_d9liow8', formRef.current, 'r0A9ejRzmV8WW5GUJ');
            setSuccess('Merci pour votre commande. Nous vous contacterons bientôt.');
            setTimeout(() => {
                navigate('/'); // Redirect to home page
            }, 2000); // Redirect after 2 seconds
            formRef.current.reset();
            setFormData({
                billNumber, // Keep bill number the same after reset
                name: '',
                address_street: '',
                billing_address: '',
                urgency_selection: '',
                urgent_details: '',
                phone_number: '',
                secondary_email: '',
                currency: '',
                delivery_mode: '',
                required_services: '',
                budget_amount: '',
                payment_date: '',
                payment_method: '',
                customer_profile: '',
                description: ''
            });
            setError('');
        } catch (error) {
            console.error('Error sending email:', error.text);
            setError('Failed to send email. Please try again.');
        }
    };

    return (
        <div
            className="bg-white text-charcoal min-h-screen font-sans leading-normal overflow-x-hidden lg:overflow-auto py-20">
            <main className="flex-1 md:p-0 lg:pt-8 lg:px-8 md:ml-24 flex flex-col">
                <section className="bg-cream-lighter p-4 shadow">
                    <div className="md:flex">
                        <h2 className="mb-8 text-4xl font-black leading-none text-gray-800 uppercase font-bebas-neue sm:text-4xl">
                            Préparez votre commande.
                        </h2>
                    </div>
                    {error && <p className="text-red-500">{error}</p>}
                    {success && <p className="text-green-500">{success}</p>}
                    <form ref={formRef} onSubmit={handleSubmit}>
                        <div className="md:flex mb-8">
                            <div className="md:w-1/3">
                                <legend className="uppercase tracking-wide text-sm">Adresse</legend>
                                <p className="text-xs font-light text-red">Cette section entière est obligatoire.</p>
                            </div>
                            <div className="md:flex-1 mt-2 mb:mt-0 md:px-3">
                                <div className="mb-4">
                                    <label className="block uppercase tracking-wide text-xs font-bold">Nom
                                        complet</label>
                                    <input required className="w-full shadow-inner p-4 border-0" type="text"
                                           name="name" placeholder="Votre nom complet" value={formData.name}
                                           onChange={handleChange}/>
                                </div>
                                <div className="md:flex mb-4">
                                    <div className="md:flex-1 md:pr-3">
                                        <label
                                            className="block uppercase tracking-wide text-charcoal-darker text-xs font-bold">Adresse
                                            de livraison</label>
                                        <input required className="w-full shadow-inner p-4 border-0" type="text"
                                               name="address_street" placeholder="555 Roadrunner Lane"
                                               value={formData.address_street} onChange={handleChange}/>
                                    </div>
                                    <div className="md:flex-1 md:pl-3">
                                        <label
                                            className="block uppercase tracking-wide text-charcoal-darker text-xs font-bold">Adresse
                                            de facturation</label>
                                        <input required className="w-full shadow-inner p-4 border-0" type="text"
                                               name="billing_address" placeholder="Pays et Ville"
                                               value={formData.billing_address} onChange={handleChange}/>
                                        <span className="text-xs mb-4 font-thin">Nous tenons à clarifier que ceci est requis.</span>
                                    </div>
                                </div>
                                <div className="md:flex mb-4">
                                    <div className="md:flex-1 md:pr-3">
                                        <label
                                            className="block uppercase tracking-wide text-charcoal-darker text-xs font-bold">Réponse
                                            Urgente</label>
                                        <div className="w-full flex">
                                            <select required
                                                    className="flex-1 p-4 shadow-inner border-0 uppercase font-semibold bg-transparent"
                                                    name="urgency_selection" value={formData.urgency_selection}
                                                    onChange={handleChange}>
                                                <option value="">Sélectionner</option>
                                                <option value="facebook">Facebook</option>
                                                <option value="Instagram">Instagram</option>
                                                <option value="Whatsapp">Whatsapp</option>
                                                <option value="Telegram">Telegram</option>
                                                <option value="Email">Email</option>
                                                <option value="apple">Appel</option>
                                                <option value="sms">SMS</option>
                                                <option value="autres">Autres</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="md:flex-1 md:pl-3">
                                        <label
                                            className="block uppercase tracking-wide text-charcoal-darker text-xs font-bold">Détail
                                            Urgence</label>
                                        <input required className="w-full shadow-inner p-4 border-0" type="text"
                                               name="urgent_details" placeholder="Les informations pour l'urgence"
                                               value={formData.urgent_details} onChange={handleChange}/>
                                        <span className="text-xs mb-4 font-thin">Nous tenons à clarifier que ceci est requis.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="md:flex mb-8">
                            <div className="md:w-1/3">
                                <legend className="uppercase tracking-wide text-sm">Contact</legend>
                                <p className="text-xs font-light text-red">Cette section entière est obligatoire.</p>
                            </div>
                            <div className="md:flex-1 mt-2 mb:mt-0 md:px-3">
                                <div className="mb-4">
                                    <label className="block uppercase tracking-wide text-xs font-bold">Numéro de
                                        téléphone</label>
                                    <input required className="w-full shadow-inner p-4 border-0" type="tel"
                                           name="phone_number" placeholder="(555) 555-5555 avec Whatsapp svp"
                                           value={formData.phone_number} onChange={handleChange}/>
                                </div>
                                <div className="mb-4">
                                    <label
                                        className="block uppercase tracking-wide text-charcoal-darker text-xs font-bold">Email</label>
                                    <input required className="w-full shadow-inner p-4 border-0" type="email"
                                           name="secondary_email" placeholder="Votre Email"
                                           value={formData.secondary_email} onChange={handleChange}/>
                                </div>
                            </div>
                        </div>
                        <div className="md:flex mb-8">
                            <div className="md:w-1/3">
                                <legend className="uppercase tracking-wide text-sm">Détails de la commande</legend>
                                <p className="text-xs font-light text-red">Cette section entière est obligatoire.</p>
                            </div>
                            <div className="md:flex-1 mt-2 mb:mt-0 md:px-3">
                                <div className="md:flex mb-4">
                                    <div className="md:flex-1 md:pr-3">
                                        <label
                                            className="block uppercase tracking-wide text-charcoal-darker text-xs font-bold">Devise</label>
                                        <div className="w-full flex">
                                            <select required
                                                    className="flex-1 p-4 shadow-inner border-0 uppercase font-semibold bg-transparent"
                                                    name="currency" value={formData.currency} onChange={handleChange}>
                                                <option value="">Sélectionner</option>
                                                <option value="EUR">EUR</option>
                                                <option value="USD">USD</option>
                                                <option value="RMB">RMB</option>
                                                <option value="FCFA">FCFA</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="md:flex-1 md:pl-3 mt-2 md:mt-0">
                                        <label
                                            className="block uppercase tracking-wide text-charcoal-darker text-xs font-bold">Livraison</label>
                                        <div className="w-full flex">
                                            <select required
                                                    className="flex-1 p-4 shadow-inner border-0 uppercase font-semibold bg-transparent"
                                                    name="delivery_mode" value={formData.delivery_mode}
                                                    onChange={handleChange}>
                                                <option value="">Sélectionner</option>
                                                <option value="express">Express</option>
                                                <option value="avion">Avion</option>
                                                <option value="bateau">Bateau</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="md:flex mb-4">
                                    <div className="md:flex-1 md:pr-3">
                                        <label
                                            className="block uppercase tracking-wide text-charcoal-darker text-xs font-bold">Services</label>
                                        <div className="w-full flex">
                                            <select required
                                                    className="flex-1 p-4 shadow-inner border-0 uppercase font-semibold bg-transparent"
                                                    name="required_services" value={formData.required_services}
                                                    onChange={handleChange}>
                                                <option value="">Sélectionner</option>
                                                <option value="Alibaba et autres">Alibaba et autres</option>
                                                <option value="Mon fournisseur">Mon fournisseur</option>
                                                <option value="Ma facture">Ma facture</option>
                                                <option value="Transport">Transport</option>
                                                <option value="CRÉATIVITÉ">CRÉATIVITÉ</option>
                                                <option value="ASSISTANCE">ASSISTANCE</option>
                                                <option value="autres">Autres</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="md:flex-1 md:pl-3 mt-2 md:mt-0">
                                        <label
                                            className="block uppercase tracking-wide text-charcoal-darker text-xs font-bold">Budget</label>
                                        <div className="w-full flex">
                                            <input className="flex-1 shadow-inner p-4 border-0 bg-transparent"
                                                   type="text"
                                                   name="budget_amount" placeholder="150.000 FCFA"
                                                   value={formData.budget_amount} onChange={handleChange}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="md:flex mb-4">
                                    <div className="md:flex-1 md:pr-3">
                                        <label
                                            className="block uppercase tracking-wide text-charcoal-darker text-xs font-bold">Date
                                            de paiement</label>
                                        <div className="w-full flex">
                                            <input className="flex-1 shadow-inner p-4 border-0 bg-transparent"
                                                   type="date"
                                                   name="payment_date" placeholder="07/05/2024"
                                                   value={formData.payment_date} onChange={handleChange}/>
                                        </div>
                                    </div>
                                    <div className="md:flex-1 md:pl-3 mt-2 md:mt-0">
                                        <label
                                            className="block uppercase tracking-wide text-charcoal-darker text-xs font-bold">Méthode
                                            de Paiement</label>
                                        <div className="w-full flex">
                                            <select required
                                                    className="flex-1 p-4 shadow-inner border-0 uppercase font-semibold bg-transparent"
                                                    name="payment_method" value={formData.payment_method}
                                                    onChange={handleChange}>
                                                <option value="">Sélectionner</option>
                                                <option value="Western Union">Western Union</option>
                                                <option value="MoneyGram">MoneyGram</option>
                                                <option value="Express">Express 24h/7j</option>
                                                <option value="Airtel Money">Airtel Money</option>
                                                <option value="Moov Money">Moov Money</option>
                                                <option value="Orange Money">Orange Money</option>
                                                <option value="Visa et Banque">Visa et Banque...</option>
                                                <option value="Paypal">Paypal</option>
                                                <option value="Autres">Autres</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="md:flex mb-4">
                                    <div className="md:flex-1 md:pr-3">
                                        <label
                                            className="block uppercase tracking-wide text-charcoal-darker text-xs font-bold">Profil
                                            client</label>
                                        <div className="w-full flex">
                                            <select required
                                                    className="flex-1 p-4 shadow-inner border-0 uppercase font-semibold bg-transparent"
                                                    name="customer_profile" value={formData.customer_profile}
                                                    onChange={handleChange}>
                                                <option value="">Sélectionner</option>
                                                <option value="détaillants en ligne">Détaillants en ligne</option>
                                                <option value="Grossistes">Grossistes et distributeurs</option>
                                                <option value="Fabricants">Fabricants</option>
                                                <option value="Entrepreneurs">Entrepreneurs</option>
                                                <option value="Importateurs">Importateurs</option>
                                                <option value="Entreprises">Entreprises de commerce international
                                                </option>
                                                <option value="électronique">Entreprises de commerce électronique
                                                </option>
                                                <option value="Détaillants traditionnels">Détaillants traditionnels
                                                </option>
                                                <option value="autres">autres</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="md:flex-1 md:pl-3 mt-2 md:mt-0">
                                        <label
                                            className="block uppercase tracking-wide text-charcoal-darker text-xs font-bold">Numéro
                                            de Facture</label>
                                        <div className="w-full flex">
                                            <input readOnly className="flex-1 shadow-inner p-4 border-0" type="text"
                                                   name="invoice_number" value={formData.billNumber}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="md:flex mb-6">
                            <div className="md:w-1/3">
                                <legend className="uppercase tracking-wide text-sm">Description</legend>
                                <p className="text-xs font-light text-red">Cette section entière est obligatoire.</p>
                            </div>
                            <div className="md:flex-1 mt-2 mb:mt-0 md:px-3">
                                <textarea
                                    required
                                    name="description"
                                    className="w-full shadow-inner p-4 border-0"
                                    placeholder="Veuillez fournir plus de détails sur votre commande."
                                    rows="6"
                                    value={formData.description}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="md:flex mb-6">
                            <div className="md:w-1/3">
                                <legend className="uppercase tracking-wide text-sm">Informations</legend>
                            </div>
                            <div className="md:flex-1 px-3 text-center">
                                <p className="text-left font-medium bg-gold hover:bg-gold-dark text-cream mx-auto cursor-pointer relative">
                                    Vos informations seront utilisées exclusivement pour l'établissement de votre
                                    facture, d'un proforma ou tout autre document nécessaire à votre transaction. Elles
                                    ne seront en aucun cas commercialisées ni transmises à une tierce partie. Nous
                                    respectons la confidentialité de vos données. Merci pour votre confiance.
                                </p>
                            </div>
                        </div>
                        <div className="md:flex mb-6 border border-t-1 border-b-0 border-x-0 border-cream-dark">
                            <div className="mt-4 md:flex-1 px-3 text-center md:text-right">
                                <button
                                    className="px-4 py-2 mr-4 text-white uppercase bg-red-500 border-2 border-transparent rounded-lg text-md hover:bg-red-400"
                                    type="submit">Envoyer
                                </button>
                            </div>
                        </div>
                    </form>
                </section>
            </main>
        </div>
    );
};

export default Order;
