import React from 'react';
import pageData from "./pageData";
import Stats from "./Stats";

function TopOfPage({page}) {
    const {title, paragraph} = pageData[page];
    return (
        <div>
            <div className="relative overflow-hidden">
                <div aria-hidden="true" className="flex absolute -top-96 start-1/2 transform -translate-x-1/2">
                    <div
                        className="bg-gradient-to-r from-violet-300/50 to-purple-100 blur-3xl w-[25rem] h-[44rem] rotate-[-60deg] transform -translate-x-[10rem] "></div>
                    <div
                        className="bg-gradient-to-tl from-blue-50 via-blue-100 to-blue-50 blur-3xl w-[90rem] h-[50rem] rounded-fulls origin-top-left -rotate-12 -translate-x-[15rem] "></div>
                </div>
                <div className="relative z-10">
                    <div className="max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8 py-10 lg:py-16">
                        <div className="max-w-2xl text-center mx-auto">
                            <p className="inline-block text-sm font-medium bg-clip-text bg-gradient-to-l from-blue-600 to-violet-500 text-transparent ">
                                Nouvelle collection
                            </p>
                            <div className="mt-5 max-w-2xl">
                                <h1 className="block font-extrabold text-gray-800 text-4xl md:text-5xl lg:text-6xl ">
                                    {title}
                                </h1>
                            </div>
                            <div className="mt-5 max-w-3xl">
                                <p className="text-lg text-gray-600 ">
                                    {paragraph}
                                </p>
                            </div>
                            <div className="mt-6 gap-3 flex justify-center">
                                <Stats/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TopOfPage;
