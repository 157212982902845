import React, {useEffect, useState} from 'react';
import {UserAuth} from "../../context/AuthContext";
import {useNavigate} from "react-router-dom";
import logo from "../../assets/img/logo/mylogo.png";

const SignIn = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const {signIn, user} = UserAuth(); // Add googleSignIn here


    useEffect(() => {
        if (user) navigate('/'); // Navigate to the homepage if there's a user
    }, [user, navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        try {
            await signIn(email, password);
            navigate('/');
        } catch {
            setError('Le mot de passe est incorrect ou l\'utilisateur n\'existe pas.');
        }
    };
    return (
        <div>
            <div className="flex h-screen w-full items-center justify-center bg-gray-900 bg-cover bg-no-repeat">
                <div
                    className="rounded-xl bg-gray-800 bg-opacity-50 px-16 py-10 shadow-lg backdrop-blur-md max-sm:px-8">
                    <div className="text-white text-center">
                        <div className="mb-4 flex flex-col items-center">
                            <img src={logo} width="100" alt="logo medinchina"/>
                            <h1 className="mb-2 text-xl">Medinchina</h1>
                        </div>
                        <div className="mt-4 max-w-[280px] mx-auto">
                            <div className="flex flex-col items-center">
                                {error && (
                                    <div
                                        className="mb-3 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                                        role="alert">
                                        <strong className="font-bold">Error!</strong>
                                        <span className="block sm:inline">{error}</span>
                                    </div>
                                )}
                                <p className="text-center text-white text-[14px] font-semibold mb-5">
                                    Connectez-vous pour accéder à votre compte.
                                </p>
                                <form onSubmit={handleSubmit}>
                                    <input
                                        type="email"
                                        className="w-full px-6 py-3 mb-2 text-secondary-800 border border-white rounded-lg font-medium"
                                        placeholder="Votre email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                    <input
                                        type="password"
                                        className="w-full px-6 py-3 mb-2 text-secondary-800 border border-white rounded-lg font-medium"
                                        placeholder="Mot de passe"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                    <button
                                        type="submit"
                                        className="bg-red-500 hover:bg-red-700 text-white text-base rounded-lg py-2.5 px-5 transition-colors w-full text-[19px]">
                                        Connexion
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SignIn;
