import React from 'react';
import {Change} from "../index";

const Mesh = () => {
    return (
        <div>
            <Change/>
        </div>
    );
};

export default Mesh;