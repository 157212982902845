import React from 'react';
import AllRoutes from "./routes/AllRoutes";

const App = () => {
    return (
        <>
            <AllRoutes />
        </>
    );
};

export default App;