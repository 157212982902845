import React from 'react';
import {TopOfPage} from "../index";

const TopQuestion = () => {
    return (
        <div>
            <TopOfPage page="Question"/>
        </div>
    );
};

export default TopQuestion;