// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {getAuth} from "firebase/auth";
import {getFirestore} from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAmZSVkdBaHLaY5yZINvBvorIDUEUqSjuw",
    authDomain: "medinchina-f8dc6.firebaseapp.com",
    projectId: "medinchina-f8dc6",
    storageBucket: "medinchina-f8dc6.appspot.com",
    messagingSenderId: "262111461370",
    appId: "1:262111461370:web:ebf806ad667061ca99ee43"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export default app;