import React, {useCallback, useEffect, useState} from 'react';
import {Link} from "react-router-dom";


const Assistance = () => {
    const today = new Date().toISOString().split('T')[0]; // Get today's date in ISO format
    const [dates, setDates] = useState({checkIn: '', checkOut: ''});
    const [numberOfDays, setNumberOfDays] = useState(0);
    const [currency, setCurrency] = useState("USD");
    const [calculatedAmount, setCalculatedAmount] = useState(0);

    // Consolidate useEffects that reset fields on currency change
    useEffect(() => {
        setDates({checkIn: '', checkOut: ''});
        setNumberOfDays(0);
        setCalculatedAmount(0);
    }, [currency]);

    const calculateAmount = useCallback(() => {
        const rates = {
            USD: [41, 25, 17, 14, 8],
            EUR: [38, 25, 17, 12, 8],
            RMB: [297, 178, 119, 95, 60],
            FCFA: [25000, 15000, 10000, 8000, 5000]
        };
        const dayRanges = [7, 14, 21, 28];

        const getRate = (days) => {
            let rateIndex = dayRanges.findIndex(range => days <= range);
            if (rateIndex === -1) rateIndex = dayRanges.length; // Use last rate if no range matches
            return rates[currency][rateIndex];
        };

        if (numberOfDays > 0) {
            const amount = numberOfDays * getRate(numberOfDays);
            setCalculatedAmount(amount.toFixed(2));
        }
    }, [numberOfDays, currency]);

    useEffect(() => {
        if (dates.checkIn && dates.checkOut) {
            calculateAmount();
        }
    }, [dates, calculateAmount]);

    const handleDateChange = (e, type) => {
        const updatedDates = {...dates, [type]: e.target.value};
        setDates(updatedDates);
        calculateNumberOfDays(updatedDates.checkIn, updatedDates.checkOut);
    };

    const calculateNumberOfDays = (checkIn, checkOut) => {
        if (checkIn && checkOut) {
            const oneDay = 24 * 60 * 60 * 1000;
            const firstDate = new Date(checkIn);
            const secondDate = new Date(checkOut);
            const diffDays = Math.round(Math.abs((secondDate - firstDate) / oneDay));
            setNumberOfDays(diffDays);
        }
    };
    return (
        <div>
            <div className="py-16">
                <div
                    className="grid sm:grid-cols-2 items-center gap-16 p-8 mx-auto max-w-4xl bg-white shadow-[0_2px_10px_-3px_rgba(6,81,237,0.3)] rounded-md text-[#333] font-[sans-serif]">
                    <div>
                        <h1 className="text-3xl font-extrabold">FRAIS DE GUIDE
                        </h1>
                        <p className="text-sm text-gray-400 mt-3">
                            Ceci est un simulateur de prix pour un service de guide en Chine. Les prix sont des
                            estimations. Si vous êtes intéressé, contactez-nous pour plus de détails.
                        </p>
                    </div>

                    <form className="ml-auo space-y-4">
                        <span className="text-sm font-extrabold">Date d'Arriver</span>
                        <input required
                               type="date"
                               min={today}
                               value={dates.checkIn}
                               onChange={(e) => handleDateChange(e, 'checkIn')}
                               className="w-full rounded-md py-2.5 px-4 border text-sm outline-[#007bff] bg-transparent"/>
                        <h2 className="text-sm font-extrabold">Date de Depart</h2>
                        <input required
                               type="date"
                               min={dates.checkIn}
                               value={dates.checkOut}
                               onChange={(e) => handleDateChange(e, 'checkOut')}
                               className="w-full rounded-md py-2.5 px-4 border text-sm outline-[#007bff] bg-transparent"/>
                        <select id="currency"
                                value={currency}
                                onChange={(e) => setCurrency(e.target.value)}
                                className="w-full rounded-md py-2.5 px-4 border text-sm outline-[#007bff]">
                            <option value="">Votre devise</option>
                            <option value="EUR">EUR</option>
                            <option value="USD">USD</option>
                            <option value="RMB">RMB</option>
                            <option value="FCFA">FCFA</option>
                        </select>
                        <div
                            className="w-full rounded-md py-2.5 px-4 border text-sm outline-[#007bff]">
                            {numberOfDays} Jours
                        </div>
                        <p className="w-full rounded-md px-4 border text-sm p-2.5 outline-[#007bff]">
                            Nous sommes heureux de vous informer que le coût de votre service de guide en
                            Chine s'élèvera à {calculatedAmount} {currency} pour une durée
                            de {numberOfDays} jours. Ce montant comprend les
                            honoraires du guide. Pour des
                            informations complémentaires sur notre service de guide et les détails de votre
                            réservation, veuillez cliquer{' '}
                            <Link to={"/contact"} className="text-primary-700 font-bold"> ici </Link>.
                        </p>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Assistance;