import React from 'react';


const Board = () => {
    return (
        <>
        </>
    );
};

export default Board;