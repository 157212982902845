import React from 'react';
import {SeeClients} from "../components";
import Header3 from "../layout/header/Header3";
import Footer from "../layout/footer/Footer";

const OurCustomer = () => {
    return (
        <div>
            <Header3/>
            <section className="py-6 dark:bg-gray-100 dark:text-gray-900">
                <div
                    className="container mx-auto flex flex-col justify-center p-4 space-y-8 md:p-10 lg:space-y-0 lg:space-x-12 lg:justify-between lg:flex-row">
                    <div className="flex flex-col space-y-4 text-center lg:text-left">
                        <h1 className="text-5xl font-bold leading-none">Liste de nos clients</h1>
                        <p className="text-lg">Doloribus consectetur quasi ipsa quo neque culpa blanditiis ducimus
                            recusandae a veritatis optio cumque, in harum ad nam!</p>
                    </div>
                </div>
            </section>
            <SeeClients/>
            <Footer/>
        </div>
    );
};

export default OurCustomer;