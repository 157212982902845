import React, {useState, useEffect} from 'react';
import {Link} from "react-router-dom";

const MyEvent = () => {
    const [days, setDays] = useState(0);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);

    useEffect(() => {
        // Définir la date de fin du compte à rebours (31 juillet)
        const countdownDate = new Date('2024-12-31T23:59:59Z').getTime();

        const interval = setInterval(() => {
            const now = new Date().getTime();
            const distance = countdownDate - now;

            if (distance < 0) {
                clearInterval(interval);
                setDays(0);
                setHours(0);
                setMinutes(0);
                setSeconds(0);
            } else {
                setDays(Math.floor(distance / (1000 * 60 * 60 * 24)));
                setHours(Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
                setMinutes(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)));
                setSeconds(Math.floor((distance % (1000 * 60)) / 1000));
            }
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div>
            <section className="py-24 bg-slate-900 bg-gradient dark:bg-gray-800 md:py-24">
                <div className="box-content max-w-5xl px-5 mx-auto">
                    <div className="flex flex-col items-center -mx-5 md:flex-row">
                        <div className="w-full px-5 mb-5 text-center md:mb-0 md:text-left">
                            <h6 className="text-xs font-semibold text-green-600 uppercase md:text-base">
                                Compte à rebours fin 2024
                            </h6>
                            <h3 className="text-2xl font-bold text-white font-heading md:text-4xl">
                                Jusqu'au 31 Dec
                            </h3>
                            <h3 className="text-lg font-semibold leading-tight text-white font-heading md:text-xl">
                                Offre spéciale Medinchina.
                            </h3>
                            <div className="w-full mt-4 md:w-44">
                                <button>
                                    <Link to="/clients"
                                          className="w-full px-4 py-3 text-lg font-semibold text-white bg-primary-500 rounded-lg hover:bg-primary-600">
                                        Devenir Client.
                                    </Link>
                                </button>
                            </div>
                        </div>
                        <div className="w-full px-5 md:w-auto">
                            <div className="flex justify-center text-center text-white">
                                <div
                                    className="w-20 py-3 mx-2 border rounded-lg md:w-24 border-light-300 bg-light-100 md:py-4">
                                    <div className="text-2xl font-semibold md:text-3xl">
                                        <span>{days}</span>
                                    </div>
                                    <div className="mt-3 text-xs uppercase opacity-75">Jours</div>
                                </div>
                                <div
                                    className="w-20 py-3 mx-2 border rounded-lg md:w-24 border-light-300 bg-light-100 md:py-4">
                                    <div className="text-2xl font-semibold md:text-3xl">
                                        <span>{hours}</span>
                                    </div>
                                    <div className="mt-3 text-xs uppercase opacity-75">Heures</div>
                                </div>
                                <div
                                    className="w-20 py-3 mx-2 border rounded-lg md:w-24 border-light-300 bg-light-100 md:py-4">
                                    <div className="text-2xl font-semibold md:text-3xl">
                                        <span>{minutes}</span>
                                    </div>
                                    <div className="mt-3 text-xs uppercase opacity-75">Minutes</div>
                                </div>
                                <div
                                    className="w-20 py-3 mx-2 border rounded-lg md:w-24 border-light-300 bg-light-100 md:py-4">
                                    <div className="text-2xl font-semibold md:text-3xl">
                                        <span>{seconds}</span>
                                    </div>
                                    <div className="mt-3 text-xs uppercase opacity-75">Secondes</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default MyEvent;
