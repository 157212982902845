import NavBar from "./header/MainHeader";
import Footer from "./footer/Footer"


const MainLayout = ({children}) => {
    return (
        <>
            <NavBar/>
            <main>{children}</main>
            <Footer/>
        </>
    );
};
export default MainLayout;