import React from 'react';

const Faq = () => {
    return (
        <div>
            <section className="bg-white ">
                <div className="container flex flex-col justify-center p-4 mx-auto md:p-8">
                    <p className="p-2 text-sm font-medium tracking-wider text-center text-primary-700 uppercase">
                        Comment ça marche</p>
                    <h2 className="mb-12 text-4xl  text-secondary-800 font-bold leading-none text-center sm:text-5xl">
                        Questions Fréquemment Posées</h2>
                    <div
                        className="flex text-secondary-700 flex-col divide-y sm:px-8 lg:px-12 xl:px-32 divide-primary-900">
                        <details>
                            <summary className="py-2 outline-none cursor-pointer focus:underline">
                                Quelle est la durée approximative de l'envoi par bateau avec MedInChina ?
                            </summary>
                            <div className="px-4 pb-4">
                                <p>L'envoi par bateau prend environ 2 mois, soit environ 60 jours.</p>
                            </div>
                        </details>
                        <details>
                            <summary className="py-2 outline-none cursor-pointer focus:underline">
                                Comment se fait le paiement pour l'envoi par bateau avec MedInChina ?
                            </summary>
                            <div className="px-4 pb-4">
                                <p>
                                    Le paiement pour l'envoi par bateau se fait à 100% au Gabon à la réception du colis,
                                    avec un tarif de 240 mil par CMB (m³) de marchandise.
                                </p>
                            </div>
                        </details>
                        <details>
                            <summary className="py-2 outline-none cursor-pointer focus:underline">
                                Pourquoi les colis contenant des batteries, de la poudre ou des liquides ne peuvent-ils
                                pas être envoyés par avion avec MedInChina ?
                            </summary>
                            <div className="px-4 pb-4 space-y-2">
                                <p>Les colis contenant ces éléments ne peuvent pas être envoyés par avion vers le Gabon
                                    en raison de contraintes douanières.
                                </p>
                            </div>
                        </details>
                        <details>
                            <summary className="py-2 outline-none cursor-pointer focus:underline">
                                Quelle est la durée approximative de l'envoi par avion avec MedInChina ?
                            </summary>
                            <div className="px-4 pb-4 space-y-2">
                                <p>
                                    L'envoi par avion prend environ 2 semaines.
                                </p>
                            </div>
                        </details>
                        <details>
                            <summary className="py-2 outline-none cursor-pointer focus:underline">
                                Comment se fait le paiement pour l'envoi par avion avec MedInChina ?
                            </summary>
                            <div className="px-4 pb-4 space-y-2">
                                <p>
                                    Le paiement pour l'envoi par avion se fait à 65% du montant total depuis la Chine,
                                    et à 35% au Gabon, à la réception du colis dans l'entrepôt de l'agence partenaire.
                                </p>
                            </div>
                        </details>
                        <details>
                            <summary className="py-2 outline-none cursor-pointer focus:underline">
                                Quels sont les moyens de paiement acceptés par MedInChina ?
                            </summary>
                            <div className="px-4 pb-4 space-y-2">
                                <p>
                                    MedInChina accepte plusieurs moyens de paiement tels que PayPal, Airtel Money,
                                    Western Union, dépôt bancaire ou MoneyGram.
                                </p>
                            </div>
                        </details>
                        <details>
                            <summary className="py-2 outline-none cursor-pointer focus:underline">
                                À quel moment le montant total sera-t-il transmis à l'utilisateur avec MedInChina ?
                            </summary>
                            <div className="px-4 pb-4 space-y-2">
                                <p>
                                    Le montant total sera transmis une fois que le produit ou le service sera clairement
                                    défini, et aucune facture ne sera émise avant cet accord pour éviter tout
                                    malentendu.
                                </p>
                            </div>
                        </details>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Faq;