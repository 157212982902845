import React from 'react';
import {Link} from "react-router-dom";

const NotFound = () => {
    return (
        <div className="py-24">
            <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
                <div className="text-center">
                    <p className="text-base font-semibold text-red-600">404</p>
                    <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Page non
                        disponible</h1>
                    <p className="mt-6 text-base leading-7 text-gray-600">
                        Désolé, nous n'avons pas pu trouver la page
                        que vous recherchez.
                    </p>
                    <div className="mt-10 flex items-center justify-center gap-x-6">
                        <Link to={"/"}
                              className="rounded-md bg-red-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">
                            Accueil</Link>
                        <Link to={"/contact"} className="text-sm font-semibold text-gray-900">Contact support <span
                            aria-hidden="true">&rarr;</span></Link>
                    </div>
                </div>
            </main>

        </div>
    );
};

export default NotFound;