import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import products from '../../Data/paysliste';
import {Link} from "react-router-dom";

const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10000
};

const Slide = ({product}) => (
    <section>
        <div className="bg-white flex items-center justify-center p-12 py-6">
            <div className="mx-auto w-full max-w-screen-lg bg-slate-800 px-5 py-10 rounded-2xl">
                <div className="grid gap-5 md:grid-cols-2 md:gap-10 lg:gap-20">
                    <div className="flex justify-center md:justify-end">
                        <img className="w-full max-w-sm rounded-xl"
                             src={product.flag}
                             alt="Marketing newsletter via computer Illustration in PNG, SVG"/>
                    </div>
                    <div className="flex items-center">
                        <div className="mx-auto md:mx-0">
                            <h3 className="text-4xl font-bold text-white uppercase">{product.country}</h3>
                            <p className="mt-2 max-w-[20rem] text-lg text-white/80">
                                Votre commande sera traitée avec la plus grande diligence et expédiée par avion
                                vers des destinations telles que <span
                                className="text-lg text-white font-semibold">{product.City1}</span> ou <span
                                className="text-lg text-white font-semibold">{product.City2}</span>.
                            </p>
                            <div className=" mt-4 flex flex-col items-center md:flex-row">
                                <Link to={"/delivery"}
                                      className=" bg-primary-700 inline-flex items-center justify-center w-full h-12 px-6 mb-3 font-medium
                            tracking-wide text-white transition duration-200 rounded shadow-md md:w-auto md:mr-4 md:mb-0
                            bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline
                            focus:outline-none"
                                >
                                    Maintenant
                                </Link>
                                <Link to={"/contact"}
                                      className="inline-flex items-center font-semibold text-white transition-colors duration-200 hover:text-deep-purple-accent-700"
                                >
                                    En savoir +
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
);

const Slideshow = () => (
    <Slider {...settings}>
        {products.map((product, index) => (
            <Slide key={product.id || index} product={product}/>
        ))}
    </Slider>
);

export default Slideshow;
