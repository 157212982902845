import Header1 from './Header1.jsx';
import Header2 from './Header2.jsx';
import useWindowSize from './useWindowSize.js';

const MainHeader = () => {
    const {width} = useWindowSize();

    return (
        <div>
            {width >= 1024 ? <Header1/> : <Header2/>}
        </div>
    );
};

export default MainHeader;
