const Clients = () => {
    return (
        <section className="bg-white text-gray-800">
            <div className="container px-6 py-12 mx-auto">
                <div className="grid items-center gap-4 xl:grid-cols-5">
                    <div className="max-w-2xl mx-auto my-8 space-y-4 text-center xl:col-span-2 xl:text-left">
                        <h2 className="text-4xl  font-bold text-primary-800 capitalize">Nos clients
                            satisfaits</h2>
                        <p className="text-gray-600 md:text-left">
                            Découvrez ce que nos clients satisfaits ont à dire sur
                            leur expérience avec notre service. Leurs témoignages sont une véritable
                            preuve de notre engagement envers la satisfaction client et de la qualité
                            de nos services. Laissez-vous inspirer par leurs histoires !
                        </p>
                    </div>
                    <div className="p-6 xl:col-span-3">
                        <div className="grid gap-4 md:grid-cols-2">
                            <div className="grid content-center gap-4">
                                <div className="p-6 rounded shadow-md ">
                                    <p className="text-gray-600">
                                        " Medinchina a rendu mes achats en Chine incroyablement
                                        simples. Leur équipe était toujours disponible pour répondre à mes questions
                                        et leur processus d'expédition était rapide et efficace.
                                        Je recommande vivement leurs services. "
                                    </p>
                                    <div className="flex items-center mt-4 space-x-4">
                                        <div>
                                            <p className="text-lg font-semibold text-gray-600">Ange Mukendi</p>
                                            <p className="text-sm text-gray-600">Client Medinchina Congo (DRC)</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-6 rounded shadow-md dark:bg-gray-50">
                                    <p className="text-gray-600">
                                        " Je suis extrêmement satisfait de Medinchina. Leur
                                        sélection de produits
                                        est impressionnante et leur service clientèle est excellent.
                                        J'ai reçu tous mes articles en parfait état et dans les délais promis. "
                                    </p>
                                    <div className="flex items-center mt-4 space-x-4">
                                        <div>
                                            <p className="text-lg font-semibold text-gray-600">Laurent Bonnet</p>
                                            <p className="text-sm text-gray-600">Client Medinchina France</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="grid content-center gap-4">
                                <div className="p-6 rounded shadow-md dark:bg-gray-50">
                                    <p className="text-gray-600">
                                        " En tant que client fidèle de Medinchina, je peux témoigner de leur fiabilité
                                        et
                                        de leur professionnalisme.
                                        Leur équipe travaille dur pour s'assurer que chaque commande est traitée avec le
                                        plus grand soin,
                                        ce qui me donne une tranquillité d'esprit totale. "
                                    </p>
                                    <div className="flex items-center mt-4 space-x-4">
                                        <div>
                                            <p className="text-lg font-semibold text-gray-600">Matrix BITEGUE</p>
                                            <p className="text-sm text-gray-600">Client Medinchina Gabon.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-6 rounded shadow-md dark:bg-gray-50">
                                    <p className="text-gray-600">
                                        " Je suis ravi d'avoir découvert Medinchina pour mes achats en Chine.
                                        Leur site est convivial, leur processus de commande est simple et leurs
                                        tarifs sont compétitifs. Je n'hésiterai pas à utiliser à nouveau
                                        leurs services à l'avenir. "
                                    </p>
                                    <div className="flex items-center mt-4 space-x-4">
                                        <div>
                                            <p className="text-lg font-semibold text-gray-600">Lea N. Mvondo</p>
                                            <p className="text-sm text-gray-600">Client Medinchina Cameroun</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Clients;