import React from 'react';
import ListOfUser from "./ListOfUser";

const SeeClients = () => {
    return (
        <div>
            <ListOfUser/>
        </div>
    );
};

export default SeeClients;