import React from 'react';
import {pro01, pro02, pro03, pro04, pro05} from '../../assets';
import {Link} from "react-router-dom";

const Plan = () => {
    return (
        <div className='max-w-[1400px] m-auto py-16 px-4 grid lg:grid-cols-2 gap-4'>
            {/* Left Side */}
            <div className='grid grid-cols-2 grid-rows-6 h-[80vh]'>
                <img
                    className='row-span-3 object-cover w-full h-full p-2'
                    src={pro01}
                    alt='/'
                />
                <img
                    className='row-span-2 object-cover w-full h-full p-2'
                    src={pro02}
                    alt='/'
                />
                <img
                    className='row-span-2 object-cover w-full h-full p-2'
                    src={pro03}
                    alt='/'
                />
                <img
                    className='row-span-3 object-cover w-full h-full p-2'
                    src={pro04}
                    alt='/'
                />
                <img
                    className='row-span-2 object-cover w-full h-full p-2'
                    src={pro05}
                    alt='/'
                />
            </div>
            {/* Right Side */}
            <div className='flex flex-col h-full justify-center'>
                <h3 className='text-5xl md:text-6xl font-bold'>Toujours
                    excitant.</h3>
                <p className='text-2xl py-6'>
                    Un monde que nous connaissons bien.
                </p>
                <p className='pb-6'>
                    Avec des années d'expérience, MEDINCHINA vous offre la possibilité de vous lancer dans le business
                    de votre choix en toute confiance, même avec le budget le plus serré.
                </p>
                <div>
                    <Link to={"/orders"}>
                        <button className='bg-black text-white p-3 border-black rounded-full hover:shadow-xl'>Passez
                            votre
                            commande
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Plan;