import React, {useState} from 'react';
import products from '../../Data/paysliste';

const SearchComponent = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const [showResults, setShowResults] = useState(false);

    const handleSearchInputChange = (e) => {
        setSearchQuery(e.target.value);
        if (e.target.value.trim() === '') {
            setShowResults(false);
        }
    };

    const handleSearchSubmit = (e) => {
        e.preventDefault();
        setShowResults(true);
    };

    const filteredProducts = products.filter((product) => {
        return (
            product.country.toLowerCase().includes(searchQuery.toLowerCase()) ||
            product.City1.toLowerCase().includes(searchQuery.toLowerCase()) ||
            product.City2.toLowerCase().includes(searchQuery.toLowerCase())
        );
    });

    return (
        <div className=" max-w-4xl mx-auto px-4 py-10 sm:px-6 lg:px-8">
            <div className="max-w-3xl text-center mx-auto">
                <h2 className="mb-12 text-gray-800 text-4xl font-bold leading-none text-center sm:text-3xl">
                    Retrouvez votre pays et les villes desservies par Medinchina.
                </h2>
            </div>
            <form onSubmit={handleSearchSubmit} className="mt-8 sm:flex">
                <label htmlFor="default-search" className="sr-only">Search</label>
                <div className="relative w-full sm:flex-1">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <svg className="h-5 w-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
                            <path fillRule="evenodd"
                                  d="M11 12a1 1 0 0 1-2 0V8a1 1 0 0 1 2 0v4zm3.293 2.293a1 1 0 0 1-1.414 1.414l-3-3a1 1 0 0 1 1.414-1.414l3 3zM6 12a4 4 0 1 1 0-8 4 4 0 0 1 0 8z"
                                  clipRule="evenodd"/>
                        </svg>
                    </div>
                    <input
                        id="default-search"
                        className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:border-red-300 focus:ring focus:ring-red-200 focus:ring-opacity-50 sm:text-sm"
                        placeholder="Recherche de pays ou de ville..."
                        type="search"
                        value={searchQuery}
                        onChange={handleSearchInputChange}
                        required
                    />
                </div>
                <button
                    type="submit"
                    className="mt-3 w-full sm:mt-0 sm:ml-3 bg-red-600 border border-transparent rounded-md py-2 px-4 flex items-center justify-center text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:w-auto sm:flex-shrink-0 sm:inline-flex sm:items-center sm:justify-center"
                >
                    Rechercher
                </button>
            </form>

            {showResults && searchQuery.trim() !== '' && (
                <div className="mt-5 max-h-96 overflow-y-auto">
                    {filteredProducts.length > 0 ? (
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                            <tr>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Flag</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Pays</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Ville
                                    1
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Ville
                                    2
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">FCFA
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Dollar
                                </th>
                            </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                            {filteredProducts.map((product) => (
                                <tr key={product._id}>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <img src={product.flag} alt={product.country}
                                             className="w-6 h-6 inline-block mr-2"/>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">{product.country}</td>
                                    <td className="px-6 py-4 whitespace-nowrap">{product.City1}</td>
                                    <td className="px-6 py-4 whitespace-nowrap">{product.City2}</td>
                                    <td className="px-6 py-4 whitespace-nowrap">{product.XAF} FCFA/kg</td>
                                    <td className="px-6 py-4 whitespace-nowrap">${product.USD}/kg</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    ) : (
                        <div className="text-gray-500 dark:text-gray-400">No results found.</div>
                    )}
                </div>
            )}
        </div>
    );
};

export default SearchComponent;
