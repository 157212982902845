import React, {useCallback, useEffect, useState, useMemo} from 'react';
import {Link} from 'react-router-dom';

const currencyRates = {
    USD: [[241.93, 0.15], [1000, 0.10], [15000, 0.05], [Infinity, 0.04]],
    EUR: [[250, 0.15], [1000, 0.10], [15000, 0.05], [Infinity, 0.04]],
    RMB: [[1800, 0.15], [7300, 0.10], [110000, 0.05], [Infinity, 0.04]],
    FCFA: [[150000, 0.15], [600000, 0.10], [10000000, 0.05], [Infinity, 0.04]]
};

const Product = () => {
    const [formData, setFormData] = useState({
        price: "",
        quantity: "",
        currency: "USD",
        benefit: "",
    });
    const [message, setMessage] = useState({errorMessage: "", successMessage: ""});

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value.trim() !== "" ? value : "",
        }));
        if (name === 'currency') {
            setMessage({errorMessage: "", successMessage: ""});
        }
    };

    const memoizedFormData = useMemo(() => formData, [formData]);

    const calculateCurrencyTotal = (priceValue, quantityValue, currency) => {
        let total = priceValue * quantityValue;
        const rates = currencyRates[currency] || [[Infinity, 1]];
        for (let [limit, rate] of rates) {
            if (total <= limit) {
                return total * rate;
            }
        }
        return total; // Fallback in case of no matching currency
    };

    const calculateTotalAndStatus = useCallback(() => {
        const {price, quantity, currency} = memoizedFormData;
        const priceValue = parseFloat(price);
        const quantityValue = parseFloat(quantity);

        if (isNaN(priceValue) || isNaN(quantityValue)) {
            setMessage({errorMessage: 'Please enter valid values', successMessage: ''});
            setTimeout(() => setMessage({errorMessage: ''}), 10000); // 10 seconds
            return;
        }

        let total = calculateCurrencyTotal(priceValue, quantityValue, currency);
        const reprice = total + priceValue * quantityValue;
        const calculatedBenefit = reprice + (reprice * 0.10);

        // Only update if the benefit has changed
        if (calculatedBenefit.toFixed(2) !== formData.benefit) {
            setFormData(prevState => ({...prevState, benefit: calculatedBenefit.toFixed(2)}));
        }
        setMessage({successMessage: "Please send us a screenshot", errorMessage: ''});
    }, [memoizedFormData, formData.benefit]); // Added formData.benefit

    useEffect(() => {
        const {price, quantity} = formData;

        if (price !== "" && quantity !== "") {
            calculateTotalAndStatus();
        } else {
            // Only reset benefit and message if they are currently set
            if (formData.benefit !== "") {
                setFormData(prevState => ({...prevState, benefit: ""}));
            }
            if (message.errorMessage !== "" || message.successMessage !== "") {
                setMessage({errorMessage: "", successMessage: ""});
            }
        }
    }, [formData.price, formData.quantity, calculateTotalAndStatus, formData, message]); // Included formData and message

    useEffect(() => {
        // Reset form data when currency changes
        setFormData(prevState => ({...prevState, price: "", quantity: "", benefit: ""}));
        setMessage({errorMessage: "", successMessage: ""});
    }, [formData.currency]);

    return (
        <div>
            <div className="my-6 py-16">
                <div
                    className="grid sm:grid-cols-2 gap-16 p-8 mx-auto max-w-4xl bg-white shadow-[0_2px_10px_-3px_rgba(6,81,237,0.3)] rounded-md text-[#333] font-[sans-serif]">
                    <div className="text-left">
                        {message.errorMessage && (
                            <div className="message error">
                                {message.errorMessage}
                            </div>
                        )}
                        {message.successMessage && (
                            <div className="message success">
                                {message.successMessage}
                            </div>
                        )}
                        <h1 className="text-3xl font-extrabold">MOQ</h1>
                        <p className="text-sm text-gray-400 mt-3">
                            Le MOQ (Minimum Order Quantity) est la quantité minimale de produits que vous pouvez
                            commander. Il permet de réduire les coûts de production et de logistique, assurant ainsi des
                            prix plus compétitifs et une meilleure qualité. Respecter le MOQ vous garantit des produits
                            de haute qualité et une gestion des commandes plus efficace.
                        </p>
                    </div>
                    <div className="ml-auto space-y-4">
                        <select name="currency"
                                value={formData.currency}
                                onChange={handleChange}
                                className="w-full rounded-md py-2.5 px-4 border bg-transparent text-sm outline-red-700 ">
                            <option value="EUR">EUR</option>
                            <option value="USD">USD</option>
                            <option value="RMB">RMB</option>
                            <option value="FCFA">FCFA</option>
                        </select>
                        <input type="number"
                               required={true}
                               name="quantity"
                               value={formData.quantity}
                               onChange={handleChange}
                               placeholder="Entrez la quantitee"
                               className="w-full rounded-md py-2.5 px-4 border text-sm outline-red-700"/>
                        <input name="price"
                               value={formData.price}
                               required={true}
                               type="number"
                               onChange={handleChange}
                               placeholder="Entrez votre montant"
                               className="w-full rounded-md py-2.5 px-4 border text-sm outline-red-700"/>
                        {formData.price && formData.quantity && (
                            <div className="w-full rounded-md py-2.5 px-4 border text-sm outline-red-700">
                                <p className="flex items-baseline gap-x-2">
                                    <span className="font-bold tracking-tight text-gray-900">{formData.benefit}</span>
                                    <span
                                        className="text-sm font-semibold leading-6 tracking-wide text-gray-600">{formData.currency}</span>
                                </p>
                            </div>
                        )}
                        <div className="w-full rounded-md px-4 border text-sm pt-2.5 outline-red-700">
                            <div className="md:flex-1 mt-2 mb:mt-0 md:px-3">
                                <p className="w-full shadow-inner p-4 border-0">
                                    Pour une quantité de {formData.quantity} pièces, le montant à payer s'élève
                                    à {formData.benefit} {formData.currency}. Ce montant inclut l'ensemble des frais
                                    associés.
                                    Pour de plus amples informations, veuillez cliquer {' '}
                                    <Link to={"/contact"} className="text-red-400 font-bold">ici</Link>.
                                </p>
                            </div>
                        </div>
                        <div>
                            <Link to="/orders">
                                <button
                                    className="text-white bg-red-700 hover:bg-red-600 font-semibold rounded-md text-sm px-4 py-2.5 w-full">Commander
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Product;
