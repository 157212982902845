const tissage = [
    {
        id: 1,
        title: "Tissage",
        type: "Straight",
        picture: "../Wigs/img1.png",
        style: [
            {
                id: 1,
                grade: "7A Non-Remy Hair",
                lengths: [
                    {length: 8, price: 45},
                    {length: 10, price: 54},
                    {length: 12, price: 70},
                    {length: 14, price: 80},
                    {length: 16, price: 95},
                    {length: 18, price: 105},
                    {length: 20, price: 125},
                    {length: 22, price: 158},
                    {length: 24, price: 185},
                    {length: 26, price: 208},
                    {length: 28, price: 235},
                ]
            },
            {
                id: 2,
                grade: "8A Remy Hair",
                lengths: [
                    {length: 8, price: 55},
                    {length: 10, price: 70},
                    {length: 12, price: 85},
                    {length: 14, price: 108},
                    {length: 16, price: 125},
                    {length: 18, price: 155},
                    {length: 20, price: 175},
                    {length: 22, price: 215},
                    {length: 24, price: 255},
                    {length: 26, price: 285},
                    {length: 28, price: 305},
                ]
            },
            {
                id: 3,
                grade: "10A Remy Hair",
                lengths: [
                    {length: 8, price: 83},
                    {length: 10, price: 109},
                    {length: 12, price: 128},
                    {length: 14, price: 151},
                    {length: 16, price: 172},
                    {length: 18, price: 204},
                    {length: 20, price: 235},
                    {length: 22, price: 270},
                    {length: 24, price: 319},
                    {length: 26, price: 365},
                    {length: 28, price: 423},
                ]
            }
        ]
    },
    {
        id: 2,
        title: "Tissage",
        type: "Body Wave",
        picture: "../Wigs/img2.png",
        style: [
            {
                id: 1,
                grade: "7A Non-Remy Hair",
                lengths: [
                    {length: 8, price: 45},
                    {length: 10, price: 54},
                    {length: 12, price: 70},
                    {length: 14, price: 80},
                    {length: 16, price: 95},
                    {length: 18, price: 105},
                    {length: 20, price: 125},
                    {length: 22, price: 158},
                    {length: 24, price: 185},
                    {length: 26, price: 208},
                    {length: 28, price: 235},
                ]
            },
            {
                id: 2,
                grade: "8A Remy Hair",
                lengths: [
                    {length: 8, price: 55},
                    {length: 10, price: 70},
                    {length: 12, price: 85},
                    {length: 14, price: 108},
                    {length: 16, price: 125},
                    {length: 18, price: 155},
                    {length: 20, price: 175},
                    {length: 22, price: 215},
                    {length: 24, price: 255},
                    {length: 26, price: 285},
                    {length: 28, price: 305},
                ]
            },
            {
                id: 3,
                grade: "10A Remy Hair",
                lengths: [
                    {length: 8, price: 83},
                    {length: 10, price: 109},
                    {length: 12, price: 128},
                    {length: 14, price: 151},
                    {length: 16, price: 172},
                    {length: 18, price: 204},
                    {length: 20, price: 235},
                    {length: 22, price: 270},
                    {length: 24, price: 319},
                    {length: 26, price: 365},
                    {length: 28, price: 423},
                ]
            }
        ]
    },
    {
        id: 3,
        title: "Tissage",
        type: "deep wave",
        picture: "../Wigs/img3.png",
        style: [
            {
                id: 1,
                grade: "7A Non-Remy Hair",
                lengths: [
                    {length: 8, price: 50},
                    {length: 10, price: 59},
                    {length: 12, price: 75},
                    {length: 14, price: 85},
                    {length: 16, price: 100},
                    {length: 18, price: 110},
                    {length: 20, price: 130},
                    {length: 22, price: 163},
                    {length: 24, price: 190},
                    {length: 26, price: 213},
                    {length: 28, price: 240},
                ]
            },
            {
                id: 2,
                grade: "8A Remy Hair",
                lengths: [
                    {length: 8, price: 60},
                    {length: 10, price: 75},
                    {length: 12, price: 90},
                    {length: 14, price: 113},
                    {length: 16, price: 130},
                    {length: 18, price: 160},
                    {length: 20, price: 180},
                    {length: 22, price: 220},
                    {length: 24, price: 260},
                    {length: 26, price: 290},
                    {length: 28, price: 310},
                ]
            },
            {
                id: 3,
                grade: "10A Remy Hair",
                lengths: [
                    {length: 8, price: 93},
                    {length: 10, price: 119},
                    {length: 12, price: 138},
                    {length: 14, price: 161},
                    {length: 16, price: 182},
                    {length: 18, price: 214},
                    {length: 20, price: 245},
                    {length: 22, price: 280},
                    {length: 24, price: 329},
                    {length: 26, price: 375},
                    {length: 28, price: 433},
                ]
            }
        ]
    },
    {
        id: 4,
        title: "Tissage",
        type: "Water wave",
        picture: "../Wigs/img4.png",
        style: [
            {
                id: 1,
                grade: "7A Non-Remy Hair",
                lengths: [
                    {length: 8, price: 50},
                    {length: 10, price: 59},
                    {length: 12, price: 75},
                    {length: 14, price: 85},
                    {length: 16, price: 100},
                    {length: 18, price: 110},
                    {length: 20, price: 130},
                    {length: 22, price: 163},
                    {length: 24, price: 190},
                    {length: 26, price: 213},
                    {length: 28, price: 240},
                ]
            },
            {
                id: 2,
                grade: "8A Remy Hair",
                lengths: [
                    {length: 8, price: 60},
                    {length: 10, price: 75},
                    {length: 12, price: 90},
                    {length: 14, price: 113},
                    {length: 16, price: 130},
                    {length: 18, price: 160},
                    {length: 20, price: 180},
                    {length: 22, price: 220},
                    {length: 24, price: 260},
                    {length: 26, price: 290},
                    {length: 28, price: 310},
                ]
            },
            {
                id: 3,
                grade: "10A Remy Hair",
                lengths: [
                    {length: 8, price: 93},
                    {length: 10, price: 119},
                    {length: 12, price: 138},
                    {length: 14, price: 161},
                    {length: 16, price: 182},
                    {length: 18, price: 214},
                    {length: 20, price: 245},
                    {length: 22, price: 280},
                    {length: 24, price: 320},
                    {length: 26, price: 375},
                    {length: 28, price: 433},
                ]
            }
        ]
    },
    {
        id: 5,
        title: "Tissage",
        type: "Loose Deep",
        picture: "../Wigs/img5.png",
        style: [
            {
                id: 1,
                grade: "7A Non-Remy Hair",
                lengths: [
                    {length: 8, price: 50},
                    {length: 10, price: 59},
                    {length: 12, price: 75},
                    {length: 14, price: 85},
                    {length: 16, price: 100},
                    {length: 18, price: 110},
                    {length: 20, price: 130},
                    {length: 22, price: 163},
                    {length: 24, price: 190},
                    {length: 26, price: 213},
                    {length: 28, price: 240},
                ]
            },
            {
                id: 2,
                grade: "8A Remy Hair",
                lengths: [
                    {length: 8, price: 60},
                    {length: 10, price: 75},
                    {length: 12, price: 90},
                    {length: 14, price: 113},
                    {length: 16, price: 130},
                    {length: 18, price: 160},
                    {length: 20, price: 180},
                    {length: 22, price: 220},
                    {length: 24, price: 220},
                    {length: 26, price: 290},
                    {length: 28, price: 310},
                ]
            },
            {
                id: 3,
                grade: "10A Remy Hair",
                lengths: [
                    {length: 8, price: 93},
                    {length: 10, price: 119},
                    {length: 12, price: 138},
                    {length: 14, price: 161},
                    {length: 16, price: 182},
                    {length: 18, price: 214},
                    {length: 20, price: 245},
                    {length: 22, price: 280},
                    {length: 24, price: 380},
                    {length: 26, price: 375},
                    {length: 28, price: 433},
                ]
            }
        ]
    },
    {
        id: 6,
        title: "Tissage",
        type: "YaKi Straight",
        picture: "../Wigs/img6.png",
        style: [
            {
                id: 1,
                grade: "7A Non-Remy Hair",
                lengths: [
                    {length: 8, price: 55},
                    {length: 10, price: 64},
                    {length: 12, price: 80},
                    {length: 14, price: 90},
                    {length: 16, price: 105},
                    {length: 18, price: 115},
                    {length: 20, price: 135},
                    {length: 22, price: 168},
                    {length: 24, price: 195},
                    {length: 26, price: 218},
                    {length: 28, price: 245},
                ]
            },
            {
                id: 2,
                grade: "9A Hair",
                lengths: [
                    {length: 10, price: 96},
                    {length: 12, price: 115},
                    {length: 14, price: 134},
                    {length: 16, price: 153},
                    {length: 18, price: 170},
                    {length: 20, price: 195},
                    {length: 22, price: 225},
                    {length: 24, price: 265},
                ]
            }

        ]
    },
    {
        id: 7,
        title: "Tissage",
        type: "Loose",
        picture: "../Wigs/img7.png",
        style: [
            {
                id: 1,
                grade: "7A Non-Remy Hair",
                lengths: [
                    {length: 8, price: 50},
                    {length: 10, price: 59},
                    {length: 12, price: 75},
                    {length: 14, price: 85},
                    {length: 16, price: 100},
                    {length: 18, price: 110},
                    {length: 20, price: 130},
                    {length: 22, price: 163},
                    {length: 24, price: 190},
                    {length: 26, price: 213},
                    {length: 28, price: 240},
                ]
            },
            {
                id: 2,
                grade: "8A Remy Hair",
                lengths: [
                    {length: 8, price: 60},
                    {length: 10, price: 75},
                    {length: 12, price: 90},
                    {length: 14, price: 113},
                    {length: 16, price: 130},
                    {length: 18, price: 160},
                    {length: 20, price: 180},
                    {length: 22, price: 220},
                    {length: 24, price: 260},
                    {length: 26, price: 290},
                    {length: 28, price: 310},
                ]
            },
            {
                id: 3,
                grade: "10A Remy Hair",
                lengths: [
                    {length: 8, price: 93},
                    {length: 10, price: 119},
                    {length: 12, price: 138},
                    {length: 14, price: 161},
                    {length: 16, price: 182},
                    {length: 18, price: 214},
                    {length: 20, price: 245},
                    {length: 22, price: 280},
                    {length: 24, price: 329},
                    {length: 26, price: 375},
                    {length: 28, price: 433},
                ]
            }

        ]
    },
    {
        id: 8,
        title: "Tissage",
        type: "Kinky curly",
        picture: "../Wigs/img8.png",
        style: [
            {
                id: 1,
                grade: "7A Non-Remy Hair",
                lengths: [
                    {length: 8, price: 55},
                    {length: 10, price: 64},
                    {length: 12, price: 80},
                    {length: 14, price: 90},
                    {length: 16, price: 105},
                    {length: 18, price: 115},
                    {length: 20, price: 135},
                    {length: 22, price: 168},
                    {length: 24, price: 195},
                    {length: 26, price: 218},
                    {length: 28, price: 245},
                ]
            },
            {
                id: 2,
                grade: "8A Remy Hair",
                lengths: [
                    {length: 8, price: 65},
                    {length: 10, price: 80},
                    {length: 12, price: 95},
                    {length: 14, price: 118},
                    {length: 16, price: 135},
                    {length: 18, price: 165},
                    {length: 20, price: 185},
                    {length: 22, price: 225},
                    {length: 24, price: 265},
                    {length: 26, price: 295},
                    {length: 28, price: 315},
                ]
            },
            {
                id: 3,
                grade: "10A Remy Hair",
                lengths: [
                    {length: 8, price: 98},
                    {length: 10, price: 124},
                    {length: 12, price: 143},
                    {length: 14, price: 166},
                    {length: 16, price: 187},
                    {length: 18, price: 219},
                    {length: 20, price: 250},
                    {length: 22, price: 285},
                    {length: 24, price: 334},
                    {length: 26, price: 380},
                    {length: 28, price: 438},
                ]
            }
        ]
    },
    {
        id: 9,
        title: "Tissage",
        type: "P4/27 Straight",
        picture: "../Wigs/img9.png",
        style: [
            {
                id: 1,
                grade: "8A Remy Hair",
                lengths: [
                    {length: 10, price: 67},
                    {length: 12, price: 77},
                    {length: 14, price: 93},
                    {length: 16, price: 108},
                    {length: 18, price: 130},
                    {length: 20, price: 150},
                    {length: 22, price: 178},
                    {length: 24, price: 208},
                    {length: 26, price: 249},
                    {length: 28, price: 282},
                ]
            }
        ]
    },
    {
        id: 10,
        title: "Tissage",
        type: "P4/27 Body Wave",
        picture: "../Wigs/img10.png",
        style: [
            {
                id: 1,
                grade: "8A Remy Hair",
                lengths: [
                    {length: 10, price: 67},
                    {length: 12, price: 77},
                    {length: 14, price: 93},
                    {length: 16, price: 108},
                    {length: 18, price: 130},
                    {length: 20, price: 150},
                    {length: 22, price: 178},
                    {length: 24, price: 208},
                    {length: 26, price: 249},
                    {length: 28, price: 282},
                ]
            }
        ]
    },
    {
        id: 11,
        title: "Tissage",
        type: "P4/27 Loose Deep",
        picture: "../Wigs/img11.png",
        style: [
            {
                id: 1,
                grade: "8A Remy Hair",
                lengths: [
                    {length: 10, price: 77},
                    {length: 12, price: 87},
                    {length: 14, price: 103},
                    {length: 16, price: 118},
                    {length: 18, price: 140},
                    {length: 20, price: 160},
                    {length: 22, price: 188},
                    {length: 24, price: 218},
                    {length: 26, price: 259},
                    {length: 28, price: 292},
                ]
            }
        ]
    },
    {
        id: 12,
        title: "Tissage",
        type: "P4/27 curly",
        picture: "../Wigs/img12.png",
        style: [
            {
                id: 1,
                grade: "8A Remy Hair",
                lengths: [
                    {length: 10, price: 82},
                    {length: 12, price: 92},
                    {length: 14, price: 108},
                    {length: 16, price: 123},
                    {length: 18, price: 145},
                    {length: 20, price: 165},
                    {length: 22, price: 193},
                    {length: 24, price: 223},
                    {length: 26, price: 264},
                    {length: 28, price: 297},
                ]
            }
        ]
    },
    {
        id: 13,
        title: "Tissage",
        type: "T1B4/30 Straight",
        picture: "../Wigs/img13.png",
        style: [
            {
                id: 1,
                grade: "8A Remy Hair",
                lengths: [
                    {length: 12, price: 80},
                    {length: 14, price: 90},
                    {length: 16, price: 105},
                    {length: 18, price: 120},
                    {length: 20, price: 140},
                    {length: 22, price: 183},
                    {length: 24, price: 210},
                    {length: 26, price: 243},
                    {length: 28, price: 295},
                ]
            }
        ]
    },
    {
        id: 14,
        title: "Tissage",
        type: "T1B4/30 Body Wave",
        picture: "../Wigs/img14.png",
        style: [
            {
                id: 1,
                grade: "8A Remy Hair",
                lengths: [
                    {length: 12, price: 80},
                    {length: 14, price: 90},
                    {length: 16, price: 105},
                    {length: 18, price: 120},
                    {length: 20, price: 140},
                    {length: 22, price: 183},
                    {length: 24, price: 210},
                    {length: 26, price: 243},
                    {length: 28, price: 295},
                ]
            }
        ]
    },
    {
        id: 15,
        title: "Tissage",
        type: "Ombre T4 Spring Curly",
        picture: "../Wigs/img15.png",
        style: [
            {
                id: 1,
                grade: "10A Remy Hair",
                lengths: [
                    {length: 12, price: 125},
                    {length: 14, price: 144},
                    {length: 16, price: 163},
                    {length: 18, price: 180},
                    {length: 20, price: 205},
                    {length: 22, price: 235},
                    {length: 24, price: 275},
                ]
            }
        ]
    },
    {
        id: 16,
        title: "Tissage",
        type: "Ombre 3Tone Loose Wave",
        picture: "../Wigs/img16.png",
        style: [
            {
                id: 1,
                grade: "10A Remy Hair",
                lengths: [
                    {length: 10, price: 106},
                    {length: 12, price: 125},
                    {length: 14, price: 144},
                    {length: 16, price: 163},
                    {length: 18, price: 180},
                    {length: 20, price: 205},
                    {length: 22, price: 235},
                    {length: 24, price: 275},
                ]
            }
        ]
    },
    {
        id: 17,
        title: "Tissage",
        type: "Ombre 3Tone Body Wave",
        picture: "../Wigs/img17.png",
        style: [
            {
                id: 1,
                grade: "10A Remy Hair",
                lengths: [
                    {length: 10, price: 101},
                    {length: 12, price: 120},
                    {length: 14, price: 139},
                    {length: 16, price: 158},
                    {length: 18, price: 175},
                    {length: 20, price: 200},
                    {length: 22, price: 230},
                    {length: 24, price: 270},

                ]
            }
        ]
    },
    {
        id: 18,
        title: "Tissage",
        type: "Ombre 3Tone Straight",
        picture: "../Wigs/img18.png",
        style: [
            {
                id: 1,
                grade: "10A Remy Hair",
                lengths: [
                    {length: 10, price: 101},
                    {length: 12, price: 120},
                    {length: 14, price: 139},
                    {length: 16, price: 158},
                    {length: 18, price: 175},
                    {length: 20, price: 200},
                    {length: 22, price: 230},
                    {length: 24, price: 270},

                ]
            }
        ]
    },
    {
        id: 19,
        title: "Tissage",
        type: "Ombre 3Tone Kingky Curly",
        picture: "../Wigs/img19.png",
        style: [
            {
                id: 1,
                grade: "10A Remy Hair",
                lengths: [
                    {length: 12, price: 125},
                    {length: 14, price: 144},
                    {length: 16, price: 163},
                    {length: 18, price: 180},
                    {length: 20, price: 205},
                    {length: 22, price: 235},
                    {length: 24, price: 275},

                ]
            }
        ]
    },
    {
        id: 20,
        title: "Tissage",
        type: "#613 Straight & Body Wave",
        picture: "../Wigs/img20.png",
        style: [
            {
                id: 1,
                grade: "10A Remy Hair",
                lengths: [
                    {length: 10, price: 100},
                    {length: 12, price: 120},
                    {length: 14, price: 130},
                    {length: 16, price: 160},
                    {length: 18, price: 185},
                    {length: 20, price: 225},
                    {length: 22, price: 255},
                    {length: 24, price: 300},
                    {length: 26, price: 335},
                    {length: 28, price: 390},
                    {length: 30, price: 420},
                ]
            }
        ]
    },

];
export default tissage;
