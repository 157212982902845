import React, {useState} from 'react';
import emailjs from 'emailjs-com';
import {Link, useNavigate} from "react-router-dom"; // Import useNavigate

const Question = () => {
    const [formData, setFormData] = useState({
        rating: '',
        servicesUsed: '',
        servicesExpectations: '',
        improvements: '',
        recommendation: '',
        additionalComments: '',
        name: '',
        email: '',
        phoneNumber: '',
        message: ''
    });
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate(); // Create an instance of useNavigate

    const handleSubmit = (e) => {
        e.preventDefault();
        JSON.stringify(formData, null, 2);
        emailjs.sendForm('service_1h5q8el', 'template_jqac0n8', e.target, 'r0A9ejRzmV8WW5GUJ')
            .then((result) => {
                console.log('Email sent successfully!', result.text);
                setSuccessMessage('Votre message a été envoyé avec succès.');
                setErrorMessage('');
                // Redirect to the main page after 5 seconds
                setTimeout(() => {
                    navigate('/home');
                }, 5000);
            }, (error) => {
                console.error('Error sending email:', error.text);
                setErrorMessage('Une erreur s\'est produite lors de l\'envoi du message. Veuillez réessayer plus tard.');
                setSuccessMessage('');
            });
    };

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    return (
        <div>
            <div
                className="bg-white text-slate-900 flex min-h-screen flex-col items-center pt-16 sm:justify-center sm:pt-0 py-20">
                <div
                    className="text-foreground font-semibold text-2xl text-center tracking-tighter mx-auto flex items-center gap-2">
                    Questionnaire sur <br/>Notre Service Client.
                </div>
                <div className="relative mt-12 w-full max-w-lg sm:mt-10">
                    <div
                        className="relative -mb-px h-px w-full bg-gradient-to-r from-transparent via-sky-300 to-transparent"
                        bis_skin_checked="1"></div>
                    <div
                        className="mx-5 border border-b-black/20 sm:border-t-white/20 shadow-[20px_0_20px_20px] shadow-slate-500/10 rounded-lg border-white/20 border-l-white/20 border-r-white/20 sm:shadow-sm lg:rounded-xl lg:shadow-none">
                        <div className="flex flex-col p-6">
                            {successMessage &&
                                <div
                                    className="mb-3 bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative"
                                    role="alert">
                                    <strong className="font-bold">Succès !</strong>
                                    <span className="block sm:inline">{successMessage}</span>
                                </div>
                            }
                            {errorMessage &&
                                <div
                                    className="mb-3 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                                    role="alert">
                                    <strong className="font-bold">Erreur !</strong>
                                    <span className="block sm:inline">{errorMessage}</span>
                                </div>
                            }
                            <h3 className="text-xl font-semibold leading-6 tracking-tighter">Svp & Merci</h3>
                            <p className="mt-1.5 text-sm font-medium text-slate-500">
                                Ces questions ci-dessous nous aident à mieux comprendre nos clients.
                            </p>
                        </div>
                        <div className="p-6 pt-0">
                            <form onSubmit={handleSubmit}>
                                <div>
                                    <div>
                                        <div
                                            className="group relative rounded-lg border focus-within:border-sky-200 px-3 pb-1.5 pt-2.5 duration-200 focus-within:ring focus-within:ring-sky-300/30">
                                            <div className="flex justify-between">
                                                <label
                                                    className="text-xs font-medium text-muted-foreground group-focus-within:text-slate-500 text-gray-400">1
                                                    - Évaluez la qualité de nos services de 1 à 5?</label>
                                            </div>
                                            <input type="number"
                                                   name="rating"
                                                   value={formData.rating}
                                                   onChange={handleChange}
                                                   required
                                                   placeholder="1 = très insatisfait, 5 = très satisfait"
                                                   className="block w-full border-0 bg-transparent p-0 text-sm file:my-1 file:rounded-full file:border-0 file:bg-accent file:px-4 file:py-2 file:font-medium placeholder:text-muted-foreground/90 focus:outline-none focus:ring-0 sm:leading-7 text-foreground"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-4">
                                    <div>
                                        <div
                                            className="group relative rounded-lg border focus-within:border-sky-200 px-3 pb-1.5 pt-2.5 duration-200 focus-within:ring focus-within:ring-sky-300/30">
                                            <div className="flex justify-between">
                                                <label
                                                    className="text-xs font-medium text-muted-foreground group-focus-within:text-slate-500 text-gray-400">2
                                                    - Quels services avez-vous récemment utilisés chez nous ?</label>
                                            </div>
                                            <select name="servicesUsed"
                                                    value={formData.servicesUsed}
                                                    onChange={handleChange}
                                                    required
                                                    className=" mt-2 block w-full border-0 bg-transparent p-0 text-sm file:my-1 file:rounded-full file:border-0 file:bg-accent file:px-4 file:py-2 file:font-medium placeholder:text-muted-foreground/90 focus:outline-none focus:ring-0 sm:leading-7 text-foreground">
                                                <option value="">Le service SVP</option>
                                                <option value="Approvisionnement">APPROVISIONNEMENT</option>
                                                <option value="Calculatrice">CALCULATRICE</option>
                                                <option value="Creation">CRÉATIVITÉ</option>
                                                <option value="Guide">ASSISTANCE</option>
                                                <option value="Autre">AUTRES</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-4">
                                    <div>
                                        <div
                                            className="group relative rounded-lg border focus-within:border-sky-200 px-3 pb-1.5 pt-2.5 duration-200 focus-within:ring focus-within:ring-sky-300/30">
                                            <div className="flex justify-between">
                                                <label
                                                    className="text-xs font-medium text-muted-foreground group-focus-within:text-slate-500 text-gray-400">3
                                                    - Quels aspects de nos services ont répondu à vos attentes ?</label>
                                            </div>
                                            <input type="text"
                                                   name="servicesExpectations"
                                                   value={formData.servicesExpectations}
                                                   onChange={handleChange}
                                                   required
                                                   placeholder="Votre réponse"
                                                   className="block w-full border-0 bg-transparent p-0 text-sm file:my-1 file:rounded-full file:border-0 file:bg-accent file:px-4 file:py-2 file:font-medium placeholder:text-muted-foreground/90 focus:outline-none focus:ring-0 sm:leading-7 text-foreground"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-4">
                                    <div>
                                        <div
                                            className="group relative rounded-lg border focus-within:border-sky-200 px-3 pb-1.5 pt-2.5 duration-200 focus-within:ring focus-within:ring-sky-300/30">
                                            <div className="flex justify-between">
                                                <label
                                                    className="text-xs font-medium text-muted-foreground group-focus-within:text-slate-500 text-gray-400">4
                                                    - Comment pourrions-nous améliorer nos services ?</label>
                                            </div>
                                            <input type="text"
                                                   name="improvements"
                                                   value={formData.improvements}
                                                   onChange={handleChange}
                                                   required
                                                   placeholder="Votre réponse"
                                                   className="block w-full border-0 bg-transparent p-0 text-sm file:my-1 file:rounded-full file:border-0 file:bg-accent file:px-4 file:py-2 file:font-medium placeholder:text-muted-foreground/90 focus:outline-none focus:ring-0 sm:leading-7 text-foreground"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-4">
                                    <div>
                                        <div
                                            className="group relative rounded-lg border focus-within:border-sky-200 px-3 pb-1.5 pt-2.5 duration-200 focus-within:ring focus-within:ring-sky-300/30">
                                            <div className="flex justify-between">
                                                <label
                                                    className="text-xs font-medium text-muted-foreground group-focus-within:text-slate-500 text-gray-400">5
                                                    - Recommanderiez-vous nos services à d'autres personnes ?</label>
                                            </div>
                                            <input type="number"
                                                   name="recommendation"
                                                   value={formData.recommendation}
                                                   onChange={handleChange}
                                                   required
                                                   placeholder="1 = très insatisfait, 5 = très satisfait"
                                                   className="block w-full border-0 bg-transparent p-0 text-sm file:my-1 file:rounded-full file:border-0 file:bg-accent file:px-4 file:py-2 file:font-medium placeholder:text-muted-foreground/90 focus:outline-none focus:ring-0 sm:leading-7 text-foreground"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-4">
                                    <div>
                                        <div
                                            className="group relative rounded-lg border focus-within:border-sky-200 px-3 pb-1.5 pt-2.5 duration-200 focus-within:ring focus-within:ring-sky-300/30">
                                            <div className="flex justify-between">
                                                <label
                                                    className="text-xs font-medium text-muted-foreground group-focus-within:text-slate-500 text-gray-400">6
                                                    - Avez-vous des suggestions ou commentaires supplémentaires
                                                    ?</label>
                                            </div>
                                            <input type="text"
                                                   name="additionalComments"
                                                   value={formData.additionalComments}
                                                   onChange={handleChange}
                                                   required
                                                   placeholder="Votre réponse"
                                                   className="block w-full border-0 bg-transparent p-0 text-sm file:my-1 file:rounded-full file:border-0 file:bg-accent file:px-4 file:py-2 file:font-medium placeholder:text-muted-foreground/90 focus:outline-none focus:ring-0 sm:leading-7 text-foreground"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-4">
                                    <div>
                                        <div
                                            className="group relative rounded-lg border focus-within:border-sky-200 px-3 pb-1.5 pt-2.5 duration-200 focus-within:ring focus-within:ring-sky-300/30">
                                            <div className="flex justify-between">
                                                <label
                                                    className="text-xs font-medium text-muted-foreground group-focus-within:text-slate-500 text-gray-400">
                                                    Laissez-nous vos coordonnées si vous souhaitez être
                                                    contacté.</label>
                                            </div>
                                            <input type="text"
                                                   name="name"
                                                   value={formData.name}
                                                   onChange={handleChange}
                                                   required
                                                   placeholder="Nom"
                                                   className="block w-full border-0 bg-transparent p-0 text-sm file:my-1 file:rounded-full file:border-0 file:bg-accent file:px-4 file:py-2 file:font-medium placeholder:text-muted-foreground/90 focus:outline-none focus:ring-0 sm:leading-7 text-foreground"/>
                                            <input type="email"
                                                   name="email"
                                                   value={formData.email}
                                                   onChange={handleChange}
                                                   required
                                                   placeholder="Address e-mail"
                                                   className="block w-full border-0 bg-transparent p-0 text-sm file:my-1 file:rounded-full file:border-0 file:bg-accent file:px-4 file:py-2 file:font-medium placeholder:text-muted-foreground/90 focus:outline-none focus:ring-0 sm:leading-7 text-foreground"/>
                                            <input type="tel"
                                                   name="phoneNumber"
                                                   placeholder="+33 000 000 000"
                                                   value={formData.phoneNumber}
                                                   onChange={handleChange}
                                                   required
                                                   className="block w-full border-0 bg-transparent p-0 text-sm file:my-1 file:rounded-full file:border-0 file:bg-accent file:px-4 file:py-2 file:font-medium placeholder:text-muted-foreground/90 focus:outline-none focus:ring-0 sm:leading-7 text-foreground"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-4">
                                    <div>
                                        <div
                                            className="group relative rounded-lg border focus-within:border-sky-200 px-3 pb-1.5 pt-2.5 duration-200 focus-within:ring focus-within:ring-sky-300/30">
                                            <div className="flex justify-between">
                                                <label
                                                    className="text-xs font-medium text-muted-foreground group-focus-within:text-slate-500 text-gray-400">Votre
                                                    message à notre équipe</label>
                                            </div>
                                            <div className="flex items-center">
                                                <textarea name="message"
                                                          value={formData.message}
                                                          onChange={handleChange}
                                                          className="block w-full border-0 bg-transparent p-0 text-sm file:my-1 placeholder:text-muted-foreground/90 focus:outline-none focus:ring-0 focus:ring-teal-500 sm:leading-7 text-foreground"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-4 flex items-center justify-end gap-x-2">
                                    <button
                                        className="font-semibold hover:bg-black hover:text-white hover:ring hover:ring-black transition duration-300 inline-flex items-center justify-center rounded-md text-sm focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-white text-black h-10 px-4 py-2"
                                        type="submit">
                                        Envoyer
                                    </button>
                                </div>
                            </form>
                            <p className="mt-4  text-gray-500 text-sm text-left">
                                Merci beaucoup d'avoir pris le temps de remplir ce
                                questionnaire. Vos commentaires sont précieux pour nous aider à améliorer nos services
                                et à mieux répondre à vos besoins.
                            </p>
                            <div className="mt-4 text-gray-500 text-sm justify-center text-center">
                                Powered by <Link to="/contact" className="underline" target="_blank"
                                                 rel="noopener noreferrer">MedinDev</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Question;
