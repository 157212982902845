import React, {useState} from 'react';
import wigsliste from '../../Data/wigsliste';

const SearchWigs = () => {
    const [maxPrice, setMaxPrice] = useState('');
    const [showResults, setShowResults] = useState(false);

    const handleMaxPriceChange = (e) => {
        setMaxPrice(e.target.value);
        if (e.target.value.trim() === '') {
            setShowResults(false);
        }
    };

    const handleSearchSubmit = (e) => {
        e.preventDefault();
        setShowResults(true);
    };

    const multiplier = 115;

    const filteredWigs = wigsliste.flatMap(wigs =>
        wigs.styles.flatMap(style =>
            style.lengths.filter(length => length.price * multiplier <= parseInt(maxPrice, 10))
                .map(length => ({
                    ...style,
                    wigType: wigs.type,
                    density: wigs.density,
                    grade: wigs.grade,
                    length: length.length,
                    finalPrice: length.price * multiplier
                }))
        )
    );

    // Sort filteredWigs by finalPrice in ascending order
    const sortedFilteredWigs = filteredWigs.sort((a, b) => a.finalPrice - b.finalPrice);

    return (
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="max-w-3xl text-center mx-auto">
                <h2 className="mb-12 text-gray-800 text-xl font-bold leading-none text-center sm:text-3xl">
                    La perruque parfaite avec votre budget.
                </h2>
            </div>
            <form onSubmit={handleSearchSubmit} className="mt-8 sm:flex py-20">
                <div className="relative w-full sm:flex-1 mt-3 sm:mt-0 sm:ml-3">
                    <input
                        id="max-price"
                        type="number"
                        className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:border-red-300 focus:ring focus:ring-red-200 focus:ring-opacity-50 sm:text-sm"
                        placeholder="Entrez votre budget et découvrez vos options d'achat."
                        value={maxPrice}
                        onChange={handleMaxPriceChange}
                    />
                </div>
                <button
                    type="submit"
                    className="mt-3 w-full sm:mt-0 sm:ml-3 bg-red-600 border border-transparent rounded-md py-2 px-4 flex items-center justify-center text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:w-auto sm:flex-shrink-0 sm:inline-flex sm:items-center sm:justify-center"
                >
                    Recherche
                </button>
            </form>
            {showResults && (
                <div className="mt-5 max-h-96 overflow-y-auto">
                    {sortedFilteredWigs.length > 0 ? (
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                            <tr>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Type</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Density</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Grade</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Length</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Price
                                    (FCFA)
                                </th>
                            </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                            {sortedFilteredWigs.map((wig, index) => (
                                <tr key={index} className="text-secondary-800 ">
                                    <td className="px-6 py-4 whitespace-nowrap font-bold">{wig.wigType}</td>
                                    <td className="px-6 py-4 whitespace-nowrap">{wig.density}</td>
                                    <td className="px-6 py-4 whitespace-nowrap font-bold">{wig.grade}</td>
                                    <td className="px-6 py-4 whitespace-nowrap">{wig.length}"</td>
                                    <td className="px-6 py-4 whitespace-nowrap font-semibold">{wig.finalPrice} FCFA</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    ) : (
                        <div className="text-gray-500">No results found.</div>
                    )}
                </div>
            )}
        </div>
    );
};

export default SearchWigs;
