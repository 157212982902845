import React, {useState} from "react";
import {Assistance, Country, Delivery, Longpay, Mesh, Product, Simulation, Transfer} from "./index";
import {motion} from "framer-motion";


const Convertor = () => {
    const [activeButton, setActiveButton] = useState("moq");

    const handleClick = (button) => {
        setActiveButton(button);
    };

    const getButtonClass = (button) => {
        return `font-medium text-[16px] items-center px-5 py-3 md:py-4 md:px-8 rounded-xl capitalize bg-[#E60023] hover:bg-[#D5001F] relative gap-2 transition duration-300 ${
            activeButton === button ? 'hover:scale-105' : 'hover:scale-105 scale-90 bg-slate-900'
        } text-white shadow-glass`;
    };

    return (
        <div className="text-center">
            <div className="h-1/5 flex justify-start gap-3.5 p-2 overflow-x-auto whitespace-nowrap">
                {["longpay", "simulation", "assistance", "delivery", "transfer", "moq", "country", "mesh"].map((button) => (
                    <button
                        key={button}
                        className={getButtonClass(button)}
                        onClick={() => handleClick(button)}
                    >
                        {button === "moq" && "MOQ"}
                        {button === "delivery" && "Livraison"}
                        {button === "simulation" && "Facture"}
                        {button === "longpay" && "Coffre"}
                        {button === "assistance" && "Guide"}
                        {button === "transfer" && "Money"}
                        {button === "country" && "Pays"}
                        {button === "mesh" && "Perruques"}
                    </button>
                ))}
            </div>
            <motion.div
                key={activeButton}
                initial={{opacity: 0, y: 10}}
                animate={{opacity: 1, y: 0}}
                exit={{opacity: 0, y: -10}}
                transition={{duration: 0.3}}
            >
                {activeButton === "moq" && <Product/>}
                {activeButton === "delivery" && <Delivery/>}
                {activeButton === "simulation" && <Simulation/>}
                {activeButton === "longpay" && <Longpay/>}
                {activeButton === "assistance" && <Assistance/>}
                {activeButton === "transfer" && <Transfer/>}
                {activeButton === "country" && <Country/>}
                {activeButton === "mesh" && <Mesh/>}
            </motion.div>
        </div>
    );
};

export default Convertor;
