const pay = [
    {
        id: 1,
        method: "Western Union",
        Pays: "Chine",
        Devise01: "Dollar",
        Devise02: "Euro",
        Description: "Veuillez noter que en sélectionnant Western Union , vous pourriez avoir besoin de revenir vers nous pour plus d'informations.",
        Phone: "+8613860555031",
        Logo: "../Pay/lo5.png", // Assuming you have a relevant flag image; adjust the path as necessary
    },
    {
        id: 2,
        method: "MoneyGram",
        Pays: "Chine",
        Devise01: "Dollar",
        Devise02: "Euro",
        Description: "Veuillez noter que en sélectionnant MoneyGram , vous pourriez avoir besoin de revenir vers nous pour plus d'informations.",
        Phone: "+8613860555031",
        Logo: "../Pay/lo6.png",
    },
    {
        id: 3,
        method: "Airtel Money",
        Pays: "Gabon",
        Devise01: "FCFA", // Assuming the currency; adjust as necessary
        Devise02: "FCFA", // Assuming there's no second currency option
        Description: "Ce service est exclusivement disponible au Gabon En choisissant Airtel Money, vous pourriez avoir besoin de nous contacter pour plus d'informations.",
        Phone: "074 755 759",
        Logo: "../Pay/lo3.png",
    },
    {
        id: 4,
        method: "Moov Money",
        Pays: "Gabon",
        Devise01: "FCFA", // Assuming the currency; adjust as necessary
        Devise02: "FCFA", // Assuming there's no second currency option
        Description: "Ce service est exclusivement disponible au Gabon En choisissant Moov Money, vous pourriez avoir besoin de nous contacter pour plus d'informations.",
        Phone: "065 697 318",
        Logo: "../Pay/lo10.png",
    },
    {
        id: 5,
        method: "Orange Money",
        Pays: "Multiple",
        Devise01: "Variable", // Assuming the currency varies; adjust as necessary
        Devise02: null, // Assuming there's no second currency option
        Description: "Veuillez noter que Orange Money est disponible dans plusieurs pays, et nos agences partenaires peuvent avoir besoin d'informations différentes en fonction du pays. En sélectionnant ce service ,n'hésitez pas à revenir vers nous pour obtenir plus de détails.",
        Phone: "+8613860555031", // Assuming phone number is not applicable here; adjust as necessary
        Logo: "../Pay/lo8.png", // Assuming a generic logo; adjust the path as necessary
    },
    {
        id: 6,
        method: "Visa et Banque",
        Pays: "International",
        Devise01: "Dollar",
        Devise02: "Euro",
        Description: "Veuillez noter que en sélectionnant Visa et Banque, vous pourriez avoir besoin de revenir vers nous pour plus d'informations.",
        Phone: "+8613860555031", // Assuming phone number is not applicable here; adjust as necessary
        Logo: "../Pay/lo4.png", // Assuming a generic logo; adjust the path as necessary
    },
    {
        id: 7,
        method: "Paypal",
        Pays: "International",
        Devise01: "Dollar US",
        Devise02: "Euro",
        Description: "Veuillez noter que en sélectionnant Paypal, vous pourriez avoir besoin de revenir vers nous pour plus d'informations.",
        Phone: "N/A", // Assuming email is the primary contact method; adjust as necessary
        Logo: "../Pay/lo7.png",
    },
    {
        id: 8,
        method: "Express 24h/7j",
        Pays: "Variable",
        Devise01: "Express Pay",
        Devise02: "Urus Pay",
        Description: "En choisissant un agent avec lequel vous avez l'habitude de traiter, vous en êtes entièrement responsable à 100%. Faites donc les bons choix pour vos transferts d'argent Merci.",
        Phone: "+8613860555031", // Assuming phone number is not applicable here; adjust as necessary
        Logo: "../Pay/lo11.png", // Assuming a generic logo; adjust the path as necessary
    },
    {
        id: 9,
        method: "Autres",
        Pays: "Variable",
        Devise01: "Dollar US",
        Devise02: "Euro",
        Description: "Si le moyen de paiement que vous avez choisi ne figure pas dans la liste, veuillez nous envoyer un e-mail dans lequel vous expliquez clairement pourquoi vous souhaitez utiliser ce service de paiement. Nous serons alors en mesure de vous fournir plus de détails et de répondre à toutes vos questions à ce sujet.",
        Phone: "+8613860555031", // Assuming email is the primary contact method; adjust as necessary
        Logo: "../Pay/lo1.png", // Assuming a generic logo; adjust the path as necessary
    }
];

export default pay;
