import {useState, useEffect} from 'react';

const ActionGame = () => {
    // Consolidating state into a single object
    const [metrics, setMetrics] = useState({
        abonnes: 4550,
        commandesEnCours: 953,
        livraisonsEnCours: 552,
        retours: 218,
    });

    // Function to update values every 10 seconds
    useEffect(() => {
        const interval = setInterval(() => {
            setMetrics(prevMetrics => ({
                abonnes: prevMetrics.abonnes + Math.floor(Math.random() * 4),
                commandesEnCours: prevMetrics.commandesEnCours + Math.floor(Math.random() * 3),
                livraisonsEnCours: prevMetrics.livraisonsEnCours + Math.floor(Math.random() * 2),
                retours: prevMetrics.retours + Math.floor(Math.random() * 2),
            }));
        }, 10000); // 10 seconds

        return () => clearInterval(interval); // Cleanup function to avoid memory leaks
    }, []);

    // Reusable component for displaying metric
    const MetricDisplay = ({label, value}) => (
        <div>
            <h5 className="font-bold text-white">
                <span className="text-secondary-600">+ </span>
                <span className="inline text-primary-700">{value}</span>
            </h5>
            <p className="text-xs tracking-wide text-gray-950 ">{label}</p>
        </div>
    );

    return (
        <section className="">
            <div className="container grid grid-cols-4 gap-3 py-4 mx-auto text-center">
                <MetricDisplay label="Fournisseurs" value={metrics.abonnes}/>
                <MetricDisplay label="Achats" value={metrics.commandesEnCours}/>
                <MetricDisplay label="Clients" value={metrics.livraisonsEnCours}/>
                <MetricDisplay label="Avis" value={metrics.retours}/>
            </div>
        </section>
    );
};

export default ActionGame;
