import React from 'react';
import Navbar from "../components/mycontact/Navbar";
import Hero from "../components/mycontact/Hero";
import Plan from "../components/mycontact/Plan";
import Footer from "../layout/footer/Footer";
import {BodyConta, Faq, Social} from "../components";
import Clients from "../components/about/Clients";


const Contact = () => {
    return (
        <>
            <Navbar/>
            <Hero/>
            <BodyConta/>
            <Plan/>
            <Social/>
            <Clients/>
            <Faq/>
            <Footer/>
        </>
    );
};

export default Contact;