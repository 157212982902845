import React, {useEffect, useState} from 'react';
import {db} from '../../firebase';
import {collection, getDocs, deleteDoc, doc} from 'firebase/firestore';

const ItemList = () => {
    const [items, setItems] = useState([]);

    useEffect(() => {
        const fetchItems = async () => {
            const querySnapshot = await getDocs(collection(db, 'items'));
            const itemsList = querySnapshot.docs.map(doc => ({id: doc.id, ...doc.data()}));
            setItems(itemsList);
        };

        fetchItems();
    }, []);

    const deleteItem = async (id) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this item?");
        if (confirmDelete) {
            try {
                await deleteDoc(doc(db, 'items', id));
                setItems(items.filter(item => item.id !== id));
            } catch (error) {
                console.error("Error deleting item: ", error);
            }
        }
    };

    return (
        <div className="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 pr-10 lg:px-8">
            <section className="container mx-auto p-6 font-mono">
                <div className="w-full mb-8 overflow-hidden rounded-lg shadow-lg">
                    <div className="w-full overflow-x-auto">
                        <table className="w-full">
                            <thead>
                            <tr className="text-md font-semibold tracking-wide text-left text-gray-900 bg-gray-100 uppercase border-b border-gray-600">
                                <th className="px-4 py-3">Nom</th>
                                <th className="px-4 py-3">Infos</th>
                                <th className="px-4 py-3">Pays</th>
                                <th className="px-4 py-3">Activity</th>
                                <th className="px-4 py-3">Address</th>
                                <th className="px-4 py-3">Action</th>
                            </tr>
                            </thead>
                            <tbody className="bg-white">
                            {items.map(item => (
                                <tr key={item.id} className="text-gray-700">
                                    <td className="px-4 py-3 border">
                                        <div className="flex items-center text-sm">
                                            <div>
                                                <p className="font-semibold text-black uppercase ">{item.name}</p>
                                                <p className="text-xs text-gray-600">{item.price}</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="px-4 py-3 border ">
                                        <div className="flex items-center text-sm">
                                            <div>
                                                <p className="font-semibold text-black">{item.phone}</p>
                                                <p className="text-xs text-gray-600">{item.email}</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="px-4 py-3 border ">
                                        <div className="flex items-center text-sm">
                                            <div>
                                                <p className="font-semibold text-black">{item.country}</p>
                                                <p className="text-xs text-gray-600">{item.city}</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="px-4 py-3 text-sm border">{item.activity}</td>
                                    <td className="px-4 py-3 text-sm border">{item.address}</td>
                                    <td className="px-4 py-3 text-sm border">
                                        <button
                                            className="px-5 py-2 border-red-500 border text-red-500 rounded transition duration-300 hover:bg-red-700 hover:text-white focus:outline-none"
                                            onClick={() => deleteItem(item.id)}
                                        >
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default ItemList;
