import React, {useState} from 'react';
import {doc, setDoc} from 'firebase/firestore';
import {db} from "../../firebase";
import {useNavigate} from "react-router-dom";

const AddItem = () => {
    const [formData, setFormData] = useState({
        itemName: '',
        itemPrice: '',
        email: '',
        phone: '',
        country: '',
        city: '',
        activity: '',
        address: ''
    });

    const [successMessage, setSuccessMessage] = useState('');
    const navigate = useNavigate();

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleAddItem = async (e) => {
        e.preventDefault();

        // Check for empty fields
        for (const key in formData) {
            if (!formData[key]) {
                alert(`${key.replace(/([A-Z])/g, ' $1')} is required!`);
                return;
            }
        }

        try {
            // Generate a custom ID
            const customId = `${Date.now()}-${Math.floor(Math.random() * 1000)}`;

            // Use setDoc to create a document with a custom ID
            await setDoc(doc(db, "items", customId), {
                name: formData.itemName,
                price: formData.itemPrice,
                email: formData.email,
                phone: formData.phone,
                country: formData.country,
                city: formData.city,
                activity: formData.activity,
                address: formData.address
            });

            console.log("Document written with custom ID: ", customId);
            setSuccessMessage("Félicitation et Bienvenue!");

            // Clear the input fields after successful submission
            setFormData({
                itemName: '',
                itemPrice: '',
                email: '',
                phone: '',
                country: '',
                city: '',
                activity: '',
                address: ''
            });

            // Redirect to orders after 3 seconds
            setTimeout(() => {
                navigate('/orders');
            }, 3000);
        } catch (e) {
            console.error("Error adding document: ", e);
        }
    };

    return (
        <div className="max-w-2xl mx-auto bg-white p-16 ">
            <div className="p-4 border-b-2">
                <h1 className="text-2xl font-semibold text-gray-900">Rejoignez-nous dès maintenant</h1>
                <p className="mt-2 text-sm text-gray-500">Veuillez remplir le formulaire ci-dessous afin de vous
                    enregistrer.
                </p>
            </div>
            {successMessage && (
                <div className="px-6 py-4 bg-green-50 rounded-lg text-green-500 mb-4">
                    {successMessage}
                </div>
            )}
            <form onSubmit={handleAddItem} className="pt-4">
                <div className="grid gap-6 mb-6 lg:grid-cols-2">
                    <div>
                        <label className="block mb-2 text-sm font-medium text-gray-900">Nom</label>
                        <input type="name"
                               name="itemName"
                               value={formData.itemName}
                               onChange={handleChange}
                               className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                               placeholder="Votre nom"
                               required/>
                    </div>
                    <div>
                        <label className="block mb-2 text-sm font-medium text-gray-900">Prenom</label>
                        <input type="text"
                               name="itemPrice"
                               value={formData.itemPrice}
                               onChange={handleChange}
                               className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                               placeholder="Votre prenom"
                               required/>
                    </div>
                    <div>
                        <label className="block mb-2 text-sm font-medium text-gray-900">Email</label>
                        <input type="email"
                               name="email"
                               value={formData.email}
                               onChange={handleChange}
                               className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                               placeholder="votremail@gmail.com"
                               required/>
                    </div>
                    <div>
                        <label className="block mb-2 text-sm font-medium text-gray-900">Téléphone</label>
                        <input type="tel"
                               name="phone"
                               value={formData.phone}
                               onChange={handleChange}
                               className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                               placeholder="+00-123-45-678"
                               required/>
                    </div>
                    <div>
                        <label className="block mb-2 text-sm font-medium text-gray-900">Pays</label>
                        <input type="text"
                               name="country"
                               value={formData.country}
                               onChange={handleChange}
                               className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                               placeholder="Votre pays"
                               required/>
                    </div>
                    <div>
                        <label className="block mb-2 text-sm font-medium text-gray-900">Ville</label>
                        <input type="text"
                               name="city"
                               value={formData.city}
                               onChange={handleChange}
                               className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                               placeholder="Votre ville"
                               required/>
                    </div>
                </div>
                <div className="mb-6">
                    <label className="block mb-2 text-sm font-medium text-gray-900">Adresse</label>
                    <input type="text"
                           name="address"
                           value={formData.address}
                           onChange={handleChange}
                           className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                           placeholder="Quartier, rue, porte..."
                           required/>
                </div>
                <div className="mb-6">
                    <label className="block mb-2 text-sm font-medium text-gray-900">Secteur d'activité</label>
                    <input type="text"
                           name="activity"
                           value={formData.activity}
                           onChange={handleChange}
                           className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                           placeholder="Commerce, Industrie, etc."
                           required/>
                </div>
                <div className="flex items-start mb-6">
                    <div className="flex items-center h-5">
                        <input id="agree" type="checkbox" required
                               className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300"/>
                    </div>
                    <label htmlFor="agree" className="ml-2 text-sm font-medium text-gray-900">
                        Je suis d'accord avec les termes et conditions..
                    </label>
                </div>
                <button type="submit"
                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">
                    S'inscrire
                </button>
            </form>
            <p className="mt-5 pb-16">
                Merci pour votre inscription et la confiance que vous accordez à nos services. Toute l'équipe de
                MedinChina est là pour vous offrir le meilleur.
                <h1 className="font-semibold items-center">La Team MedinChina</h1>
            </p>
        </div>
    );
};

export default AddItem;
