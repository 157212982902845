import React from 'react';
import {MyClients} from "../components";
import Header3 from "../layout/header/Header3";
import Footer from "../layout/footer/Footer";

const Clients = () => {
    return (
        <div>
            <Header3/>
            <MyClients/>
            <Footer/>
        </div>
    );
};

export default Clients;