import React, {useState, useEffect} from 'react';
import {motion, AnimatePresence} from 'framer-motion';
import imag1 from "../../assets/img/start/imas01.jpg";
import imag2 from "../../assets/img/start/imas02.jpg";
import imag3 from "../../assets/img/start/imas03.jpg";
import imag4 from "../../assets/img/start/imas04.jpg";
import imag5 from "../../assets/img/start/imas05.jpg";
import imag6 from "../../assets/img/start/imas06.jpg";
import imag7 from "../../assets/img/start/imas07.jpg";
import {Link} from "react-router-dom";

const slides = [imag1, imag2, imag3, imag4, imag5, imag6, imag7];

const Hero = () => {
    const [currentSlide, setCurrentSlide] = useState(0);

    useEffect(() => {
        const slideInterval = setInterval(() => {
            setCurrentSlide(prevSlide => (prevSlide + 1) % slides.length);
        }, 5000); // Change slide every 5 seconds

        return () => clearInterval(slideInterval);
    }, []);

    return (
        <div className='relative w-full h-screen overflow-hidden'>
            <AnimatePresence>
                <motion.div
                    key={currentSlide}
                    className='absolute top-0 left-0 w-full h-screen'
                    initial={{opacity: 0, scale: 1.2}}
                    animate={{opacity: 1, scale: 1}}
                    exit={{opacity: 0, scale: 1.2}}
                    transition={{duration: 5, ease: 'easeInOut'}}
                >
                    <img
                        className='w-full h-full object-cover'
                        src={slides[currentSlide]}
                        alt='Slideshow'
                    />
                </motion.div>
            </AnimatePresence>
            <div className='bg-black/20 absolute top-0 left-0 w-full h-screen'/>
            <div className='absolute top-0 w-full h-full flex flex-col justify-center text-white'>
                <div className='md:left-[10%] max-w-[1100px] m-auto absolute p-4'>
                    <p>Avec medinchina</p>
                    <h1 className='font-bold text-5xl md:text-7xl drop-shadow-2xl'>
                        C'est toujours excitant.
                    </h1>
                    <p className='max-w-[600px] drop-shadow-2xl py-2 text-xl'>
                        Devenez client et bénéficiez de nombreux avantages.
                    </p>
                    <Link to={"/clients"}>
                        <button className='bg-white text-black p-3 rounded-full '> Rejoignez-nous
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Hero;
