import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import {IoBagHandleOutline} from 'react-icons/io5';
import Header1 from './Header1.jsx';
import {FaFacebookF, FaTiktok, FaWhatsapp} from "react-icons/fa";
import {PiMathOperationsBold} from "react-icons/pi";

const NavItem = ({to, icon: Icon, label}) => {
    const {pathname} = useLocation();
    const isActive = pathname === to;
    const linkClass = isActive ? 'text-red-500' : 'text-gray-500';

    return (
        <Link to={to}>
            <li className="flex flex-col items-center ">
                <Icon className={`h-8 w-8 ${linkClass}`}/>
                <h3 className={`text-xs font-bold mt-2 uppercase ${linkClass}`}>{label}</h3>
            </li>
        </Link>
    );
};

const Header2 = () => {
    return (
        <>
            <Header1/>
            <nav className="fixed bottom-0 w-full bg-white border-t border-gray-100 shadow z-50">
                <div className="w-full max-w-screen-xl mx-auto p-4">
                    <div className="flex justify-center">
                        <ul className="flex flex-wrap gap-4 items-center text-sm font-medium">
                            <NavItem to="/" icon={PiMathOperationsBold}/>
                            <NavItem to="/orders" icon={IoBagHandleOutline}/>
                            <NavItem to="https://wa.me/message/HKKNJXX6OIIBH1"
                                     icon={FaWhatsapp}/>
                            <NavItem to="https://www.facebook.com/share/DThdENX1wX6PzSKe/?mibextid=LQQJ4d"
                                     icon={FaFacebookF}/>
                            <NavItem to="https://www.tiktok.com/@medinchina20?_t=8meNAk6klHW&_r=1"
                                     icon={FaTiktok}/>
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    );
};

export default React.memo(Header2);
