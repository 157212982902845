import React, {useState} from 'react';
import book from "../../assets/new/Guide2024.pdf";
import {HiMenuAlt3} from 'react-icons/hi';
import {Link} from "react-router-dom";
import {LuFileDown} from "react-icons/lu";
import {FaThumbsUp} from "react-icons/fa";

const Navbar = () => {
    const [nav, setNav] = useState(false);
    const handleNav = () => {
        setNav(!nav);
        document.body.style.overflow = nav ? 'scroll' : 'hidden';
    };

    return (
        <div className='absolute w-full flex justify-between p-4 items-center'>
            <h1 className='text-white font-bold text-2xl z-20'>Medinchina</h1>
            <HiMenuAlt3 onClick={handleNav} className='z-20 text-white cursor-pointer' size={25}/>
            <div
                className={
                    nav
                        ? 'ease-in duration-300 fixed text-gray-300 left-0 top-0 w-full h-screen bg-black/90 px-4 py-7 flex-col z-10'
                        : 'absolute top-0 h-screen left-[-100%] ease-in duration-500 z-10'
                }
            >
                <ul className='flex flex-col fixed w-full h-full items-center justify-center'>
                    <Link to={"/"}>
                        <li className='font-bold text-3xl p-8'>Nos Simulateurs</li>
                    </Link>
                    <a href={book} download className="flex items-center">
                        <li className='font-bold text-3xl p-8 flex items-center'>
                            Nos Services
                            <LuFileDown className="ml-2"/>
                        </li>
                    </a>
                    <a href={"/question"} className="flex items-center">
                        <li className='font-bold text-3xl p-8 flex items-center'>
                            Votre Note
                            <FaThumbsUp className="ml-2"/>
                        </li>
                    </a>
                    <Link to={"/clients"}>
                        <li className='font-bold text-3xl p-8'>Nos Clients</li>
                    </Link>
                    <Link to={"/fourus"}>
                        <li className='font-bold text-3xl p-8'>Admin</li>
                    </Link>
                </ul>
            </div>
        </div>
    );
};

export default Navbar;
