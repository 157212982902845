import React from "react";
import {
    FaWeixin,
    FaFacebookF,
    FaInstagram,
    FaLinkedinIn,
    FaTiktok,
    FaWhatsapp,
    FaYoutube,
    FaTelegramPlane,
} from "react-icons/fa";

const Social = () => {
    return (
        <div
            className=" px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
            <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
                <div>
                    <p className=" inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider uppercase rounded-full text-primary-700 ">
                        medinchina Social Media
                    </p>
                </div>
                <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-secondary-700 sm:text-4xl md:mx-auto">
                    Suivez-nous sur les médias sociaux et rejoignez-nous
                </h2>
                <p className="text-base text-bgkd-500 md:text-lg"></p>
            </div>
            <div
                className="grid gap-10 row-gap-8 mx-auto sm:row-gap-10 lg:max-w-screen-lg sm:grid-cols-2 lg:grid-cols-3">
                <div className="flex items-center">
                    <div className="object-cover w-20 h-20 mr-4 rounded-full flex items-center justify-center shadow ">
                        <FaFacebookF className="h-10 w-10  text-blue-600 "/>
                    </div>
                    <a href="https://www.facebook.com/share/DThdENX1wX6PzSKe/?mibextid=LQQJ4d" target="_blank"
                       rel="noopener noreferrer">
                        <div className="flex flex-col justify-center">
                            <p className="text-lg font-bold text-secondary-500 ">
                                Facebook
                            </p>
                            <p className="text-sm text-secondary-400 ">
                                Suivez-nous
                            </p>
                        </div>
                    </a>
                </div>
                <div className="flex items-center">
                    <div className="object-cover w-20 h-20 mr-4 rounded-full flex items-center justify-center shadow ">
                        <FaTelegramPlane className="h-10 w-10  text-blue-400 "/>
                    </div>
                    <a href="https://t.me/agencemedinchina" target="_blank" rel="noopener noreferrer">
                        <div className="flex flex-col justify-center">
                            <p className="text-lg font-bold text-secondary-500 ">
                                Telegram
                            </p>
                            <p className="text-sm text-secondary-400 ">
                                Suivez-nous
                            </p>
                        </div>
                    </a>
                </div>
                <div className="flex items-center">
                    <div className="object-cover w-20 h-20 mr-4 rounded-full flex items-center justify-center shadow ">
                        <FaLinkedinIn className="h-10 w-10  text-blue-600 "/>
                    </div>
                    <div className="flex flex-col justify-center">
                        <p className="text-lg font-bold text-secondary-500 ">
                            LinkedIn
                        </p>
                        <p className="text-sm text-secondary-400 ">
                            Suivez-nous
                        </p>
                    </div>
                </div>
                <div className="flex items-center">
                    <div className="object-cover w-20 h-20 mr-4 rounded-full flex items-center justify-center shadow ">
                        <FaInstagram className="h-10 w-10  text-pink-600 "/>
                    </div>
                    <a href="https://www.instagram.com/medinchina20?igsh=MWpsN2d3MDdiZzVlMQ%3D%3D&utm_source=qr"
                       target="_blank" rel="noopener noreferrer">
                        <div className="flex flex-col justify-center">
                            <p className="text-lg font-bold text-secondary-500 ">
                                Instagram
                            </p>
                            <p className="text-sm text-secondary-400 ">
                                Suivez-nous
                            </p>
                        </div>
                    </a>
                </div>
                <div className="flex items-center">
                    <div className="object-cover w-20 h-20 mr-4 rounded-full flex items-center justify-center shadow ">
                        <FaWhatsapp className="h-10 w-10  text-green-700 "/>
                    </div>
                    <a href="https://wa.me/message/HKKNJXX6OIIBH1" target="_blank" rel="noopener noreferrer">
                        <div className="flex flex-col justify-center">
                            <p className="text-lg font-bold text-secondary-500">
                                Whatsapp
                            </p>
                            <p className="text-sm text-secondary-400">
                                Contactez-nous
                            </p>
                        </div>
                    </a>
                </div>
                <div className="flex items-center">
                    <div className="object-cover w-20 h-20 mr-4 rounded-full flex items-center justify-center shadow ">
                        <FaWeixin className="h-10 w-10  text-green-700"/>
                    </div>
                    <div className="flex flex-col justify-center">
                        <p className="text-lg font-bold text-secondary-500 ">
                            Weixin
                        </p>
                        <p className="text-sm text-secondary-400 ">
                            Contactez-nous
                        </p>
                    </div>
                </div>
                <div className="flex items-center">
                    <div className="object-cover w-20 h-20 mr-4 rounded-full flex items-center justify-center shadow ">
                        <FaTiktok className="h-10 w-10  text-bgkd-800 dark:text-bgkd-50"/>
                    </div>
                    <a href="https://www.tiktok.com/@medinchina20?_t=8meNAk6klHW&_r=1" target="_blank"
                       rel="noopener noreferrer">
                        <div className="flex flex-col justify-center">
                            <p className="text-lg font-bold text-secondary-500">
                                Tiktok
                            </p>
                            <p className="text-sm text-secondary-400">
                                Suivez-nous
                            </p>
                        </div>
                    </a>
                </div>
                <div className="flex items-center">
                    <div className="object-cover w-20 h-20 mr-4 rounded-full flex items-center justify-center shadow ">
                        <FaYoutube className="h-10 w-10  text-primary-700"/>
                    </div>
                    <div className="flex flex-col justify-center">
                        <p className="text-lg font-bold text-secondary-500">
                            Youtube
                        </p>
                        <p className="text-sm text-secondary-400">
                            Suivez-nous
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Social;
