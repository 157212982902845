import React from 'react';
import {TransTab} from "../index";

const Country = () => {
    return (
        <div>
            <TransTab/>
        </div>
    );
};

export default Country;