import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import products from '../../Data/paysliste';

const Delivery = () => {
    const [dimensions, setDimensions] = useState({height: '', width: '', length: ''});
    const [type, setType] = useState('');
    const [pays, setPays] = useState('');
    const [country, setCountry] = useState('');
    const [weight, setWeight] = useState('');
    const [currency, setCurrency] = useState('');
    const [finalPrice, setFinalPrice] = useState(0);
    const [pricecbm, setPriceCbm] = useState(0);
    const [unit, setUnit] = useState('M'); // New state for unit of measurement

    // Combined handlers for input changes to reduce redundancy
    const handleInputChange = (setter) => (event) => setter(event.target.value);

    // Calculate total and status for air delivery
    useEffect(() => {
        if (!country || !weight || !currency) return;
        const selectedCountry = products.find((item) => item.country === country);
        const price = selectedCountry ? selectedCountry[currency] : null;
        setFinalPrice(price ? price * weight : 0);
    }, [country, weight, currency]);

    // Calculate price per cbm for boat delivery
    useEffect(() => {
        const {height, length, width} = dimensions;
        if (!height || !length || !width || !pays) return;

        // Convert dimensions to meters
        const conversionFactor = unit === 'MM' ? 0.001 : unit === 'CM' ? 0.01 : 1;
        const convertedHeight = height * conversionFactor;
        const convertedLength = length * conversionFactor;
        const convertedWidth = width * conversionFactor;

        const cbm = convertedHeight * convertedLength * convertedWidth;
        const prices = {gab: 240000, cam: 270000, rca: 350000, ci: 260000, tchad: 300000, congo: 260000};
        const final = prices[pays] ? cbm * prices[pays] : cbm;
        setPriceCbm(isNaN(final) ? 0 : parseFloat(final.toFixed(3)));
    }, [dimensions, pays, unit]);

    // Map unit to its full form for placeholder
    const unitMap = {
        M: 'm',
        CM: 'cm',
        MM: 'mm'
    };

    return (
        <div
            className="bg-cream text-charcoal min-h-screen font-sans leading-normal overflow-x-hidden lg:overflow-auto py-16">
            <main className="flex-1 md:p-0 lg:pt-8 lg:px-8 md:ml-24 flex flex-col">
                <section className="bg-cream-lighter p-4 shadow">
                    <form>
                        <div className="md:flex mb-8">
                            <div className="md:w-1/3">
                                <legend className="uppercase tracking-wide font-semibold text-sm">Calculateur de frais
                                    de livraison
                                </legend>
                                <p className="text-xs font-light text-red">Cette section entière est requise.</p>
                            </div>
                            <div className="md:flex-1 mt-2 mb:mt-0 md:px-3">
                                <div className="mb-4">
                                    <label className="block uppercase tracking-wide text-xs font-bold">Le moyen de
                                        Livraison</label>
                                    <select className="w-full shadow-inner p-4 border-0 bg-transparent" value={type}
                                            required onChange={handleInputChange(setType)}>
                                        <option value="">Selectionner</option>
                                        <option value="Bateau">Bateau</option>
                                        <option value="Avion">Avion</option>
                                    </select>
                                    <span className="text-xs mb-4 font-thin">Ceci est requis.</span>
                                </div>
                                {type === "Bateau" && (
                                    <>
                                        <div className="mb-4">
                                            <label className="block uppercase tracking-wide text-xs font-bold">le
                                                Pays</label>
                                            <select className="w-full shadow-inner p-4 border-0 bg-transparent" required
                                                    onChange={handleInputChange(setPays)}>
                                                <option value="">Votre pays</option>
                                                <option value="gab">Gabon</option>
                                                <option value="cam">Cameroun</option>
                                                <option value="rca">République centrafricaine</option>
                                                <option value="ci">Cote d'ivoire</option>
                                                <option value="tchad">Tchad</option>
                                                <option value="congo">Congo Brazzaville</option>
                                            </select>
                                            <span
                                                className="text-xs mb-4 font-thin text-primary-700">Ceci est requis.</span>
                                        </div>
                                        <div className="mb-4">
                                            <label className="block uppercase tracking-wide text-xs font-bold">Les
                                                dimensions</label>
                                            <select className="w-full shadow-inner p-4 border-0 bg-transparent"
                                                    value={unit} onChange={handleInputChange(setUnit)}>
                                                <option value="M">Mètre</option>
                                                <option value="CM">Centimètre</option>
                                                <option value="MM">Millimètre</option>
                                            </select>
                                            <span
                                                className="text-xs mb-4 font-thin text-primary-700">Ceci est requis.</span>
                                        </div>
                                        <div className="grid grid-cols-3 gap-4 md:flex mb-4">
                                            <div className="md:flex-1 md:pr-3">
                                                <label
                                                    className="block uppercase tracking-wide text-charcoal-darker text-xs font-bold">Longueur</label>
                                                <input required placeholder={unitMap[unit]} type="number" id="length"
                                                       value={dimensions.length} onChange={(e) => setDimensions({
                                                    ...dimensions,
                                                    length: e.target.value
                                                })} className="w-full shadow-inner p-4 border-0"/>
                                            </div>
                                            <div className="md:flex-1 md:pl-3">
                                                <label
                                                    className="block uppercase tracking-wide text-charcoal-darker text-xs font-bold">Largeur</label>
                                                <input required placeholder={unitMap[unit]} type="number" id="width"
                                                       value={dimensions.width} onChange={(e) => setDimensions({
                                                    ...dimensions,
                                                    width: e.target.value
                                                })} className="w-full shadow-inner p-4 border-0"/>
                                            </div>
                                            <div className="md:flex-1 md:pl-3">
                                                <label
                                                    className="block uppercase tracking-wide text-charcoal-darker text-xs font-bold">Hauteur</label>
                                                <input required placeholder={unitMap[unit]} type="number" id="height"
                                                       value={dimensions.height} onChange={(e) => setDimensions({
                                                    ...dimensions,
                                                    height: e.target.value
                                                })} className="w-full shadow-inner p-4 border-0"/>
                                            </div>
                                        </div>
                                        <div className="mb-4">
                                            <label className="block uppercase tracking-wide text-xs font-bold">Montant
                                                final</label>
                                            <p className="w-full shadow-inner font-bold p-4 border-0">{pricecbm} FCFA</p>
                                        </div>
                                    </>
                                )}
                                {type === "Avion" && (
                                    <>
                                        <div className="mb-4">
                                            <label className="block uppercase tracking-wide text-xs font-bold">le
                                                Pays</label>
                                            <select className="w-full shadow-inner p-4 border-0 bg-transparent" required
                                                    onChange={handleInputChange(setCountry)}>
                                                <option value="">Votre pays</option>
                                                {products.map(item => (
                                                    <option key={item._id} value={item.country}>{item.country}</option>
                                                ))}
                                            </select>
                                            <span
                                                className="text-xs mb-4 font-thin text-primary-700">Ceci est requis.</span>
                                        </div>
                                        <div className="grid grid-cols-2 gap-2 md:flex mb-4">
                                            <div className="md:flex-1 md:pr-3">
                                                <label className="block uppercase tracking-wide text-xs font-bold">Les
                                                    devise</label>
                                                <select className="w-full shadow-inner p-4 border-0 bg-transparent"
                                                        onChange={handleInputChange(setCurrency)}>
                                                    <option value="">Selectionner</option>
                                                    <option value="XAF">FCFA</option>
                                                    <option value="USD">DOLLAR</option>
                                                </select>
                                                <span className="text-xs mb-4 font-thin text-primary-700">Ceci est requis.</span>
                                            </div>
                                            <div className="md:flex-1 md:pl-3">
                                                <label
                                                    className="block uppercase tracking-wide text-charcoal-darker text-xs font-bold">Poids(kg)</label>
                                                <input required type="number" id="weight" value={weight}
                                                       onChange={handleInputChange(setWeight)}
                                                       className="w-full shadow-inner p-4 border-0"/>
                                                <span className="text-xs mb-4 font-thin text-primary-700">Ceci est requis.</span>
                                            </div>
                                        </div>
                                        <div className="mb-4">
                                            <label
                                                className="block uppercase tracking-wide text-xs font-bold">Montant</label>
                                            {finalPrice !== null ? (
                                                <p className="w-full shadow-inner font-bold p-4 border-0">{finalPrice} {currency}</p>
                                            ) : (
                                                <p className="w-full shadow-inner font-bold p-4 border-0">Price not
                                                    available</p>
                                            )}
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="md:flex mb-6">
                            <div className="md:w-1/3">
                                <legend className="uppercase tracking-wide text-sm">Description</legend>
                            </div>
                            <div className="md:flex-1 mt-2 mb:mt-0 md:px-3">
                                {pricecbm > 0 && type === "Bateau" && (
                                    <p>
                                        Nous vous informons que votre colis sera expédié par bateau et devrait arriver
                                        dans un délai de 45 à 60 jours à compter de la confirmation de la commande. Le
                                        montant à régler pour cette expédition s'élève à {pricecbm} FCFA.
                                        Pour toute question ou clarification, n'hésitez pas à nous contacter <Link
                                        to="/Services" className="font-bold text-primary-700">ici</Link>.
                                    </p>
                                )}
                                {finalPrice > 0 && type === "Avion" && (
                                    <p>
                                        Nous vous informons que votre colis sera expédié par avion en mode Express et
                                        devrait arriver dans un délai de 7 à 14 jours à compter de la confirmation de
                                        votre commande. Le montant à régler pour cette expédition s'élève
                                        à {finalPrice} {currency}. Pour toute question ou clarification, n'hésitez pas à
                                        nous contacter <Link to="/Services"
                                                             className="font-bold text-primary-700">ici</Link>.
                                    </p>
                                )}
                            </div>
                        </div>
                    </form>
                </section>
            </main>
        </div>
    );
};

export default Delivery;
