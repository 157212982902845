const pageData = {
    Orders: {
        title: "Commandes",
        paragraph: "Prêt à passer à l'action ? Profitez d'une expérience fluide et sécurisée pour concrétiser vos achats. Explorez dès maintenant notre sélection et passez votre commande en quelques clics !",

    },
    Services: {
        title: "Services",
        paragraph: "Gagnez du temps en explorant directement nos services. Téléchargez dès maintenant notre guide pour découvrir nos offres et trouver la solution idéale pour vos besoins.",

    },
    Delivery: {
        title: "Livraisons",
        paragraph: "Nous comprenons l'importance de la rapidité, de la fiabilité et de la sécurité dans le transport et la livraison de vos marchandises.",

    },
    Contact: {
        title: "Contacts",
        paragraph: "Vous avez des questions, des commentaires ou des besoins spécifiques ? Contactez-nous ! Notre équipe dévouée est là pour vous aider. Nous vous répondrons dans les plus brefs délais.",

    },
    About: {
        title: "Notre Histoire",
        paragraph: "Depuis nos humbles débuts en 2020 sous le nom de Vertex, nous avons métamorphosé notre identité pour devenir Medinchina. Aujourd'hui, nous sommes une agence innovante, dédiée à simplifier les transactions commerciales entre la Chine et le reste du monde.",

    },
    Question: {
        title: "Questionnaire",
        paragraph: "Nous vous serions reconnaissants de bien vouloir partager votre opinion sur la qualité de notre service client. Pour nous aider à améliorer nos services, veuillez prendre quelques instants pour répondre à nos questions. Nous vous remercions par avance pour votre collaboration.",

    },
    Wigs: {
        title: "Sur mesure",
        paragraph: "Nous sommes ravis de vous annoncer le lancement de notre nouvelle page dédiée à la recherche de mesh sur mesure, conçue spécialement pour répondre à vos besoins tout en respectant votre budget. Découvrez une expérience personnalisée et simplifiée pour trouver les solutions adaptées à vos exigences. ",

    },
    Documents: {
        title: "Explorez nos Documents",
        paragraph: "Gagnez du temps en explorant directement nos documents. Téléchargez dès maintenant notre guide pour découvrir nos offres et trouver la solution idéale pour vos besoins.",

    },
    Payment: {
        title: "Moyens de Paiement",
        paragraph: "Nous vous offrons une variété de moyens de paiement pour faciliter vos transactions. Découvrez les options disponibles et choisissez celle qui vous convient le mieux.",

    },
    // Add more pages as needed
};

export default pageData;
