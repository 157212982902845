const wigs = [
    {
        id: 1,
        type: "BOB Wig",
        density: "150%",
        grade: "10A",
        styles: [
            {
                id: 1,// Unique identifier for this style
                lace: "4x1 T bob",
                lengths: [
                    {length: 8, price: 137},
                    {length: 10, price: 140},
                    {length: 12, price: 165},
                    {length: 14, price: 195},
                    {length: 16, price: 245},
                    {length: 18, price: 310}
                ]
            },
            {
                id: 2,
                lace: "13x6x1 T bob",
                lengths: [
                    {length: 8, price: 172},
                    {length: 10, price: 175},
                    {length: 12, price: 215},
                    {length: 14, price: 255},
                    {length: 16, price: 305},
                    {length: 18, price: 370}
                ]
            },
            {
                id: 3,
                lace: "4x4 bob",
                lengths: [
                    {length: 8, price: 145},
                    {length: 10, price: 150},
                    {length: 12, price: 170},
                    {length: 14, price: 205},
                    {length: 16, price: 255},
                    {length: 18, price: 350}
                ]
            },
            {
                id: 4,
                lace: "5x5 bob",
                lengths: [
                    {length: 8, price: 222},
                    {length: 10, price: 225},
                    {length: 12, price: 255},
                    {length: 14, price: 295},
                    {length: 16, price: 340},
                    {length: 18, price: 390}
                ]
            },
            {
                id: 5,
                lace: "13x4 bob",
                lengths: [
                    {length: 8, price: 165},
                    {length: 10, price: 170},
                    {length: 12, price: 210},
                    {length: 14, price: 250},
                    {length: 16, price: 270},
                    {length: 18, price: 380}
                ]
            },
            {
                id: 6,
                lace: "13x6 bob",
                lengths: [
                    {length: 8, price: 335},
                    {length: 10, price: 340},
                    {length: 12, price: 380},
                    {length: 14, price: 420},
                    {length: 16, price: 460},
                    {length: 18, price: 520}
                ]
            },
            {
                id: 7,
                lace: "No lace Bang",
                lengths: [
                    {length: 8, price: 107},
                    {length: 10, price: 110},
                    {length: 12, price: 135},
                    {length: 14, price: 165},
                    {length: 16, price: 215},
                    {length: 18, price: 280}
                ]
            },
            {
                id: 8,
                lace: "V Part Bob",
                lengths: [
                    {length: 8, price: 135},
                    {length: 10, price: 140},
                    {length: 12, price: 180},
                    {length: 14, price: 210},
                    {length: 16, price: 260},
                    {length: 18, price: 320}
                ]
            }
        ]
    },
    {
        id: 2,
        type: "BOB Wig",
        density: "180%",
        grade: "10A",
        styles: [
            {
                id: 1,// Unique identifier for this style
                lace: "4x1 T bob",
                lengths: [
                    {length: 8, price: 152},
                    {length: 10, price: 155},
                    {length: 12, price: 190},
                    {length: 14, price: 235},
                    {length: 16, price: 295},
                    {length: 18, price: 375}
                ]
            },
            {
                id: 2,
                lace: "13x6x1 T bob",
                lengths: [
                    {length: 8, price: 187},
                    {length: 10, price: 190},
                    {length: 12, price: 240},
                    {length: 14, price: 295},
                    {length: 16, price: 355},
                    {length: 18, price: 435}
                ]
            },
            {
                id: 3,
                lace: "4x4 bob",
                lengths: [
                    {length: 8, price: 160},
                    {length: 10, price: 165},
                    {length: 12, price: 195},
                    {length: 14, price: 245},
                    {length: 16, price: 305},
                    {length: 18, price: 415}
                ]
            },
            {
                id: 4,
                lace: "5x5 bob",
                lengths: [
                    {length: 8, price: 237},
                    {length: 10, price: 240},
                    {length: 12, price: 280},
                    {length: 14, price: 335},
                    {length: 16, price: 390},
                    {length: 18, price: 455}
                ]
            },
            {
                id: 5,
                lace: "13x4 bob",
                lengths: [
                    {length: 8, price: 180},
                    {length: 10, price: 185},
                    {length: 12, price: 235},
                    {length: 14, price: 290},
                    {length: 16, price: 320},
                    {length: 18, price: 445}
                ]
            },
            {
                id: 6,
                lace: "13x6 bob",
                lengths: [
                    {length: 8, price: 350},
                    {length: 10, price: 355},
                    {length: 12, price: 405},
                    {length: 14, price: 460},
                    {length: 16, price: 510},
                    {length: 18, price: 585}
                ]
            },
            {
                id: 7,
                lace: "No lace Bang",
                lengths: [
                    {length: 8, price: 122},
                    {length: 10, price: 125},
                    {length: 12, price: 160},
                    {length: 14, price: 205},
                    {length: 16, price: 265},
                    {length: 18, price: 345}
                ]
            },
            {
                id: 8,
                lace: "V Part Bob",
                lengths: [
                    {length: 8, price: 150},
                    {length: 10, price: 155},
                    {length: 12, price: 205},
                    {length: 14, price: 250},
                    {length: 16, price: 310},
                    {length: 18, price: 385}
                ]
            }
        ]
    },
    {
        id: 3,
        type: "BOB Wig",
        density: "210%",
        grade: "10A",
        styles: [
            {
                id: 1,// Unique identifier for this style
                lace: "4x1 T bob",
                lengths: [
                    {length: 8, price: 167},
                    {length: 10, price: 170},
                    {length: 12, price: 215},
                    {length: 14, price: 275},
                    {length: 16, price: 345},
                    {length: 18, price: 440}
                ]
            },
            {
                id: 2,
                lace: "13x6x1 T bob",
                lengths: [
                    {length: 8, price: 202},
                    {length: 10, price: 205},
                    {length: 12, price: 265},
                    {length: 14, price: 335},
                    {length: 16, price: 405},
                    {length: 18, price: 480}
                ]
            },
            {
                id: 3,
                lace: "4x4 bob",
                lengths: [
                    {length: 8, price: 175},
                    {length: 10, price: 180},
                    {length: 12, price: 220},
                    {length: 14, price: 285},
                    {length: 16, price: 355},
                    {length: 18, price: 480}
                ]
            },
            {
                id: 4,
                lace: "5x5 bob",
                lengths: [
                    {length: 8, price: 252},
                    {length: 10, price: 255},
                    {length: 12, price: 305},
                    {length: 14, price: 375},
                    {length: 16, price: 440},
                    {length: 18, price: 520}
                ]
            },
            {
                id: 5,
                lace: "13x4 bob",
                lengths: [
                    {length: 8, price: 195},
                    {length: 10, price: 200},
                    {length: 12, price: 260},
                    {length: 14, price: 330},
                    {length: 16, price: 370},
                    {length: 18, price: 510}
                ]
            },
            {
                id: 6,
                lace: "13x6 bob",
                lengths: [
                    {length: 8, price: 365},
                    {length: 10, price: 370},
                    {length: 12, price: 430},
                    {length: 14, price: 500},
                    {length: 16, price: 560},
                    {length: 18, price: 650}
                ]
            },
            {
                id: 7,
                lace: "No lace Bang",
                lengths: [
                    {length: 8, price: 137},
                    {length: 10, price: 140},
                    {length: 12, price: 185},
                    {length: 14, price: 245},
                    {length: 16, price: 315},
                    {length: 18, price: 410}
                ]
            },
            {
                id: 8,
                lace: "V Part Bob",
                lengths: [
                    {length: 8, price: 165},
                    {length: 10, price: 170},
                    {length: 12, price: 230},
                    {length: 14, price: 290},
                    {length: 16, price: 360},
                    {length: 18, price: 450}
                ]
            }
        ]
    },
    {
        id: 4,
        type: "BOB Wig",
        density: "150%",
        grade: "12A",
        styles: [
            {
                id: 1,// Unique identifier for this style
                lace: "2x6 BOB",
                lengths: [
                    {length: 10, price: 165},
                    {length: 12, price: 205},
                    {length: 14, price: 250},
                ]
            },
            {
                id: 2,
                lace: "4x4 BOB",
                lengths: [
                    {length: 10, price: 175},
                    {length: 12, price: 215},
                    {length: 14, price: 260},
                ]
            },
            {
                id: 3,
                lace: "5x5 BOB",
                lengths: [
                    {length: 10, price: 185},
                    {length: 12, price: 225},
                    {length: 14, price: 270},
                ]
            },
            {
                id: 4,
                lace: "13x4 large BOB",
                lengths: [
                    {length: 10, price: 205},
                    {length: 12, price: 225},
                    {length: 14, price: 270},
                ]
            },
            {
                id: 5,
                lace: "3x1 mécanisme BOB",
                lengths: [
                    {length: 10, price: 185},
                    {length: 12, price: 195},
                    {length: 14, price: 255},

                ]
            },
            {
                id: 6,
                lace: "No lace Bang",
                lengths: [
                    {length: 10, price: 135},
                    {length: 12, price: 175},
                    {length: 14, price: 225},
                ]
            }
        ]
    },
    {
        id: 5,
        type: "BOB Wig",
        density: "180%",
        grade: "12A",
        styles: [
            {
                id: 1,// Unique identifier for this style
                lace: "2x6 BOB",
                lengths: [
                    {length: 10, price: 185},
                    {length: 12, price: 235},
                    {length: 14, price: 290},
                ]
            },
            {
                id: 2,
                lace: "4x4 BOB",
                lengths: [
                    {length: 10, price: 195},
                    {length: 12, price: 245},
                    {length: 14, price: 300},
                ]
            },
            {
                id: 3,
                lace: "5x5 BOB",
                lengths: [
                    {length: 10, price: 205},
                    {length: 12, price: 255},
                    {length: 14, price: 310},
                ]
            },
            {
                id: 4,
                lace: "13x4 large BOB",
                lengths: [
                    {length: 10, price: 225},
                    {length: 12, price: 255},
                    {length: 14, price: 310},
                ]
            },
            {
                id: 5,
                lace: "3x1 mécanisme BOB",
                lengths: [
                    {length: 10, price: 205},
                    {length: 12, price: 225},
                    {length: 14, price: 295},
                ]
            },
            {
                id: 6,
                lace: "No lace Bang",
                lengths: [
                    {length: 10, price: 155},
                    {length: 12, price: 205},
                    {length: 14, price: 265},
                ]
            }
        ]
    },
    {
        id: 6,
        type: "BOB Wig",
        density: "210%",
        grade: "12A",
        styles: [
            {
                id: 1,// Unique identifier for this style
                lace: "2x6 BOB",
                lengths: [
                    {length: 10, price: 205},
                    {length: 12, price: 265},
                    {length: 14, price: 330},
                ]
            },
            {
                id: 2,
                lace: "4x4 BOB",
                lengths: [
                    {length: 10, price: 215},
                    {length: 12, price: 275},
                    {length: 14, price: 340},
                ]
            },
            {
                id: 3,
                lace: "5x5 BOB",
                lengths: [
                    {length: 10, price: 225},
                    {length: 12, price: 285},
                    {length: 14, price: 350},
                ]
            },
            {
                id: 4,
                lace: "13x4 large BOB",
                lengths: [
                    {length: 10, price: 245},
                    {length: 12, price: 285},
                    {length: 14, price: 350},
                ]
            },
            {
                id: 5,
                lace: "3x1 mécanisme BOB",
                lengths: [
                    {length: 10, price: 225},
                    {length: 12, price: 255},
                    {length: 14, price: 335},

                ]
            },
            {
                id: 6,
                lace: "No lace Bang",
                lengths: [
                    {length: 10, price: 175},
                    {length: 12, price: 235},
                    {length: 14, price: 305},
                ]
            }
        ]
    },
    {
        id: 7,
        type: "BOB Wig",
        density: "250%",
        grade: "12A",
        styles: [
            {
                id: 1,// Unique identifier for this style
                lace: "2x6 BOB",
                lengths: [
                    {length: 10, price: 225},
                    {length: 12, price: 295},
                    {length: 14, price: 370},
                ]
            },
            {
                id: 2,
                lace: "4x4 BOB",
                lengths: [
                    {length: 10, price: 235},
                    {length: 12, price: 305},
                    {length: 14, price: 380},
                ]
            },
            {
                id: 3,
                lace: "5x5 BOB",
                lengths: [
                    {length: 10, price: 245},
                    {length: 12, price: 315},
                    {length: 14, price: 390},
                ]
            },
            {
                id: 4,
                lace: "13x4 large BOB",
                lengths: [
                    {length: 10, price: 265},
                    {length: 12, price: 315},
                    {length: 14, price: 390},
                ]
            },
            {
                id: 5,
                lace: "3x1 mécanisme BOB",
                lengths: [
                    {length: 10, price: 245},
                    {length: 12, price: 285},
                    {length: 14, price: 375},

                ]
            },
            {
                id: 6,
                lace: "No lace Bang",
                lengths: [
                    {length: 10, price: 195},
                    {length: 12, price: 265},
                    {length: 14, price: 345},
                ]
            }
        ]
    },
    {
        id: 8,
        type: "#613 Straight BOB",
        density: "150%",
        grade: "Naturel",
        styles: [
            {
                id: 1,// Unique identifier for this style
                lace: "4x4 BOB",
                lengths: [
                    {length: 12, price: 245},
                    {length: 14, price: 280},
                    {length: 16, price: 310},
                ]
            },
            {
                id: 2,
                lace: "13x4 Hey BOB",
                lengths: [
                    {length: 12, price: 260},
                    {length: 14, price: 295},
                    {length: 16, price: 325},
                ]
            },
            {
                id: 3,
                lace: "13x4 large BOB",
                lengths: [
                    {length: 12, price: 280},
                    {length: 14, price: 315},
                    {length: 16, price: 345},
                ]
            },
        ]
    },
    {
        id: 9,
        type: "#613 Straight BOB",
        density: "180%",
        grade: "Naturel",
        styles: [
            {
                id: 1,// Unique identifier for this style
                lace: "4x4 BOB",
                lengths: [
                    {length: 12, price: 275},
                    {length: 14, price: 310},
                    {length: 16, price: 350},
                ]
            },
            {
                id: 2,
                lace: "13x4 Hey BOB",
                lengths: [
                    {length: 12, price: 290},
                    {length: 14, price: 325},
                    {length: 16, price: 365},
                ]
            },
            {
                id: 3,
                lace: "13x4 large BOB",
                lengths: [
                    {length: 12, price: 310},
                    {length: 14, price: 345},
                    {length: 16, price: 385},
                ]
            },
        ]
    },
    {
        id: 10,
        type: "#613 Straight BOB",
        density: "210%",
        grade: "Naturel",
        styles: [
            {
                id: 1,// Unique identifier for this style
                lace: "4x4 BOB",
                lengths: [
                    {length: 12, price: 305},
                    {length: 14, price: 340},
                    {length: 16, price: 390},
                ]
            },
            {
                id: 2,
                lace: "13x4 Hey BOB",
                lengths: [
                    {length: 12, price: 320},
                    {length: 14, price: 355},
                    {length: 16, price: 405},
                ]
            },
            {
                id: 3,
                lace: "13x4 large BOB",
                lengths: [
                    {length: 12, price: 340},
                    {length: 14, price: 375},
                    {length: 16, price: 425},
                ]
            },
        ]
    },
    {
        id: 11,
        type: "#613 Straight BOB",
        density: "250%",
        grade: "Naturel",
        styles: [
            {
                id: 1,// Unique identifier for this style
                lace: "4x4 BOB",
                lengths: [
                    {length: 12, price: 335},
                    {length: 14, price: 370},
                    {length: 16, price: 430},
                ]
            },
            {
                id: 2,
                lace: "13x4 Hey BOB",
                lengths: [
                    {length: 12, price: 350},
                    {length: 14, price: 385},
                    {length: 16, price: 445},
                ]
            },
            {
                id: 3,
                lace: "13x4 large BOB",
                lengths: [
                    {length: 12, price: 370},
                    {length: 14, price: 405},
                    {length: 16, price: 465},
                ]
            },
        ]
    },
    {
        id: 12,
        type: "Layer styling BOB",
        density: "180%",
        grade: "Naturel",
        styles: [
            {
                id: 1,// Unique identifier for this style
                lace: "4x4 BOB",
                lengths: [
                    {length: 12, price: 265},
                    {length: 14, price: 305},
                    {length: 16, price: 360},
                ]
            },
            {
                id: 2,
                lace: "5x5 BOB",
                lengths: [
                    {length: 12, price: 310},
                    {length: 14, price: 340},
                    {length: 16, price: 370},
                ]
            },
            {
                id: 3,
                lace: "13x4 large BOB",
                lengths: [
                    {length: 12, price: 299},
                    {length: 14, price: 329},
                    {length: 16, price: 369},
                ]
            },
        ]
    },
    {
        id: 13,
        type: "Layer styling BOB",
        density: "210%",
        grade: "Naturel",
        styles: [
            {
                id: 1,// Unique identifier for this style
                lace: "4x4 BOB",
                lengths: [
                    {length: 12, price: 285},
                    {length: 14, price: 335},
                    {length: 16, price: 400},
                ]
            },
            {
                id: 2,
                lace: "5x5 BOB",
                lengths: [
                    {length: 12, price: 330},
                    {length: 14, price: 370},
                    {length: 16, price: 410},
                ]
            },
            {
                id: 3,
                lace: "13x4 large BOB",
                lengths: [
                    {length: 12, price: 319},
                    {length: 14, price: 359},
                    {length: 16, price: 409},
                ]
            },
        ]
    },
    {
        id: 14,
        type: "Human Hair",
        density: "150%",
        grade: "Naturel",
        styles: [
            {
                id: 1,// Unique identifier for this style
                lace: "2x6",
                lengths: [
                    {length: 12, price: 180},
                    {length: 14, price: 210},
                    {length: 16, price: 230},
                    {length: 18, price: 275},
                    {length: 20, price: 315},
                    {length: 22, price: 395},
                    {length: 24, price: 490},
                    {length: 26, price: 600},
                    {length: 28, price: 770},
                    {length: 30, price: 940},
                    {length: 32, price: 1100},
                    {length: 34, price: 1310},
                ]
            },
            {
                id: 2,
                lace: "4x4",
                lengths: [
                    {length: 12, price: 185},
                    {length: 14, price: 215},
                    {length: 16, price: 235},
                    {length: 18, price: 280},
                    {length: 20, price: 325},
                    {length: 22, price: 405},
                    {length: 24, price: 500},
                    {length: 26, price: 610},
                    {length: 28, price: 780},
                    {length: 30, price: 950},
                    {length: 32, price: 1110},
                    {length: 34, price: 1320},
                ]
            },
            {
                id: 3,
                lace: "5x5",
                lengths: [
                    {length: 12, price: 200},
                    {length: 14, price: 240},
                    {length: 16, price: 260},
                    {length: 18, price: 305},
                    {length: 20, price: 350},
                    {length: 22, price: 440},
                    {length: 24, price: 535},
                    {length: 26, price: 645},
                    {length: 28, price: 815},
                    {length: 30, price: 995},
                    {length: 32, price: 1155},
                    {length: 34, price: 1365},
                ]
            },
            {
                id: 4,
                lace: "13x4 large",
                lengths: [
                    {length: 12, price: 205},
                    {length: 14, price: 245},
                    {length: 16, price: 265},
                    {length: 18, price: 310},
                    {length: 20, price: 355},
                    {length: 22, price: 465},
                    {length: 24, price: 560},
                    {length: 26, price: 670},
                    {length: 28, price: 840},
                    {length: 30, price: 1020},
                    {length: 32, price: 1180},
                    {length: 34, price: 1390},
                ]
            },
            {
                id: 5,
                lace: "13x6 Hey",
                lengths: [
                    {length: 12, price: 225},
                    {length: 14, price: 265},
                    {length: 16, price: 285},
                    {length: 18, price: 330},
                    {length: 20, price: 375},
                    {length: 22, price: 465},
                    {length: 24, price: 560},
                    {length: 26, price: 670},
                    {length: 28, price: 840},
                    {length: 30, price: 1020},
                    {length: 32, price: 1180},
                    {length: 34, price: 1390},
                ]
            },
            {
                id: 6,
                lace: "4x4 HD",
                lengths: [
                    {length: 12, price: 235},
                    {length: 14, price: 265},
                    {length: 16, price: 295},
                    {length: 18, price: 335},
                    {length: 20, price: 385},
                    {length: 22, price: 475},
                    {length: 24, price: 570},
                    {length: 26, price: 680},
                    {length: 28, price: 860},
                    {length: 30, price: 1010},
                    {length: 32, price: 1150},
                    {length: 34, price: 1360},
                ]
            },
            {
                id: 7,
                lace: "5x5 HD",
                lengths: [
                    {length: 12, price: 305},
                    {length: 14, price: 335},
                    {length: 16, price: 365},
                    {length: 18, price: 405},
                    {length: 20, price: 455},
                    {length: 22, price: 545},
                    {length: 24, price: 640},
                    {length: 26, price: 750},
                    {length: 28, price: 920},
                    {length: 30, price: 1070},
                    {length: 32, price: 1210},
                    {length: 34, price: 1420},
                ]
            },
            {
                id: 8,
                lace: "13x4 HD",
                lengths: [
                    {length: 12, price: 405},
                    {length: 14, price: 435},
                    {length: 16, price: 465},
                    {length: 18, price: 505},
                    {length: 20, price: 555},
                    {length: 22, price: 645},
                    {length: 24, price: 740},
                    {length: 26, price: 850},
                    {length: 28, price: 1030},
                    {length: 30, price: 1180},
                    {length: 32, price: 1320},
                    {length: 34, price: 1530},
                ]
            },
        ]
    },
    {
        id: 15,
        type: "Human Hair",
        density: "180%",
        grade: "Naturel",
        styles: [
            {
                id: 1,// Unique identifier for this style
                lace: "2x6",
                lengths: [
                    {length: 12, price: 200},
                    {length: 14, price: 235},
                    {length: 16, price: 260},
                    {length: 18, price: 310},
                    {length: 20, price: 355},
                    {length: 22, price: 445},
                    {length: 24, price: 555},
                    {length: 26, price: 670},
                    {length: 28, price: 865},
                    {length: 30, price: 1050},
                    {length: 32, price: 1230},
                    {length: 34, price: 1440},
                ]
            },
            {
                id: 2,
                lace: "4x4",
                lengths: [
                    {length: 12, price: 205},
                    {length: 14, price: 240},
                    {length: 16, price: 265},
                    {length: 18, price: 315},
                    {length: 20, price: 365},
                    {length: 22, price: 455},
                    {length: 24, price: 565},
                    {length: 26, price: 680},
                    {length: 28, price: 875},
                    {length: 30, price: 1060},
                    {length: 32, price: 1240},
                    {length: 34, price: 1450},
                ]
            },
            {
                id: 3,
                lace: "5x5",
                lengths: [
                    {length: 12, price: 220},
                    {length: 14, price: 265},
                    {length: 16, price: 290},
                    {length: 18, price: 340},
                    {length: 20, price: 390},
                    {length: 22, price: 490},
                    {length: 24, price: 600},
                    {length: 26, price: 715},
                    {length: 28, price: 910},
                    {length: 30, price: 1105},
                    {length: 32, price: 1310},
                    {length: 34, price: 1520},
                ]
            },
            {
                id: 4,
                lace: "13x4 large",
                lengths: [
                    {length: 12, price: 225},
                    {length: 14, price: 270},
                    {length: 16, price: 295},
                    {length: 18, price: 345},
                    {length: 20, price: 395},
                    {length: 22, price: 515},
                    {length: 24, price: 625},
                    {length: 26, price: 740},
                    {length: 28, price: 935},
                    {length: 30, price: 1130},
                    {length: 32, price: 1310},
                    {length: 34, price: 1520},
                ]
            },
            {
                id: 5,
                lace: "13x6 Hey",
                lengths: [
                    {length: 12, price: 245},
                    {length: 14, price: 290},
                    {length: 16, price: 315},
                    {length: 18, price: 365},
                    {length: 20, price: 415},
                    {length: 22, price: 515},
                    {length: 24, price: 625},
                    {length: 26, price: 740},
                    {length: 28, price: 935},
                    {length: 30, price: 1130},
                    {length: 32, price: 1310},
                    {length: 34, price: 1520},
                ]
            },
            {
                id: 6,
                lace: "4x4 HD",
                lengths: [
                    {length: 12, price: 255},
                    {length: 14, price: 290},
                    {length: 16, price: 325},
                    {length: 18, price: 370},
                    {length: 20, price: 425},
                    {length: 22, price: 525},
                    {length: 24, price: 635},
                    {length: 26, price: 750},
                    {length: 28, price: 955},
                    {length: 30, price: 1120},
                    {length: 32, price: 1280},
                    {length: 34, price: 1490},
                ]
            },
            {
                id: 7,
                lace: "5x5 HD",
                lengths: [
                    {length: 12, price: 325},
                    {length: 14, price: 360},
                    {length: 16, price: 395},
                    {length: 18, price: 440},
                    {length: 20, price: 495},
                    {length: 22, price: 595},
                    {length: 24, price: 705},
                    {length: 26, price: 820},
                    {length: 28, price: 1015},
                    {length: 30, price: 1180},
                    {length: 32, price: 1340},
                    {length: 34, price: 1550},
                ]
            },
            {
                id: 8,
                lace: "13x4 HD",
                lengths: [
                    {length: 12, price: 425},
                    {length: 14, price: 460},
                    {length: 16, price: 495},
                    {length: 18, price: 540},
                    {length: 20, price: 595},
                    {length: 22, price: 695},
                    {length: 24, price: 805},
                    {length: 26, price: 920},
                    {length: 28, price: 1125},
                    {length: 30, price: 1290},
                    {length: 32, price: 1450},
                    {length: 34, price: 1660},
                ]
            },
            {
                id: 9,
                lace: "360",
                lengths: [
                    {length: 12, price: 300},
                    {length: 14, price: 360},
                    {length: 16, price: 410},
                    {length: 18, price: 480},
                    {length: 20, price: 560},
                    {length: 22, price: 670},
                    {length: 24, price: 821},
                    {length: 26, price: 936},
                    {length: 28, price: 1041},
                    {length: 30, price: 1151},
                    {length: 32, price: 1310},
                ]
            },
        ]
    },
    {
        id: 16,
        type: "Human Hair",
        density: "150%",
        grade: "9A",
        styles: [
            {
                id: 1,// Unique identifier for this style
                lace: "2x6",
                lengths: [
                    {length: 10, price: 180},
                    {length: 12, price: 210},
                    {length: 14, price: 230},
                    {length: 16, price: 275},
                    {length: 18, price: 315},
                    {length: 20, price: 339},
                    {length: 22, price: 490},
                    {length: 24, price: 600},
                    {length: 26, price: 770},
                    {length: 28, price: 940},
                    {length: 30, price: 1100},
                    {length: 32, price: 1310},
                ]
            },
            {
                id: 2,
                lace: "4x4",
                lengths: [
                    {length: 10, price: 185},
                    {length: 12, price: 215},
                    {length: 14, price: 235},
                    {length: 16, price: 280},
                    {length: 18, price: 325},
                    {length: 20, price: 405},
                    {length: 22, price: 500},
                    {length: 24, price: 610},
                    {length: 26, price: 780},
                    {length: 28, price: 950},
                    {length: 30, price: 1110},
                    {length: 32, price: 1320},
                ]
            },
            {
                id: 3,
                lace: "5x5",
                lengths: [
                    {length: 10, price: 200},
                    {length: 12, price: 240},
                    {length: 14, price: 260},
                    {length: 16, price: 305},
                    {length: 18, price: 350},
                    {length: 20, price: 440},
                    {length: 22, price: 535},
                    {length: 24, price: 645},
                    {length: 26, price: 815},
                    {length: 28, price: 995},
                    {length: 30, price: 1155},
                    {length: 32, price: 1365},
                ]
            },
            {
                id: 4,
                lace: "13x4 large",
                lengths: [
                    {length: 10, price: 205},
                    {length: 12, price: 245},
                    {length: 14, price: 265},
                    {length: 16, price: 310},
                    {length: 18, price: 355},
                    {length: 20, price: 465},
                    {length: 22, price: 560},
                    {length: 24, price: 670},
                    {length: 26, price: 840},
                    {length: 28, price: 1020},
                    {length: 30, price: 1180},
                    {length: 32, price: 1390},
                ]
            },
            {
                id: 5,
                lace: "13x6 Hey",
                lengths: [
                    {length: 10, price: 225},
                    {length: 12, price: 265},
                    {length: 14, price: 285},
                    {length: 16, price: 330},
                    {length: 18, price: 375},
                    {length: 20, price: 465},
                    {length: 22, price: 560},
                    {length: 24, price: 670},
                    {length: 26, price: 840},
                    {length: 28, price: 1020},
                    {length: 30, price: 1180},
                    {length: 32, price: 1390},
                ]
            },
            {
                id: 6,
                lace: "4x4 HD",
                lengths: [
                    {length: 10, price: 235},
                    {length: 12, price: 265},
                    {length: 14, price: 295},
                    {length: 16, price: 335},
                    {length: 18, price: 385},
                    {length: 20, price: 475},
                    {length: 22, price: 570},
                    {length: 24, price: 680},
                    {length: 26, price: 860},
                    {length: 28, price: 1010},
                    {length: 30, price: 1150},
                    {length: 32, price: 1360},
                ]
            },
            {
                id: 7,
                lace: "5x5 HD",
                lengths: [
                    {length: 10, price: 305},
                    {length: 12, price: 335},
                    {length: 14, price: 365},
                    {length: 16, price: 405},
                    {length: 18, price: 455},
                    {length: 20, price: 545},
                    {length: 22, price: 640},
                    {length: 24, price: 750},
                    {length: 26, price: 920},
                    {length: 28, price: 1070},
                    {length: 30, price: 1210},
                    {length: 32, price: 1420},
                ]
            },
            {
                id: 8,
                lace: "13x4 HD",
                lengths: [
                    {length: 10, price: 405},
                    {length: 12, price: 435},
                    {length: 14, price: 465},
                    {length: 16, price: 505},
                    {length: 18, price: 555},
                    {length: 20, price: 645},
                    {length: 22, price: 740},
                    {length: 24, price: 850},
                    {length: 26, price: 1030},
                    {length: 28, price: 1180},
                    {length: 30, price: 1320},
                    {length: 32, price: 1530},
                ]
            },
            {
                id: 9,
                lace: "No lace Bang",
                lengths: [
                    {length: 10, price: 130},
                    {length: 12, price: 155},
                    {length: 14, price: 170},
                    {length: 16, price: 205},
                    {length: 18, price: 245},
                    {length: 20, price: 325},
                    {length: 22, price: 426},
                    {length: 24, price: 511},
                    {length: 26, price: 621},
                    {length: 28, price: 721},
                    {length: 30, price: 861},
                ]
            },
            {
                id: 10,
                lace: "V Part Wig",
                lengths: [
                    {length: 10, price: 160},
                    {length: 12, price: 200},
                    {length: 14, price: 225},
                    {length: 16, price: 275},
                    {length: 18, price: 320},
                    {length: 20, price: 420},
                    {length: 22, price: 506},
                    {length: 24, price: 591},
                    {length: 26, price: 701},
                    {length: 28, price: 851},
                    {length: 30, price: 1011},
                ]
            },
        ]
    },
    {
        id: 17,
        type: "Human Hair",
        density: "180%",
        grade: "9A",
        styles: [
            {
                id: 1,// Unique identifier for this style
                lace: "2x6",
                lengths: [
                    {length: 10, price: 200},
                    {length: 12, price: 235},
                    {length: 14, price: 260},
                    {length: 16, price: 310},
                    {length: 18, price: 355},
                    {length: 20, price: 445},
                    {length: 22, price: 555},
                    {length: 24, price: 670},
                    {length: 26, price: 865},
                    {length: 28, price: 1050},
                    {length: 30, price: 1230},
                    {length: 32, price: 1440},
                ]
            },
            {
                id: 2,
                lace: "4x4",
                lengths: [
                    {length: 10, price: 205},
                    {length: 12, price: 240},
                    {length: 14, price: 265},
                    {length: 16, price: 315},
                    {length: 18, price: 365},
                    {length: 20, price: 455},
                    {length: 22, price: 565},
                    {length: 24, price: 680},
                    {length: 26, price: 875},
                    {length: 28, price: 1060},
                    {length: 30, price: 1240},
                    {length: 32, price: 1450},
                ]
            },
            {
                id: 3,
                lace: "5x5",
                lengths: [
                    {length: 10, price: 220},
                    {length: 12, price: 265},
                    {length: 14, price: 290},
                    {length: 16, price: 340},
                    {length: 18, price: 390},
                    {length: 20, price: 490},
                    {length: 22, price: 600},
                    {length: 24, price: 715},
                    {length: 26, price: 910},
                    {length: 28, price: 1105},
                    {length: 30, price: 1285},
                    {length: 32, price: 1495},
                ]
            },
            {
                id: 4,
                lace: "13x4 large",
                lengths: [
                    {length: 10, price: 225},
                    {length: 12, price: 270},
                    {length: 14, price: 295},
                    {length: 16, price: 345},
                    {length: 18, price: 395},
                    {length: 20, price: 515},
                    {length: 22, price: 625},
                    {length: 24, price: 740},
                    {length: 26, price: 935},
                    {length: 28, price: 1130},
                    {length: 30, price: 1310},
                    {length: 32, price: 1520},
                ]
            },
            {
                id: 5,
                lace: "13x6 Hey",
                lengths: [
                    {length: 10, price: 245},
                    {length: 12, price: 290},
                    {length: 14, price: 315},
                    {length: 16, price: 365},
                    {length: 18, price: 415},
                    {length: 20, price: 515},
                    {length: 22, price: 625},
                    {length: 24, price: 740},
                    {length: 26, price: 935},
                    {length: 28, price: 1130},
                    {length: 30, price: 1310},
                    {length: 32, price: 1520},
                ]
            },
            {
                id: 6,
                lace: "4x4 HD",
                lengths: [
                    {length: 10, price: 255},
                    {length: 12, price: 290},
                    {length: 14, price: 325},
                    {length: 16, price: 370},
                    {length: 18, price: 425},
                    {length: 20, price: 525},
                    {length: 22, price: 635},
                    {length: 24, price: 750},
                    {length: 26, price: 955},
                    {length: 28, price: 1120},
                    {length: 30, price: 1280},
                    {length: 32, price: 1490},
                ]
            },
            {
                id: 7,
                lace: "5x5 HD",
                lengths: [
                    {length: 10, price: 325},
                    {length: 12, price: 360},
                    {length: 14, price: 395},
                    {length: 16, price: 440},
                    {length: 18, price: 495},
                    {length: 20, price: 595},
                    {length: 22, price: 705},
                    {length: 24, price: 820},
                    {length: 26, price: 1015},
                    {length: 28, price: 1180},
                    {length: 30, price: 1340},
                    {length: 32, price: 1550},
                ]
            },
            {
                id: 8,
                lace: "13x4 HD",
                lengths: [
                    {length: 10, price: 425},
                    {length: 12, price: 460},
                    {length: 14, price: 495},
                    {length: 16, price: 540},
                    {length: 18, price: 595},
                    {length: 20, price: 695},
                    {length: 22, price: 805},
                    {length: 24, price: 920},
                    {length: 26, price: 1125},
                    {length: 28, price: 1290},
                    {length: 30, price: 1450},
                    {length: 32, price: 1660},
                ]
            },
            {
                id: 9,
                lace: "No lace Bang",
                lengths: [
                    {length: 10, price: 150},
                    {length: 12, price: 180},
                    {length: 14, price: 200},
                    {length: 16, price: 240},
                    {length: 18, price: 285},
                    {length: 20, price: 375},
                    {length: 22, price: 491},
                    {length: 24, price: 581},
                    {length: 26, price: 716},
                    {length: 28, price: 831},
                    {length: 30, price: 991},
                ]
            },
            {
                id: 10,
                lace: "V Part Wig",
                lengths: [
                    {length: 10, price: 180},
                    {length: 12, price: 225},
                    {length: 14, price: 255},
                    {length: 16, price: 310},
                    {length: 18, price: 360},
                    {length: 20, price: 470},
                    {length: 22, price: 571},
                    {length: 24, price: 661},
                    {length: 26, price: 796},
                    {length: 28, price: 961},
                    {length: 30, price: 1141},
                ]
            },
            {
                id: 11,
                lace: "360",
                lengths: [
                    {length: 10, price: 300},
                    {length: 12, price: 360},
                    {length: 14, price: 410},
                    {length: 16, price: 480},
                    {length: 18, price: 560},
                    {length: 20, price: 670},
                    {length: 22, price: 821},
                    {length: 24, price: 936},
                    {length: 26, price: 1041},
                    {length: 28, price: 1151},
                    {length: 30, price: 1310},
                ]
            },
        ]
    },
    {
        id: 18,
        type: "Human Hair",
        density: "210%",
        grade: "9A",
        styles: [
            {
                id: 1,// Unique identifier for this style
                lace: "2x6",
                lengths: [
                    {length: 10, price: 220},
                    {length: 12, price: 260},
                    {length: 14, price: 290},
                    {length: 16, price: 345},
                    {length: 18, price: 395},
                    {length: 20, price: 485},
                    {length: 22, price: 590},
                    {length: 24, price: 720},
                    {length: 26, price: 910},
                    {length: 28, price: 1140},
                    {length: 30, price: 1320},
                    {length: 32, price: 1570},
                ]
            },
            {
                id: 2,
                lace: "4x4",
                lengths: [
                    {length: 10, price: 255},
                    {length: 12, price: 265},
                    {length: 14, price: 295},
                    {length: 16, price: 350},
                    {length: 18, price: 405},
                    {length: 20, price: 495},
                    {length: 22, price: 600},
                    {length: 24, price: 730},
                    {length: 26, price: 920},
                    {length: 28, price: 1150},
                    {length: 30, price: 1330},
                    {length: 32, price: 1580},
                ]
            },
            {
                id: 3,
                lace: "5x5",
                lengths: [
                    {length: 10, price: 240},
                    {length: 12, price: 290},
                    {length: 14, price: 320},
                    {length: 16, price: 375},
                    {length: 18, price: 430},
                    {length: 20, price: 530},
                    {length: 22, price: 635},
                    {length: 24, price: 765},
                    {length: 26, price: 955},
                    {length: 28, price: 1195},
                    {length: 30, price: 1375},
                    {length: 32, price: 1625},
                ]
            },
            {
                id: 4,
                lace: "13x4 large",
                lengths: [
                    {length: 10, price: 245},
                    {length: 12, price: 295},
                    {length: 14, price: 325},
                    {length: 16, price: 380},
                    {length: 18, price: 435},
                    {length: 20, price: 555},
                    {length: 22, price: 660},
                    {length: 24, price: 790},
                    {length: 26, price: 980},
                    {length: 28, price: 1220},
                    {length: 30, price: 1400},
                    {length: 32, price: 1650},
                ]
            },
            {
                id: 5,
                lace: "13x6 Hey",
                lengths: [
                    {length: 10, price: 265},
                    {length: 12, price: 315},
                    {length: 14, price: 345},
                    {length: 16, price: 400},
                    {length: 18, price: 455},
                    {length: 20, price: 555},
                    {length: 22, price: 660},
                    {length: 24, price: 790},
                    {length: 26, price: 980},
                    {length: 28, price: 1220},
                    {length: 30, price: 1400},
                    {length: 32, price: 1650},
                ]
            },
            {
                id: 6,
                lace: "4x4 HD",
                lengths: [
                    {length: 10, price: 275},
                    {length: 12, price: 315},
                    {length: 14, price: 355},
                    {length: 16, price: 405},
                    {length: 18, price: 465},
                    {length: 20, price: 565},
                    {length: 22, price: 670},
                    {length: 24, price: 800},
                    {length: 26, price: 1000},
                    {length: 28, price: 1210},
                    {length: 30, price: 1370},
                    {length: 32, price: 1620},
                ]
            },
            {
                id: 7,
                lace: "5x5 HD",
                lengths: [
                    {length: 10, price: 345},
                    {length: 12, price: 385},
                    {length: 14, price: 425},
                    {length: 16, price: 475},
                    {length: 18, price: 535},
                    {length: 20, price: 635},
                    {length: 22, price: 740},
                    {length: 24, price: 870},
                    {length: 26, price: 1060},
                    {length: 28, price: 1270},
                    {length: 30, price: 1430},
                    {length: 32, price: 1680},
                ]
            },
            {
                id: 8,
                lace: "13x4 HD",
                lengths: [
                    {length: 10, price: 445},
                    {length: 12, price: 485},
                    {length: 14, price: 525},
                    {length: 16, price: 575},
                    {length: 18, price: 635},
                    {length: 20, price: 735},
                    {length: 22, price: 840},
                    {length: 24, price: 970},
                    {length: 26, price: 1170},
                    {length: 28, price: 1138},
                    {length: 30, price: 1540},
                    {length: 32, price: 1790},
                ]
            },
            {
                id: 9,
                lace: "No lace Bang",
                lengths: [
                    {length: 10, price: 170},
                    {length: 12, price: 205},
                    {length: 14, price: 230},
                    {length: 16, price: 275},
                    {length: 18, price: 325},
                    {length: 20, price: 415},
                    {length: 22, price: 526},
                    {length: 24, price: 631},
                    {length: 26, price: 761},
                    {length: 28, price: 921},
                    {length: 30, price: 1081},
                ]
            },
            {
                id: 10,
                lace: "V Part Wig",
                lengths: [
                    {length: 10, price: 200},
                    {length: 12, price: 250},
                    {length: 14, price: 285},
                    {length: 16, price: 345},
                    {length: 18, price: 400},
                    {length: 20, price: 510},
                    {length: 22, price: 606},
                    {length: 24, price: 711},
                    {length: 26, price: 841},
                    {length: 28, price: 1051},
                    {length: 30, price: 1231},
                ]
            },
        ]
    },
    {
        id: 19,
        type: "Human Hair",
        density: "250%",
        grade: "9A",
        styles: [
            {
                id: 1,// Unique identifier for this style
                lace: "2x6",
                lengths: [
                    {length: 10, price: 240},
                    {length: 12, price: 285},
                    {length: 14, price: 320},
                    {length: 16, price: 380},
                    {length: 18, price: 435},
                    {length: 20, price: 530},
                    {length: 22, price: 640},
                    {length: 24, price: 780},
                    {length: 26, price: 985},
                    {length: 28, price: 1240},
                    {length: 30, price: 1430},
                    {length: 32, price: 1700},
                ]
            },
            {
                id: 2,
                lace: "4x4",
                lengths: [
                    {length: 10, price: 245},
                    {length: 12, price: 290},
                    {length: 14, price: 325},
                    {length: 16, price: 385},
                    {length: 18, price: 445},
                    {length: 20, price: 540},
                    {length: 22, price: 650},
                    {length: 24, price: 790},
                    {length: 26, price: 995},
                    {length: 28, price: 1250},
                    {length: 30, price: 1440},
                    {length: 32, price: 1710},
                ]
            },
            {
                id: 3,
                lace: "5x5",
                lengths: [
                    {length: 10, price: 260},
                    {length: 12, price: 315},
                    {length: 14, price: 350},
                    {length: 16, price: 410},
                    {length: 18, price: 470},
                    {length: 20, price: 575},
                    {length: 22, price: 685},
                    {length: 24, price: 825},
                    {length: 26, price: 1030},
                    {length: 28, price: 1295},
                    {length: 30, price: 1485},
                    {length: 32, price: 1755},
                ]
            },
            {
                id: 4,
                lace: "13x4 large",
                lengths: [
                    {length: 10, price: 265},
                    {length: 12, price: 320},
                    {length: 14, price: 355},
                    {length: 16, price: 415},
                    {length: 18, price: 475},
                    {length: 20, price: 600},
                    {length: 22, price: 710},
                    {length: 24, price: 850},
                    {length: 26, price: 1055},
                    {length: 28, price: 1320},
                    {length: 30, price: 1510},
                    {length: 32, price: 1780},
                ]
            },
            {
                id: 5,
                lace: "13x6 Hey",
                lengths: [
                    {length: 10, price: 285},
                    {length: 12, price: 340},
                    {length: 14, price: 375},
                    {length: 16, price: 435},
                    {length: 18, price: 495},
                    {length: 20, price: 600},
                    {length: 22, price: 710},
                    {length: 24, price: 850},
                    {length: 26, price: 1055},
                    {length: 28, price: 1320},
                    {length: 30, price: 1510},
                    {length: 32, price: 1780},
                ]
            },
            {
                id: 6,
                lace: "4x4 HD",
                lengths: [
                    {length: 10, price: 295},
                    {length: 12, price: 340},
                    {length: 14, price: 385},
                    {length: 16, price: 440},
                    {length: 18, price: 505},
                    {length: 20, price: 610},
                    {length: 22, price: 720},
                    {length: 24, price: 860},
                    {length: 26, price: 1075},
                    {length: 28, price: 1310},
                    {length: 30, price: 1480},
                    {length: 32, price: 1750},
                ]
            },
            {
                id: 7,
                lace: "5x5 HD",
                lengths: [
                    {length: 10, price: 365},
                    {length: 12, price: 410},
                    {length: 14, price: 455},
                    {length: 16, price: 510},
                    {length: 18, price: 575},
                    {length: 20, price: 680},
                    {length: 22, price: 790},
                    {length: 24, price: 930},
                    {length: 26, price: 1135},
                    {length: 28, price: 1370},
                    {length: 30, price: 1540},
                    {length: 32, price: 1810},
                ]
            },
            {
                id: 8,
                lace: "13x4 HD",
                lengths: [
                    {length: 10, price: 465},
                    {length: 12, price: 510},
                    {length: 14, price: 555},
                    {length: 16, price: 610},
                    {length: 18, price: 675},
                    {length: 20, price: 780},
                    {length: 22, price: 890},
                    {length: 24, price: 1045},
                    {length: 26, price: 1245},
                    {length: 28, price: 1480},
                    {length: 30, price: 1650},
                    {length: 32, price: 1920},
                ]
            },
        ]
    },
    {
        id: 20,
        type: "Human Hair",
        density: "150%",
        grade: "10A",
        styles: [
            {
                id: 1,// Unique identifier for this style
                lace: "T part",
                lengths: [
                    {length: 10, price: 190},
                    {length: 12, price: 215},
                    {length: 14, price: 250},
                    {length: 16, price: 315},
                    {length: 18, price: 375},
                    {length: 20, price: 455},
                    {length: 22, price: 580},
                    {length: 24, price: 705},
                    {length: 26, price: 835},
                    {length: 28, price: 1010},
                    {length: 30, price: 1145},
                    {length: 32, price: 1415},
                ]
            },
            {
                id: 2,
                lace: "4x4",
                lengths: [
                    {length: 10, price: 195},
                    {length: 12, price: 220},
                    {length: 14, price: 260},
                    {length: 16, price: 325},
                    {length: 18, price: 385},
                    {length: 20, price: 475},
                    {length: 22, price: 595},
                    {length: 24, price: 700},
                    {length: 26, price: 850},
                    {length: 28, price: 1035},
                    {length: 30, price: 1170},
                    {length: 32, price: 1450},
                ]
            },
            {
                id: 3,
                lace: "5x5",
                lengths: [
                    {length: 10, price: 240},
                    {length: 12, price: 265},
                    {length: 14, price: 305},
                    {length: 16, price: 380},
                    {length: 18, price: 450},
                    {length: 20, price: 510},
                    {length: 22, price: 650},
                    {length: 24, price: 755},
                    {length: 26, price: 890},
                    {length: 28, price: 1075},
                    {length: 30, price: 1220},
                    {length: 32, price: 1495},
                ]
            },
            {
                id: 4,
                lace: "13x4",
                lengths: [
                    {length: 10, price: 229},
                    {length: 12, price: 254},
                    {length: 14, price: 289},
                    {length: 16, price: 350},
                    {length: 18, price: 400},
                    {length: 20, price: 480},
                    {length: 22, price: 610},
                    {length: 24, price: 725},
                    {length: 26, price: 870},
                    {length: 28, price: 1055},
                    {length: 30, price: 1205},
                    {length: 32, price: 1505},
                ]
            },
            {
                id: 5,
                lace: "13x6",
                lengths: [
                    {length: 10, price: 360},
                    {length: 12, price: 385},
                    {length: 14, price: 430},
                    {length: 16, price: 490},
                    {length: 18, price: 550},
                    {length: 20, price: 610},
                    {length: 22, price: 750},
                    {length: 24, price: 855},
                    {length: 26, price: 990},
                    {length: 28, price: 1155},
                    {length: 30, price: 1300},
                    {length: 32, price: 1595},
                ]
            },
            {
                id: 6,
                lace: "No lace bang",
                lengths: [
                    {length: 10, price: 175},
                    {length: 12, price: 190},
                    {length: 14, price: 230},
                    {length: 16, price: 265},
                    {length: 18, price: 320},
                    {length: 20, price: 427},
                    {length: 22, price: 525},
                    {length: 24, price: 665},
                    {length: 26, price: 760},
                    {length: 28, price: 925},
                    {length: 30, price: 1085},
                ]
            },
        ]
    },
    {
        id: 21,
        type: "Human Hair",
        density: "180%",
        grade: "10A",
        styles: [
            {
                id: 1,// Unique identifier for this style
                lace: "T part",
                lengths: [
                    {length: 10, price: 210},
                    {length: 12, price: 240},
                    {length: 14, price: 280},
                    {length: 16, price: 350},
                    {length: 18, price: 415},
                    {length: 20, price: 500},
                    {length: 22, price: 630},
                    {length: 24, price: 765},
                    {length: 26, price: 905},
                    {length: 28, price: 1110},
                    {length: 30, price: 1255},
                    {length: 32, price: 1535},
                ]
            },
            {
                id: 2,
                lace: "4x4",
                lengths: [
                    {length: 10, price: 215},
                    {length: 12, price: 245},
                    {length: 14, price: 290},
                    {length: 16, price: 360},
                    {length: 18, price: 425},
                    {length: 20, price: 520},
                    {length: 22, price: 645},
                    {length: 24, price: 760},
                    {length: 26, price: 920},
                    {length: 28, price: 1135},
                    {length: 30, price: 1280},
                    {length: 32, price: 1570},
                ]
            },
            {
                id: 3,
                lace: "5x5",
                lengths: [
                    {length: 10, price: 260},
                    {length: 12, price: 290},
                    {length: 14, price: 335},
                    {length: 16, price: 415},
                    {length: 18, price: 490},
                    {length: 20, price: 555},
                    {length: 22, price: 700},
                    {length: 24, price: 815},
                    {length: 26, price: 960},
                    {length: 28, price: 1175},
                    {length: 30, price: 1330},
                    {length: 32, price: 1615},
                ]
            },
            {
                id: 4,
                lace: "13x4",
                lengths: [
                    {length: 10, price: 249},
                    {length: 12, price: 279},
                    {length: 14, price: 319},
                    {length: 16, price: 385},
                    {length: 18, price: 440},
                    {length: 20, price: 525},
                    {length: 22, price: 660},
                    {length: 24, price: 785},
                    {length: 26, price: 940},
                    {length: 28, price: 1155},
                    {length: 30, price: 1315},
                    {length: 32, price: 1625},
                ]
            },
            {
                id: 5,
                lace: "13x6",
                lengths: [
                    {length: 10, price: 380},
                    {length: 12, price: 410},
                    {length: 14, price: 460},
                    {length: 16, price: 525},
                    {length: 18, price: 590},
                    {length: 20, price: 655},
                    {length: 22, price: 800},
                    {length: 24, price: 915},
                    {length: 26, price: 1060},
                    {length: 28, price: 1255},
                    {length: 30, price: 1410},
                    {length: 32, price: 1715},
                ]
            },
            {
                id: 6,
                lace: "No lace bang",
                lengths: [
                    {length: 10, price: 195},
                    {length: 12, price: 215},
                    {length: 14, price: 260},
                    {length: 16, price: 300},
                    {length: 18, price: 360},
                    {length: 20, price: 472},
                    {length: 22, price: 575},
                    {length: 24, price: 725},
                    {length: 26, price: 830},
                    {length: 28, price: 1025},
                    {length: 30, price: 1195},
                ]
            },
        ]
    },
    {
        id: 22,
        type: "Human Hair",
        density: "210%",
        grade: "10A",
        styles: [
            {
                id: 1,// Unique identifier for this style
                lace: "T part",
                lengths: [
                    {length: 10, price: 230},
                    {length: 12, price: 265},
                    {length: 14, price: 310},
                    {length: 16, price: 385},
                    {length: 18, price: 455},
                    {length: 20, price: 545},
                    {length: 22, price: 680},
                    {length: 24, price: 825},
                    {length: 26, price: 975},
                    {length: 28, price: 1210},
                    {length: 30, price: 1365},
                    {length: 32, price: 1655},
                ]
            },
            {
                id: 2,
                lace: "4x4",
                lengths: [
                    {length: 10, price: 235},
                    {length: 12, price: 270},
                    {length: 14, price: 320},
                    {length: 16, price: 395},
                    {length: 18, price: 465},
                    {length: 20, price: 565},
                    {length: 22, price: 695},
                    {length: 24, price: 820},
                    {length: 26, price: 990},
                    {length: 28, price: 1235},
                    {length: 30, price: 1390},
                    {length: 32, price: 1690},
                ]
            },
            {
                id: 3,
                lace: "5x5",
                lengths: [
                    {length: 10, price: 280},
                    {length: 12, price: 315},
                    {length: 14, price: 365},
                    {length: 16, price: 450},
                    {length: 18, price: 530},
                    {length: 20, price: 600},
                    {length: 22, price: 750},
                    {length: 24, price: 875},
                    {length: 26, price: 1030},
                    {length: 28, price: 1275},
                    {length: 30, price: 1440},
                    {length: 32, price: 1735},
                ]
            },
            {
                id: 4,
                lace: "13x4",
                lengths: [
                    {length: 10, price: 269},
                    {length: 12, price: 304},
                    {length: 14, price: 349},
                    {length: 16, price: 420},
                    {length: 18, price: 480},
                    {length: 20, price: 570},
                    {length: 22, price: 710},
                    {length: 24, price: 845},
                    {length: 26, price: 1010},
                    {length: 28, price: 1255},
                    {length: 30, price: 1425},
                    {length: 32, price: 1745},
                ]
            },
            {
                id: 5,
                lace: "13x6",
                lengths: [
                    {length: 10, price: 400},
                    {length: 12, price: 435},
                    {length: 14, price: 490},
                    {length: 16, price: 560},
                    {length: 18, price: 630},
                    {length: 20, price: 700},
                    {length: 22, price: 850},
                    {length: 24, price: 975},
                    {length: 26, price: 1130},
                    {length: 28, price: 1355},
                    {length: 30, price: 1520},
                    {length: 32, price: 1835},
                ]
            },
            {
                id: 6,
                lace: "No lace bang",
                lengths: [
                    {length: 10, price: 215},
                    {length: 12, price: 240},
                    {length: 14, price: 290},
                    {length: 16, price: 335},
                    {length: 18, price: 400},
                    {length: 20, price: 517},
                    {length: 22, price: 625},
                    {length: 24, price: 785},
                    {length: 26, price: 900},
                    {length: 28, price: 1125},
                    {length: 30, price: 1305},
                ]
            },
        ]
    },
    {
        id: 23,
        type: "Human Hair",
        density: "250%",
        grade: "10A",
        styles: [
            {
                id: 1,// Unique identifier for this style
                lace: "T part",
                lengths: [
                    {length: 10, price: 250},
                    {length: 12, price: 290},
                    {length: 14, price: 340},
                    {length: 16, price: 420},
                    {length: 18, price: 495},
                    {length: 20, price: 590},
                    {length: 22, price: 730},
                    {length: 24, price: 885},
                    {length: 26, price: 1045},
                    {length: 28, price: 1345},
                    {length: 30, price: 1475},
                    {length: 32, price: 1775},
                ]
            },
            {
                id: 2,
                lace: "4x4",
                lengths: [
                    {length: 10, price: 255},
                    {length: 12, price: 295},
                    {length: 14, price: 350},
                    {length: 16, price: 430},
                    {length: 18, price: 505},
                    {length: 20, price: 610},
                    {length: 22, price: 745},
                    {length: 24, price: 880},
                    {length: 26, price: 1060},
                    {length: 28, price: 1335},
                    {length: 30, price: 1500},
                    {length: 32, price: 1810},
                ]
            },
            {
                id: 3,
                lace: "5x5",
                lengths: [
                    {length: 10, price: 300},
                    {length: 12, price: 340},
                    {length: 14, price: 395},
                    {length: 16, price: 485},
                    {length: 18, price: 570},
                    {length: 20, price: 645},
                    {length: 22, price: 800},
                    {length: 24, price: 935},
                    {length: 26, price: 1100},
                    {length: 28, price: 1375},
                    {length: 30, price: 1550},
                    {length: 32, price: 1855},
                ]
            },
            {
                id: 4,
                lace: "13x4",
                lengths: [
                    {length: 10, price: 289},
                    {length: 12, price: 329},
                    {length: 14, price: 379},
                    {length: 16, price: 455},
                    {length: 18, price: 520},
                    {length: 20, price: 615},
                    {length: 22, price: 760},
                    {length: 24, price: 905},
                    {length: 26, price: 1080},
                    {length: 28, price: 1355},
                    {length: 30, price: 1535},
                    {length: 32, price: 1865},
                ]
            },
            {
                id: 5,
                lace: "13x6",
                lengths: [
                    {length: 10, price: 420},
                    {length: 12, price: 460},
                    {length: 14, price: 520},
                    {length: 16, price: 595},
                    {length: 18, price: 670},
                    {length: 20, price: 745},
                    {length: 22, price: 900},
                    {length: 24, price: 1035},
                    {length: 26, price: 1200},
                    {length: 28, price: 1455},
                    {length: 30, price: 1630},
                    {length: 32, price: 1955},
                ]
            },
            {
                id: 6,
                lace: "No lace bang",
                lengths: [
                    {length: 10, price: 235},
                    {length: 12, price: 265},
                    {length: 14, price: 320},
                    {length: 16, price: 370},
                    {length: 18, price: 440},
                    {length: 20, price: 562},
                    {length: 22, price: 675},
                    {length: 24, price: 845},
                    {length: 26, price: 970},
                    {length: 28, price: 1225},
                    {length: 30, price: 1415},
                ]
            },
        ]
    },
    {
        id: 24,
        type: "#613 Straight",
        density: "150%",
        grade: "10A",
        styles: [
            {
                id: 1,// Unique identifier for this style
                lace: "T part",
                lengths: [
                    {length: 10, price: 269},
                    {length: 12, price: 290},
                    {length: 14, price: 335},
                    {length: 16, price: 445},
                    {length: 18, price: 555},
                    {length: 20, price: 650},
                    {length: 22, price: 815},
                    {length: 24, price: 925},
                    {length: 26, price: 1065},
                    {length: 28, price: 1205},
                    {length: 30, price: 1340},
                ]
            },
            {
                id: 2,
                lace: "4x4",
                lengths: [
                    {length: 10, price: 395},
                    {length: 12, price: 440},
                    {length: 14, price: 515},
                    {length: 16, price: 605},
                    {length: 18, price: 680},
                    {length: 20, price: 775},
                    {length: 22, price: 930},
                    {length: 24, price: 1015},
                    {length: 26, price: 1180},
                    {length: 28, price: 1330},
                    {length: 30, price: 1545},
                ]
            },
            {
                id: 3,
                lace: "13x4",
                lengths: [
                    {length: 10, price: 429},
                    {length: 12, price: 475},
                    {length: 14, price: 550},
                    {length: 16, price: 640},
                    {length: 18, price: 715},
                    {length: 20, price: 810},
                    {length: 22, price: 965},
                    {length: 24, price: 1060},
                    {length: 26, price: 1225},
                    {length: 28, price: 1375},
                    {length: 30, price: 1590},
                ]
            },
            {
                id: 4,
                lace: "13x6",
                lengths: [
                    {length: 10, price: 520},
                    {length: 12, price: 565},
                    {length: 14, price: 640},
                    {length: 16, price: 730},
                    {length: 18, price: 805},
                    {length: 20, price: 900},
                    {length: 22, price: 1055},
                    {length: 24, price: 1150},
                    {length: 26, price: 1315},
                    {length: 28, price: 1465},
                    {length: 30, price: 1680},
                ]
            },
            {
                id: 5,
                lace: "No lace bang",
                lengths: [
                    {length: 10, price: 230},
                    {length: 12, price: 250},
                    {length: 14, price: 305},
                    {length: 16, price: 405},
                    {length: 18, price: 515},
                    {length: 20, price: 610},
                    {length: 22, price: 775},
                    {length: 24, price: 885},
                    {length: 26, price: 1025},
                    {length: 28, price: 1175},
                    {length: 30, price: 1300},
                ]
            },
        ]
    },
    {
        id: 25,
        type: "#613 Straight",
        density: "180%",
        grade: "10A",
        styles: [
            {
                id: 1,// Unique identifier for this style
                lace: "T part",
                lengths: [
                    {length: 10, price: 299},
                    {length: 12, price: 320},
                    {length: 14, price: 375},
                    {length: 16, price: 505},
                    {length: 18, price: 625},
                    {length: 20, price: 735},
                    {length: 22, price: 905},
                    {length: 24, price: 1015},
                    {length: 26, price: 1175},
                    {length: 28, price: 1325},
                    {length: 30, price: 1460},
                ]
            },
            {
                id: 2,
                lace: "4x4",
                lengths: [
                    {length: 10, price: 425},
                    {length: 12, price: 470},
                    {length: 14, price: 555},
                    {length: 16, price: 665},
                    {length: 18, price: 750},
                    {length: 20, price: 860},
                    {length: 22, price: 1020},
                    {length: 24, price: 1105},
                    {length: 26, price: 1290},
                    {length: 28, price: 1450},
                    {length: 30, price: 1665},
                ]
            },
            {
                id: 3,
                lace: "13x4",
                lengths: [
                    {length: 10, price: 459},
                    {length: 12, price: 505},
                    {length: 14, price: 590},
                    {length: 16, price: 700},
                    {length: 18, price: 785},
                    {length: 20, price: 895},
                    {length: 22, price: 1055},
                    {length: 24, price: 1150},
                    {length: 26, price: 1335},
                    {length: 28, price: 1495},
                    {length: 30, price: 1710},
                ]
            },
            {
                id: 4,
                lace: "13x6",
                lengths: [
                    {length: 10, price: 550},
                    {length: 12, price: 595},
                    {length: 14, price: 680},
                    {length: 16, price: 790},
                    {length: 18, price: 875},
                    {length: 20, price: 985},
                    {length: 22, price: 1145},
                    {length: 24, price: 1240},
                    {length: 26, price: 1425},
                    {length: 28, price: 1585},
                    {length: 30, price: 1800},
                ]
            },
            {
                id: 5,
                lace: "No lace bang",
                lengths: [
                    {length: 10, price: 260},
                    {length: 12, price: 280},
                    {length: 14, price: 345},
                    {length: 16, price: 465},
                    {length: 18, price: 585},
                    {length: 20, price: 695},
                    {length: 22, price: 865},
                    {length: 24, price: 975},
                    {length: 26, price: 1135},
                    {length: 28, price: 1295},
                    {length: 30, price: 1420},
                ]
            },
        ]
    },
    {
        id: 26,
        type: "#613 Straight",
        density: "210%",
        grade: "10A",
        styles: [
            {
                id: 1,// Unique identifier for this style
                lace: "T part",
                lengths: [
                    {length: 10, price: 329},
                    {length: 12, price: 350},
                    {length: 14, price: 415},
                    {length: 16, price: 565},
                    {length: 18, price: 695},
                    {length: 20, price: 820},
                    {length: 22, price: 995},
                    {length: 24, price: 1105},
                    {length: 26, price: 1285},
                    {length: 28, price: 1445},
                    {length: 30, price: 1580},
                ]
            },
            {
                id: 2,
                lace: "4x4",
                lengths: [
                    {length: 10, price: 455},
                    {length: 12, price: 500},
                    {length: 14, price: 595},
                    {length: 16, price: 725},
                    {length: 18, price: 820},
                    {length: 20, price: 945},
                    {length: 22, price: 1110},
                    {length: 24, price: 1195},
                    {length: 26, price: 1400},
                    {length: 28, price: 1570},
                    {length: 30, price: 1785},
                ]
            },
            {
                id: 3,
                lace: "13x4",
                lengths: [
                    {length: 10, price: 489},
                    {length: 12, price: 535},
                    {length: 14, price: 630},
                    {length: 16, price: 760},
                    {length: 18, price: 855},
                    {length: 20, price: 980},
                    {length: 22, price: 1145},
                    {length: 24, price: 1245},
                    {length: 26, price: 1445},
                    {length: 28, price: 1615},
                    {length: 30, price: 1830},
                ]
            },
            {
                id: 4,
                lace: "13x6",
                lengths: [
                    {length: 10, price: 580},
                    {length: 12, price: 625},
                    {length: 14, price: 720},
                    {length: 16, price: 850},
                    {length: 18, price: 945},
                    {length: 20, price: 1070},
                    {length: 22, price: 1235},
                    {length: 24, price: 1330},
                    {length: 26, price: 1535},
                    {length: 28, price: 1705},
                    {length: 30, price: 1920},
                ]
            },
            {
                id: 5,
                lace: "No lace bang",
                lengths: [
                    {length: 10, price: 290},
                    {length: 12, price: 310},
                    {length: 14, price: 385},
                    {length: 16, price: 525},
                    {length: 18, price: 655},
                    {length: 20, price: 780},
                    {length: 22, price: 955},
                    {length: 24, price: 1065},
                    {length: 26, price: 1245},
                    {length: 28, price: 1415},
                    {length: 30, price: 1540},
                ]
            },
        ]
    },
    {
        id: 27,
        type: "#613 Straight",
        density: "250%",
        grade: "10A",
        styles: [
            {
                id: 1,// Unique identifier for this style
                lace: "T part",
                lengths: [
                    {length: 10, price: 359},
                    {length: 12, price: 380},
                    {length: 14, price: 455},
                    {length: 16, price: 625},
                    {length: 18, price: 765},
                    {length: 20, price: 905},
                    {length: 22, price: 1085},
                    {length: 24, price: 1195},
                    {length: 26, price: 1395},
                    {length: 28, price: 1565},
                    {length: 30, price: 1700},
                ]
            },
            {
                id: 2,
                lace: "4x4",
                lengths: [
                    {length: 10, price: 485},
                    {length: 12, price: 530},
                    {length: 14, price: 635},
                    {length: 16, price: 785},
                    {length: 18, price: 890},
                    {length: 20, price: 1030},
                    {length: 22, price: 1200},
                    {length: 24, price: 1285},
                    {length: 26, price: 1510},
                    {length: 28, price: 1690},
                    {length: 30, price: 1905},
                ]
            },
            {
                id: 3,
                lace: "13x4",
                lengths: [
                    {length: 10, price: 519},
                    {length: 12, price: 565},
                    {length: 14, price: 670},
                    {length: 16, price: 820},
                    {length: 18, price: 925},
                    {length: 20, price: 1065},
                    {length: 22, price: 1235},
                    {length: 24, price: 1330},
                    {length: 26, price: 1555},
                    {length: 28, price: 1735},
                    {length: 30, price: 1950},
                ]
            },
            {
                id: 4,
                lace: "13x6",
                lengths: [
                    {length: 10, price: 610},
                    {length: 12, price: 655},
                    {length: 14, price: 760},
                    {length: 16, price: 910},
                    {length: 18, price: 1015},
                    {length: 20, price: 1155},
                    {length: 22, price: 1325},
                    {length: 24, price: 1420},
                    {length: 26, price: 1645},
                    {length: 28, price: 1825},
                    {length: 30, price: 2040},
                ]
            },
            {
                id: 5,
                lace: "No lace bang",
                lengths: [
                    {length: 10, price: 320},
                    {length: 12, price: 340},
                    {length: 14, price: 425},
                    {length: 16, price: 585},
                    {length: 18, price: 725},
                    {length: 20, price: 865},
                    {length: 22, price: 1045},
                    {length: 24, price: 1155},
                    {length: 26, price: 1355},
                    {length: 28, price: 1535},
                    {length: 30, price: 1660},
                ]
            },
        ]
    },
    {
        id: 28,
        type: "Double Drawn",
        density: "150%",
        grade: "12A",
        styles: [
            {
                id: 1,// Unique identifier for this style
                lace: "13x4",
                lengths: [
                    {length: 10, price: 240},
                    {length: 12, price: 260},
                    {length: 14, price: 310},
                    {length: 16, price: 380},
                    {length: 18, price: 440},
                    {length: 20, price: 540},
                    {length: 22, price: 711},
                    {length: 24, price: 926},
                    {length: 26, price: 1080},
                ]
            },
            {
                id: 2,
                lace: "13x4 large",
                lengths: [
                    {length: 10, price: 240},
                    {length: 12, price: 260},
                    {length: 14, price: 310},
                    {length: 16, price: 310},
                    {length: 18, price: 440},
                    {length: 20, price: 540},
                    {length: 22, price: 731},
                    {length: 24, price: 946},
                    {length: 26, price: 1100},
                ]
            },
            {
                id: 3,
                lace: "13x4 HD large",
                lengths: [
                    {length: 10, price: 430},
                    {length: 12, price: 450},
                    {length: 14, price: 501},
                    {length: 16, price: 570},
                    {length: 18, price: 634},
                    {length: 20, price: 744},
                    {length: 22, price: 925},
                    {length: 24, price: 1127},
                    {length: 26, price: 1341},
                ]
            },
            {
                id: 4,
                lace: "4x4",
                lengths: [
                    {length: 10, price: 220},
                    {length: 12, price: 240},
                    {length: 14, price: 290},
                    {length: 16, price: 360},
                    {length: 18, price: 420},
                    {length: 20, price: 520},
                    {length: 22, price: 691},
                    {length: 24, price: 906},
                    {length: 26, price: 1060},

                ]
            },
            {
                id: 5,
                lace: "4x4 HD",
                lengths: [
                    {length: 10, price: 260},
                    {length: 12, price: 280},
                    {length: 14, price: 331},
                    {length: 16, price: 400},
                    {length: 18, price: 464},
                    {length: 20, price: 574},
                    {length: 22, price: 755},
                    {length: 24, price: 957},
                    {length: 26, price: 1171},
                ]
            },
        ]
    },
    {
        id: 29,
        type: "Double Drawn",
        density: "180%",
        grade: "12A",
        styles: [
            {
                id: 1,// Unique identifier for this style
                lace: "13x4",
                lengths: [
                    {length: 10, price: 275},
                    {length: 12, price: 297},
                    {length: 14, price: 353},
                    {length: 16, price: 438},
                    {length: 18, price: 504},
                    {length: 20, price: 623},
                    {length: 22, price: 816},
                    {length: 24, price: 1041},
                    {length: 26, price: 1225},
                ]
            },
            {
                id: 2,
                lace: "13x4 large",
                lengths: [
                    {length: 10, price: 275},
                    {length: 12, price: 297},
                    {length: 14, price: 353},
                    {length: 16, price: 438},
                    {length: 18, price: 504},
                    {length: 20, price: 623},
                    {length: 22, price: 836},
                    {length: 24, price: 1061},
                    {length: 26, price: 1245},
                ]
            },
            {
                id: 3,
                lace: "13x4 HD large",
                lengths: [
                    {length: 10, price: 465},
                    {length: 12, price: 487},
                    {length: 14, price: 544},
                    {length: 16, price: 628},
                    {length: 18, price: 698},
                    {length: 20, price: 827},
                    {length: 22, price: 1030},
                    {length: 24, price: 1242},
                    {length: 26, price: 1486},
                ]
            },
            {
                id: 4,
                lace: "4x4",
                lengths: [
                    {length: 10, price: 255},
                    {length: 12, price: 277},
                    {length: 14, price: 333},
                    {length: 16, price: 418},
                    {length: 18, price: 484},
                    {length: 20, price: 603},
                    {length: 22, price: 796},
                    {length: 24, price: 1021},
                    {length: 26, price: 1205},

                ]
            },
            {
                id: 5,
                lace: "4x4 HD",
                lengths: [
                    {length: 10, price: 295},
                    {length: 12, price: 317},
                    {length: 14, price: 374},
                    {length: 16, price: 458},
                    {length: 18, price: 528},
                    {length: 20, price: 657},
                    {length: 22, price: 860},
                    {length: 24, price: 1072},
                    {length: 26, price: 1316},
                ]
            },
        ]
    },
    {
        id: 30,
        type: "Double Drawn",
        density: "210%",
        grade: "12A",
        styles: [
            {
                id: 1,// Unique identifier for this style
                lace: "13x4",
                lengths: [
                    {length: 10, price: 311},
                    {length: 12, price: 333},
                    {length: 14, price: 397},
                    {length: 16, price: 495},
                    {length: 18, price: 569},
                    {length: 20, price: 705},
                    {length: 22, price: 921},
                    {length: 24, price: 1156},
                    {length: 26, price: 1370},
                ]
            },
            {
                id: 2,
                lace: "13x4 large",
                lengths: [
                    {length: 10, price: 311},
                    {length: 12, price: 333},
                    {length: 14, price: 397},
                    {length: 16, price: 495},
                    {length: 18, price: 569},
                    {length: 20, price: 705},
                    {length: 22, price: 941},
                    {length: 24, price: 1176},
                    {length: 26, price: 1390},
                ]
            },
            {
                id: 3,
                lace: "13x4 HD large",
                lengths: [
                    {length: 10, price: 501},
                    {length: 12, price: 523},
                    {length: 14, price: 588},
                    {length: 16, price: 685},
                    {length: 18, price: 763},
                    {length: 20, price: 909},
                    {length: 22, price: 1135},
                    {length: 24, price: 1357},
                    {length: 26, price: 1631},
                ]
            },
            {
                id: 4,
                lace: "4x4",
                lengths: [
                    {length: 10, price: 291},
                    {length: 12, price: 313},
                    {length: 14, price: 377},
                    {length: 16, price: 475},
                    {length: 18, price: 549},
                    {length: 20, price: 685},
                    {length: 22, price: 901},
                    {length: 24, price: 1136},
                    {length: 26, price: 1350},

                ]
            },
            {
                id: 5,
                lace: "4x4 HD",
                lengths: [
                    {length: 10, price: 331},
                    {length: 12, price: 353},
                    {length: 14, price: 418},
                    {length: 16, price: 515},
                    {length: 18, price: 593},
                    {length: 20, price: 739},
                    {length: 22, price: 965},
                    {length: 24, price: 1187},
                    {length: 26, price: 1461},
                ]
            },
        ]
    },
    {
        id: 31,
        type: "Double Drawn",
        density: "250%",
        grade: "12A",
        styles: [
            {
                id: 1,// Unique identifier for this style
                lace: "13x4",
                lengths: [
                    {length: 10, price: 348},
                    {length: 12, price: 370},
                    {length: 14, price: 440},
                    {length: 16, price: 553},
                    {length: 18, price: 633},
                    {length: 20, price: 788},
                    {length: 22, price: 1026},
                    {length: 24, price: 1271},
                    {length: 26, price: 1515},
                ]
            },
            {
                id: 2,
                lace: "13x4 large",
                lengths: [
                    {length: 10, price: 348},
                    {length: 12, price: 370},
                    {length: 14, price: 440},
                    {length: 16, price: 553},
                    {length: 18, price: 633},
                    {length: 20, price: 788},
                    {length: 22, price: 1046},
                    {length: 24, price: 1291},
                    {length: 26, price: 1535},
                ]
            },
            {
                id: 3,
                lace: "13x4 HD large",
                lengths: [
                    {length: 10, price: 538},
                    {length: 12, price: 560},
                    {length: 14, price: 631},
                    {length: 16, price: 731},
                    {length: 18, price: 827},
                    {length: 20, price: 992},
                    {length: 22, price: 1240},
                    {length: 24, price: 1472},
                    {length: 26, price: 1776},
                ]
            },
            {
                id: 4,
                lace: "4x4",
                lengths: [
                    {length: 10, price: 328},
                    {length: 12, price: 350},
                    {length: 14, price: 420},
                    {length: 16, price: 533},
                    {length: 18, price: 613},
                    {length: 20, price: 768},
                    {length: 22, price: 1006},
                    {length: 24, price: 1251},
                    {length: 26, price: 1495},

                ]
            },
            {
                id: 5,
                lace: "4x4 HD",
                lengths: [
                    {length: 10, price: 368},
                    {length: 12, price: 390},
                    {length: 14, price: 461},
                    {length: 16, price: 573},
                    {length: 18, price: 657},
                    {length: 20, price: 822},
                    {length: 22, price: 1070},
                    {length: 24, price: 1302},
                    {length: 26, price: 1606},
                ]
            },
        ]
    },
];
export default wigs;