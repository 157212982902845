import React, {useState} from 'react';
import Component1 from "./SearchWigs01";
import Component2 from "./SearchWigs02";
import Component3 from "./SearchWigs03";

const components = [
    {id: 1, label: 'Models', component: <Component1/>},
    {id: 2, label: 'Budget', component: <Component2/>},
    {id: 3, label: 'Bundle', component: <Component3/>}
];

const Change = () => {
    const [currentComponent, setCurrentComponent] = useState(1);

    return (
        <div className="max-w-2xl text-center mx-auto pt-20">
            <div className="max-w-3xl text-center mx-auto">
                <h2 className="mb-12 text-gray-800 text-4xl font-bold leading-none text-center sm:text-3xl">
                    Trouvez vos mèches en fonction de vos besoins.
                </h2>
            </div>
            <div className="mb-12 text-gray-800 font-bold leading-none text-center sm:text-3xl">
                <div className="inline-flex space-x-4">
                    {components.map(({id, label}) => (
                        <button
                            key={id}
                            className={`font-medium text-[16px] items-center px-5 py-3 md:py-4 md:px-8 rounded-xl capitalize bg-[#E60023] hover:bg-[#D5001F] relative gap-2 transition duration-300 ${currentComponent === id ? 'hover:scale-105' : 'hover:scale-105 scale-90 bg-slate-900'} text-white shadow-glass`}
                            onClick={() => setCurrentComponent(id)}
                        >
                            {label}
                            {currentComponent !== id && (
                                <span
                                    className="animate-ping absolute right-0 top-0 w-3 h-3 rounded-full bg-[#D5001F]"></span>
                            )}
                        </button>
                    ))}
                </div>
            </div>
            {components.find(({id}) => id === currentComponent).component}
        </div>
    );
};

export default Change;
