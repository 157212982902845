import React, {useState} from 'react';
import pay from '../../Data/payment'
import {Link} from "react-router-dom";

const PriceModel = () => {
    const [selectedPayMethod, setSelectedPayMethod] = useState();

    const handlePayMethodChange = (event) => {
        const method = pay.find(item => item.method === event.target.value);
        setSelectedPayMethod(method);
    };

    return (
        <div>
            <div className="py-16">
                <div
                    className="grid sm:grid-cols-2 items-center gap-16 p-8 mx-auto max-w-4xl bg-white shadow-[0_2px_10px_-3px_rgba(6,81,237,0.3)] rounded-md text-[#333] font-[sans-serif]">
                    <div>
                        <h1 className="text-3xl font-extrabold">Paiement</h1>
                        <p className="text-sm text-gray-400 mt-3">
                            {selectedPayMethod && selectedPayMethod.Description}
                        </p>
                    </div>

                    <div className="ml-auo space-y-4">
                        <select onChange={handlePayMethodChange}
                                className="w-full rounded-md py-2.5 px-4 border text-sm outline-[#007bff]">
                            <option>Selectionner une methode</option>
                            {pay.map((method, index) => (
                                <option key={index} value={method.method}>{method.method}</option>
                            ))}
                        </select>
                        {selectedPayMethod && (
                            <div className="font-semibold">
                                <h1 className="w-full mb-2 rounded-md py-2.5 px-4 uppercase border text-sm outline-[#007bff]">
                                    {selectedPayMethod.method}
                                </h1>
                                <h1 className="w-full mb-2 rounded-md py-2.5 px-4 border text-sm outline-[#007bff]">
                                    {selectedPayMethod.Pays}
                                </h1>
                                <h1 className="w-full mb-2 rounded-md py-2.5 px-4 border text-sm outline-[#007bff]">
                                    {selectedPayMethod.Devise01}
                                </h1>
                                <h1 className="w-full mb-2 rounded-md py-2.5 px-4 border text-sm outline-[#007bff]">
                                    {selectedPayMethod.Devise02}
                                </h1>
                                <h1 className="w-full mb-2 rounded-md py-2.5 px-4 border text-sm outline-[#007bff]">
                                    {selectedPayMethod.Phone}
                                </h1>
                                <div
                                    className="w-full rounded-md px-4 border text-sm outline-[#007bff]">
                                    <img className="rounded-md md:w-52 text-center mx-auto"
                                         src={selectedPayMethod.Logo}
                                         alt="logo"/>
                                </div>
                            </div>
                        )}
                        <div>
                            <Link to="/orders">
                                <button
                                    className="text-white bg-red-700 hover:bg-red-600 font-semibold rounded-md text-sm px-4 py-2.5 w-full">Commander
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PriceModel;