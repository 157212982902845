import React from 'react';
import {PriceModel} from "../index";

const Transfer = () => {
    return (
        <div>
            <PriceModel/>
        </div>
    );
};

export default Transfer;