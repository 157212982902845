import React, {useState, useEffect} from 'react';
import {Link} from "react-router-dom";

const Longpay = () => {
    const [amount, setAmount] = useState('');
    const [savingsRate, setSavingsRate] = useState('');
    const [result, setResult] = useState('');
    const [currency, setCurrency] = useState('EUR'); // État pour la devise

    useEffect(() => {
        const calculateTime = () => {
            const totalAmount = parseFloat(amount);
            const monthlySavings = parseFloat(savingsRate);

            if (isNaN(totalAmount) || isNaN(monthlySavings) || monthlySavings <= 0) {
                setResult("Veuillez entrer des valeurs valides.");
                return;
            }

            const totalMonths = totalAmount / monthlySavings;
            const weeks = Math.round((totalMonths - Math.floor(totalMonths)) * 4);
            const months = Math.floor(totalMonths);
            const totalWeeks = months * 4 + weeks;

            const currentDate = new Date();
            currentDate.setMonth(currentDate.getMonth() + months);
            currentDate.setDate(currentDate.getDate() + (weeks * 7));

            const targetDate = currentDate.toLocaleDateString();
            setResult(`Chers clients, Afin d'atteindre votre objectif, il vous faudra environ ${months} mois,
             soit ${totalWeeks} semaines. La date estimée pour cela est le ${targetDate}.
             Nous vous remercions pour votre confiance.`);
        };

        calculateTime();
    }, [amount, savingsRate]); // Dépendances

    return (
        <div>
            <div className="py-16">
                <div
                    className="grid sm:grid-cols-2 gap-16 p-8 mx-auto max-w-4xl bg-white shadow-[0_2px_10px_-3px_rgba(6,81,237,0.3)] rounded-md text-[#333] font-[sans-serif]">
                    <div className="text-left">
                        <h1 className="text-3xl font-extrabold">Le Coffre</h1>
                        <p className="text-sm text-gray-400 mt-3">
                            Le Coffre, également connu sous le nom de LTP (Long Time Payment), est une stratégie
                            développée par l'équipe de MedinChina Officiel. Cette initiative vise à aider nos clients à
                            anticiper et à organiser l'acquisition de matériel lourd. Grâce à cette approche, nous
                            permettons à nos clients de mettre en place des stratégies d'épargne efficaces pour gérer le
                            paiement de leurs factures de manière optimale.
                        </p>
                    </div>
                    <div className="ml-auto space-y-4">
                        <select
                            name="currency"
                            className="w-full rounded-md py-2.5 px-4 border bg-transparent text-sm outline-red-700"
                            onChange={(e) => setCurrency(e.target.value)} // Mettre à jour la devise sélectionnée
                        >
                            <option value="EUR">EUR</option>
                            <option value="USD">USD</option>
                            <option value="RMB">RMB</option>
                            <option value="FCFA">FCFA</option>
                        </select>

                        <div className="relative flex h-10 w-full flex-row-reverse overflow-clip rounded-lg">
                            <input
                                className="peer w-full rounded-r-lg border border-gray-300 px-2 text-gray-900 placeholder-gray-400 transition-colors duration-300 focus:border-gray-400 focus:outline-none"
                                type="number"
                                required={true}
                                name="amount"
                                placeholder="Entrez le montant"
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)}/>
                            <label
                                className="flex items-center rounded-l-lg border border-red-700 bg-red-700 px-2 text-sm text-white font-bold transition-colors duration-300 peer-focus:border-red-400 peer-focus:bg-red-400 peer-focus:text-white"
                                form="domain">{currency}</label>
                        </div>
                        <div className="relative flex h-10 w-full flex-row-reverse overflow-clip rounded-lg">
                            <input
                                className="peer w-full rounded-r-lg border border-gray-300 px-2 text-gray-900 placeholder-gray-400 transition-colors duration-300 focus:border-gray-400 focus:outline-none"
                                type="number"
                                required={true}
                                name="savingsRate"
                                placeholder="Montant épargné par mois"
                                value={savingsRate}
                                onChange={(e) => setSavingsRate(e.target.value)}/>
                            <label
                                className="flex items-center rounded-l-lg border border-red-700 bg-red-700 px-2 text-sm text-white font-bold transition-colors duration-300 peer-focus:border-red-400 peer-focus:bg-red-400 peer-focus:text-white"
                                form="domain">{currency}</label>
                        </div>

                        <div className="w-full rounded-md px-4 border text-sm pt-2.5 outline-red-700">
                            <p className="w-full shadow-inner p-4 border-0">
                                {result}
                            </p>
                        </div>
                        <div>
                            <Link to="/orders">
                                <button
                                    className="text-white bg-red-700 hover:bg-red-600 font-semibold rounded-md text-sm px-4 py-2.5 w-full"
                                >
                                    Commander
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Longpay;
