import React from "react";
// import { med, war } from "../../assets";
import med from "../../assets/img/login/ima04.jpg";
import war from "../../assets/img/login/war.jpg";
import Bus from "../../assets/img/login/Business.jpg";


const bodyConta = () => {
    return (
        <div>
            <div className="py-20 bg-white">
                <div className="container mx-auto px-6 md:px-12 xl:px-32">
                    <div className="mb-16 text-center">
                        <h2 className="mb-4 text-center text-2xl text-gray-900 font-bold md:text-4xl">
                            Notre Team</h2>
                        <p className="text-gray-600 lg:w-8/12 lg:mx-auto">
                            Rencontrez l'équipe dynamique de MedInChina. Nos experts dévoués sont là pour vous offrir un
                            service exceptionnel et répondre à vos besoins avec professionnalisme et enthousiasme.
                            Découvrez
                            qui se cache derrière notre succès !
                        </p>
                    </div>
                    <div className="grid gap-12 items-center md:grid-cols-3">
                        <div className="space-y-4 text-center">
                            <img className="w-64 h-64 mx-auto object-cover rounded-xl md:w-40 md:h-40 lg:w-64 lg:h-64"
                                 src={war} alt="woman"
                                 loading="lazy" width="640" height="805"/>
                            <div>
                                <h4 className="text-2xl text-secondary-800">M.Warren ONDAMBA</h4>
                                <span className="block text-sm text-gray-500">Responsable GABON</span>
                            </div>
                        </div>
                        <div className="space-y-4 text-center">
                            <img className="w-64 h-64 mx-auto object-cover rounded-xl md:w-48 md:h-64 lg:w-64 lg:h-80"
                                 src={med} alt="man"
                                 loading="lazy" width="1000" height="667"/>
                            <div>
                                <h4 className="text-2xl text-secondary-800">Medy Evrard M.A</h4>
                                <span className="block text-sm text-gray-500">Responsable CHINE</span>
                            </div>
                        </div>
                        <div className="space-y-4 text-center">
                            <img className="w-64 h-64 mx-auto object-cover rounded-xl md:w-40 md:h-40 lg:w-64 lg:h-64"
                                 src={Bus} alt="woman"
                                 loading="lazy" width="1000" height="667"/>
                            <div>
                                <h4 className="text-2xl">Sarah lina</h4>
                                <span className="block text-sm text-gray-500">Manager</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default bodyConta;
