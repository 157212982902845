import React from 'react';
import MainLayout from "../layout/MainLayout";
import TopQuestion from "../components/myquestions/TopQuestion";
import {MyQuestion} from "../components";

const Question = () => {
    return (
        <MainLayout>
            <TopQuestion/>
            <MyQuestion/>
        </MainLayout>
    );
};

export default Question;