import React, {useEffect, useState, useCallback} from 'react';

import {Link} from "react-router-dom";

const Simulation = () => {

    const today = new Date().toISOString().split('T')[0];
    const [formData, setFormData] = useState({
        price: "",
        currency: "USD",
        service: "",
        date: today,
        benefit: "",
    });
    const [message, setMessage] = useState({content: "", type: ""});

    // Reset form when currency changes, including the benefit (final price)
    useEffect(() => {
        setFormData(prevFormData => ({
            ...prevFormData,
            price: "",
            service: "",
            date: today,
            benefit: "",
        }));
        setMessage({content: "", type: ""});
    }, [formData.currency, today]);

    const calculateRate = (valuePrice, currency) => {
        const rates = {
            USD: [[241.93, 0.10], [1000, 0.06], [15000, 0.04]],
            EUR: [[250, 0.10], [1000, 0.06], [15000, 0.04]],
            RMB: [[1800, 0.10], [7300, 0.06], [110000, 0.04]],
            FCFA: [[150000, 0.10], [600000, 0.06], [10000000, 0.04]],
        };
        let rate = 0.13; // Default rate
        (rates[currency] || []).forEach(([limit, r]) => {
            if (valuePrice > limit) rate = r;
        });
        return valuePrice * rate;
    };

    const calculateTotal = useCallback(() => {
        const valuePrice = parseFloat(formData.price);
        if (valuePrice < 1 || isNaN(valuePrice)) {
            setMessage({content: 'Veuillez entrer des valeurs valides', type: 'error'});
            setTimeout(() => setMessage({content: "", type: ""}), 10000); // Clear message after 10 seconds
            return;
        }

        let total = calculateRate(valuePrice, formData.currency);
        const calculate = total + valuePrice;
        const calculatedBenefit = calculate + (calculate * 0.10);
        setFormData(prevFormData => ({...prevFormData, benefit: calculatedBenefit.toFixed(2)}));
        setMessage({content: "Envoyez-nous une capture d'écran", type: 'success'});
    }, [formData, setMessage]); // Dependencies

    useEffect(() => {
        if (formData.price && formData.currency && formData.service && formData.date) {
            calculateTotal();
        }
    }, [formData, calculateTotal]);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData(prevFormData => ({...prevFormData, [name]: value}));
    };

    useEffect(() => {
        console.log("Min date set to:", today);
    }, [today]);


    const handleDateChange = (e) => {
        const inputDate = new Date(e.target.value);
        const minDate = new Date(today);

        if (inputDate < minDate) {
            alert("The date must be today or later.");
            setFormData(prevFormData => ({...prevFormData, date: today}));
        } else {
            handleChange(e);  // Your existing handleChange function
        }
    };

    return (
        <div>
            <div
                className="bg-cream text-charcoal min-h-screen font-sans leading-normal overflow-x-hidden lg:overflow-auto py-16">
                <main className="flex-1 md:p-0 lg:pt-8 lg:px-8 md:ml-24 flex flex-col">
                    <section className="bg-cream-lighter p-4 shadow">
                        <div className="md:flex">
                            <h2 className="md:w-1/3 uppercase tracking-wide text-sm font-semibold  sm:text-lg mb-6">
                                Simulateur de facturation.</h2>
                            {message.content && (
                                <div className={`message ${message.type}`}>
                                    {message.content}
                                </div>
                            )}
                        </div>
                        <form>
                            <div className="md:flex mb-8">
                                <div className="md:w-1/3">
                                    <legend className="uppercase tracking-wide text-sm">Informations</legend>
                                    <p className="text-xs font-light text-red">Cette section entière est requise.</p>
                                </div>

                                <div className="md:flex-1 mt-2 mb:mt-0 md:px-3">
                                    <div className="md:flex mb-4">
                                        <div className="md:flex-1 md:pr-3">
                                            <label
                                                className="block uppercase tracking-wide text-charcoal-darker text-xs font-bold">Devise</label>
                                            <select name="currency" value={formData.currency} onChange={handleChange}
                                                    required={true}
                                                    className="w-full shadow-inner p-4 border-0 bg-transparent">
                                                <option value="EUR">EUR</option>
                                                <option value="USD">USD</option>
                                                <option value="RMB">RMB</option>
                                                <option value="FCFA">FCFA</option>
                                            </select>
                                            <span
                                                className="text-xs mb-4 font-thin">Ceci est requis.</span>
                                        </div>
                                        <div className="md:flex-1 md:pl-3">
                                            <label
                                                className="block uppercase tracking-wide text-charcoal-darker text-xs font-bold">Montant</label>

                                            <input required={true} name="price" value={formData.price} type="number"
                                                   onChange={handleChange}
                                                   className="w-full shadow-inner p-4 border-0"/>
                                            <span
                                                className="text-xs mb-4 font-thin">Ceci est requis.</span>
                                        </div>

                                    </div>
                                    <div className="md:flex mb-4">
                                        <div className="md:flex-1 md:pl-3">
                                            <label
                                                className="block uppercase tracking-wide text-charcoal-darker text-xs font-bold">Service</label>
                                            <select required={true} name="service" value={formData.service}
                                                    onChange={handleChange}
                                                    className="w-full shadow-inner p-4 border-0 bg-transparent">
                                                <option value="">Le service</option>
                                                <option value="Guide">Guide</option>
                                                <option value="Alibaba et autres">Alibaba et Autres</option>
                                                <option value="Ma facture">Ma facture</option>
                                                <option value="Mon Fournisseur">Mon Fournisseur</option>
                                                <option value="Autres">Autres</option>
                                            </select>
                                            <span
                                                className="text-xs mb-4 font-thin">Ceci est requis.</span>
                                        </div>
                                        <div className="md:flex-1 md:pl-3">
                                            <label
                                                className="block uppercase tracking-wide text-charcoal-darker text-xs font-bold">Date
                                                de paiement</label>

                                            <input required={true} name="date" type="date" value={formData.date}
                                                   onChange={handleDateChange}
                                                   min={today}
                                                   className="w-full shadow-inner p-4 border-0 bg-transparent"/>
                                            <span
                                                className="text-xs mb-4 font-thin">Ceci est requis.</span>
                                        </div>
                                        <div className="md:flex-1 md:pl-3">
                                            <label
                                                className="block uppercase tracking-wide text-charcoal-darker text-xs font-bold">Total
                                                à régler
                                            </label>
                                            <div className="w-full shadow-inner p-4 border-0">
                                                <p className="flex items-baseline gap-x-2">
                                <span
                                    className="font-bold tracking-tight text-gray-900">{formData.benefit}</span>
                                                    <span
                                                        className="text-sm font-semibold leading-6 tracking-wide text-gray-600">{formData.currency}</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="md:flex mb-6">
                                <div className="md:w-1/3">
                                    <legend className="uppercase tracking-wide text-sm">Description</legend>
                                </div>
                                <div className="md:flex-1 mt-2 mb:mt-0 md:px-3">
                                    <p className="w-full shadow-inner p-4 border-0">
                                        Le montant à régler s'élève à {formData.benefit} {formData.currency}. Ce montant
                                        englobe tous les frais relatifs
                                        au service {formData.service}. La facture correspondante a été émise
                                        le {formData.date}. Pour plus
                                        d'informations, veuillez cliquer{' '}
                                        <Link to={"/contact"} className="text-red-400 font-bold">
                                            ici.
                                        </Link>
                                    </p>
                                </div>
                            </div>
                            <div
                                className="md:flex mb-6 justify-center border border-t-1 border-b-0 border-x-0 border-cream-dark">
                                <div
                                    className="mt-4 inline-block space-y-4 sm:space-y-0 sm:flex-row sm:space-x-8">
                                    <Link to="/Orders">
                                        <button
                                            className=" px-8 py-3 bg-red-500 hover:bg-red-700 text-white text-base rounded-lg transition-colors ">
                                            Commander
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </form>
                    </section>
                </main>
            </div>
        </div>
    );
};

export default Simulation;