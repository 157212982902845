import React from 'react';
import img from "../../assets/logo/logo192.png";
import book from "../../assets/new/Guide2024.pdf";
import {LuFileDown} from "react-icons/lu";

const Header1 = () => {
    return (
        <div>
            <nav className="bg-gray-900 w-full z-20 top-0 start-0 border-b border-gray-600">
                <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
                    <a href="/" className="flex items-center space-x-3 rtl:space-x-reverse">
                        <img src={img} className="h-10" alt="medinchina Logo"/>
                        <span
                            className="self-center text-2xl font-semibold whitespace-nowrap text-white">Medinchina</span>
                    </a>

                    <div className="flex md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse">
                        <a href={book} download>
                            <button type="button"
                                    className="flex items-center text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-4 py-2">
                                Services
                                <LuFileDown className="ml-2"/>
                            </button>
                        </a>
                    </div>
                    <div className="items-center justify-between hidden w-full md:flex md:w-auto md:order-1"
                         id="navbar-sticky">
                    </div>
                </div>
            </nav>
        </div>
    );
};

export default Header1;
